<template>
  <div class="auth-wrapper">
    <div class="auth-left-container">
      <img class="app-logo" :src="whiteLabelRef.logo" alt="" />
      <div class="auth-left-container__header">
        <slot name="header"></slot>
      </div>
      <div class="auth-left-container__body">
        <slot name="body"></slot>
      </div>
    </div>
    <div class="auth-right-container">
      <ManIllustrationIcon />
    </div>
  </div>
</template>

<script setup lang="ts">
import ManIllustrationIcon from '@/features/ui/components/icons/man-illustration-icon.vue'
import useWhiteLabel from '@/hooks/use-white-label.hook'

const { whiteLabelRef } = useWhiteLabel()
</script>

<style lang="scss">
@import './auth-wrapper.scss';
</style>
