<template>
  <Dropdown
    class-name="session-participants"
    :title="t('session.participants.title')"
    :default-open="false"
    :is-loaded="!isPendingRef"
  >
    <template v-if="mentorRef">
      <UserCard v-for="user in [mentorRef, ...menteesRef]" :key="user.id" :user="user" />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getMentees, getMentor } from '@/features/groups/utils'
import useSession from '@/features/sessions/hooks/use-session.hook'
import Dropdown from '@/features/ui/components/dropdown/dropdown.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserCard from '@/features/users/components/user-card/user-card.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)

const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])

const mentorRef = computed(() => sessionRef.value?.users.find(getMentor))
</script>

<style lang="scss">
@import './session-participants.scss';
</style>
