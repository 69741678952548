<template>
  <ModalStep
    :id="'edit-profile-information-content'"
    :header="{
      title: t('auth.editProfile.description.title'),
    }"
  >
    <template #bodyContent>
      <form>
        <div id="edit-profile-editor" class="description-editor"></div>
      </form>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="button"
          color="white"
          @emit:click="closeModal"
          :text="t('ui.button.cancel')"
        ></CustomButton>
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="tempSaveInfo"
          :text="t('ui.button.next')"
          icon-position="right"
        >
          <template #icon> <ArrowIcon color="white" /> </template
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import EditorJS from '@editorjs/editorjs'
import { computed, ref, watch } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import type { ProfileInformationModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useUser from '@/features/users/hooks/use-user.hook'
import { editorJSI18n, editorJSTools } from '@/lib/editorjs'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()
const { accountRef } = useAccount()
const accountIdRef = computed(() => accountRef.value?.id)
const { userRef } = useUser(accountIdRef)

const editorRef = ref<EditorJS | null>(null)

const descriptionRef = ref<any>(null)

watch(
  userRef,
  (user) => {
    if (user) {
      descriptionRef.value = JSON.parse(user.description ?? '{"blocks": []}') as { blocks: any[] }
    }
  },
  { immediate: true },
)

watch(
  descriptionRef,
  (description) => {
    // destroy the editor if it exists
    if (editorRef.value) {
      editorRef.value.destroy()
    }

    editorRef.value = new EditorJS({
      holder: 'edit-profile-editor',
      placeholder: t('auth.editProfile.description.placeholder'),
      data: description,
      i18n: editorJSI18n,
      tools: editorJSTools,
    })
  },
  { immediate: true },
)

function closeModal() {
  modalsStore.toggleModal('profile-information', false)
}

async function tempSaveInfo() {
  const description = await editorRef.value?.save()

  modalsStore.updateModalAttachedData<ProfileInformationModalType>('profile-information', {
    description: JSON.stringify(description),
  })
  modalsStore.setModalStep<ProfileInformationModalType>('profile-information', 'profile-picture')
}
</script>

<style lang="scss">
@import './edit-profile-information-content.scss';
</style>
