import type { StoreApi } from 'zustand'

import type { ModalsStoreValuesType, ModalStoreType } from '@/features/ui/store/modal/modal.store'

export function toggleModalAction(
  get: StoreApi<ModalsStoreValuesType>['getState'],
  set: StoreApi<ModalsStoreValuesType>['setState'],
  name: ModalStoreType['name'],
  isOpen: ModalStoreType['isOpen'],
) {
  const modals = get().modals

  const targetModal = modals[name]

  if (targetModal) {
    set({
      modals: {
        ...modals,
        [name]: {
          ...targetModal,
          isOpen,
          activeStep: isOpen ? targetModal.activeStep : targetModal.steps[0],
        },
      },
    })
  }
}

export function setModalStepAction<M extends ModalStoreType>(
  get: StoreApi<ModalsStoreValuesType>['getState'],
  set: StoreApi<ModalsStoreValuesType>['setState'],
  name: M['name'],
  step: M['steps'][number],
) {
  const modals = get().modals

  const targetModal = modals[name]

  if (targetModal) {
    set({
      modals: {
        ...modals,
        [name]: {
          ...targetModal,
          activeStep: step,
        },
      },
    })
  }
}

export function updateModalAttachedDatAction<M extends ModalStoreType>(
  get: StoreApi<ModalsStoreValuesType>['getState'],
  set: StoreApi<ModalsStoreValuesType>['setState'],
  name: M['name'],
  data: M['attachedData'],
) {
  const modals = get().modals
  const targetModal = modals[name]

  if (targetModal) {
    set({
      modals: {
        ...modals,
        [name]: {
          ...targetModal,
          attachedData: {
            ...targetModal.attachedData,
            ...data,
          },
        },
      },
    })
  }
}

export const toggleShouldHideMatchingModalsAction = (
  get: StoreApi<ModalsStoreValuesType>['getState'],
  set: StoreApi<ModalsStoreValuesType>['setState'],
  shouldHideMatchingModals: boolean,
) => {
  set({
    shouldHideMatchingModals,
  })
}
