import type {
  CreateConversationDTOType,
  GetFirstGroupMessageResponseType,
  GetFirstMnetorshipGroupMessageDTOType,
  MarkConversationAsReadDTOType,
  SendMessageDTOType,
  V1ConversationType,
  V1GetConversationDTOType,
} from '@/features/chat/types'
import fetchClient from '@/lib/fetch'

export const V1_CONVERSATIONS_QUERY_KEY = 'conversations'
export async function getConversations(data: V1GetConversationDTOType): Promise<V1ConversationType[]> {
  return fetchClient(`/v1/chat?mentorshipGroupId=${data.mentorshipGroupId}`, {
    method: 'GET',
  })
}

export async function createConversationMutation(data: CreateConversationDTOType): Promise<V1ConversationType> {
  return fetchClient(`/v1/chat/conversation`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function sendMessageMutation(data: SendMessageDTOType): Promise<void> {
  return fetchClient(`/v1/chat`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const V1_FIRST_GROUP_MESSAGE_KEY = 'first-group-message'
export async function getFirstGroupMessage(
  data: GetFirstMnetorshipGroupMessageDTOType,
): Promise<GetFirstGroupMessageResponseType> {
  return fetchClient(
    `/v1/chat/autfill/first-mentorship-group-message?programId=${data.programId}&mentorshipGroupId=${data.mentorshipGroupId}`,
    {
      method: 'GET',
    },
  )
}

export async function markConversationAsReadMutation(data: MarkConversationAsReadDTOType): Promise<void> {
  return fetchClient(`/v1/chat/markAsRead/`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
}
