import type { RoleEnumType } from '@/features/auth/types'
import type { V1UserAdminDataType } from '@/features/users/types'

export enum ImportUserKeysEnumType {
  email = 'email',
  firstName = 'firstname',
  lastName = 'lastname',
  role = 'role',
  matchingCriteria = 'matchingCriteria',
  phone = 'phone',
  programId = 'programId',
  sso = 'sso',
}

export type ImportUsersDTOType = {
  programId: string
  periodId: string
  users: {
    [key in ImportUserKeysEnumType]: string | boolean | null
  }[]
}

export type GetUserNotesDTOType = {
  userId: string
}

export type GetUserNotesResponseType = {
  id: string
  content: string
  userId: string
  authorId: string
  createdAt: string
  updatedAt: string
}

export type UpdateUserNotesDTOType = {
  content: string
  userId: string
}

export type GetUsersDTOType = {
  programId: string
  page: number
  role: RoleEnumType | 'ALL'
  hasGroup: 'YES' | 'NO' | 'ALL'
  perPage: number
  search: string
}

export type GetUsersResponseType = {
  users: V1UserAdminDataType[]
  totalUsers: number
  totalPages: number
}
