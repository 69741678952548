<template>
  <div :class="`search-bar-wrapper ${className}`">
    <div class="search-bar-el-wrapper">
      <SearchIcon color="black" />
      <input
        class="search-bar"
        type="text"
        v-model="valueRef"
        :data-value="valueRef"
        :placeholder="placeholder"
        :defaultOnChangeDelay="500"
        @input="onChange"
      />
      <div class="search-bar-close-btn" v-if="valueRef" @click="onClear">
        <CrossIcon color="black" />
      </div>
    </div>
    <div v-if="slots.searchBarResults" class="search-bar-results">
      <slot name="searchBarResults"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useSlots } from 'vue'

import CrossIcon from '@/features/ui/components/icons/cross-icon.vue'
import SearchIcon from '@/features/ui/components/icons/search-icon.vue'
import debounce from '@/utils/misc/debounce'

interface InputPropsType {
  className?: string
  placeholder: string
  value?: string | null
}

const props = defineProps<InputPropsType>()
const slots = useSlots()

// set default value v-model
const valueRef = ref(props.value || '')

defineExpose({
  set(message: string) {
    valueRef.value = message
  },
})

const emit = defineEmits(['emit:change', 'emit:clear'])

const onClear = () => {
  valueRef.value = ''
  emit('emit:change', null)
  emit('emit:clear')
}

const onChange = debounce(
  (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    // Emit the new value to the parent with the event name 'update:modelValue'
    emit('emit:change', value)
  },
  500,
  false,
)
</script>

<style scoped lang="scss">
@import './search-bar.scss';
</style>
