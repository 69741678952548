import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/features/auth/types'
import { getMentees } from '@/features/groups/utils'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import AddDeliverableModalContent from '@/features/sessions/components/add-deliverable-modal/add-deliverable-modal-content/add-deliverable-modal-content.vue'
import deliverablesRatingModalContent from '@/features/sessions/components/review-session-modal/deliverables-rating-modal-content/deliverables-rating-modal-content.vue'
import FinishedReviewModalContent from '@/features/sessions/components/review-session-modal/finished-review-modal-content/finished-review-modal-content.vue'
import PresencesSessionModalContent from '@/features/sessions/components/review-session-modal/presences-session-modal-content/presences-session-modal-content.vue'
import RateSessionModalContent from '@/features/sessions/components/review-session-modal/rate-session-modal-content/rate-session-modal-content.vue'
import ReportModalContent from '@/features/sessions/components/review-session-modal/report-modal-content/report-modal-content.vue'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import type { ReviewSessionModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import { useI18n } from '@/lib/i18n'
import { ANALYTICS_EVENTS, trackEvent } from '@/lib/posthog'
import type { ComponentFactoryType } from '@/types'

export function useSessionInReview() {
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

  const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)
  const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)
  const { sessionRef, isPendingRef: isSessionPendingRef } = useSession(sessionIdRef)

  const { accountRef } = useAccount()
  const { t } = useI18n()

  // list of actions that can be done on the session
  const actionsListRef = computed(() => {
    if (!sessionRef.value) {
      return []
    }

    const mentees = sessionRef.value.users.filter(getMentees)

    if (accountRef.value?.role === RoleEnumType.MENTOR) {
      return [
        {
          title: t('session.pendingActions.review.title'),
          // if the user has already rated the session, the status will be 'done'
          status: sessionRef.value.reviews.find(
            (review) => review.userId === accountRef.value?.id && typeof review.grade === 'number',
          )
            ? 'done'
            : 'pending',
          id: 'rating',
          component: RateSessionModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldReviewSessionRating,
        },
        {
          title: t('session.pendingActions.presences.title'),
          // if the user has already filled the presence, the status will be 'done'
          status: sessionRef.value.presences.length > 0 ? 'done' : 'pending',
          id: 'presence',
          component: PresencesSessionModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldMentorReviewSessionPresence,
        },
        {
          title: t('session.pendingActions.deliverables.title'),
          // if the user has already rated the deliverables, the status will be 'done'
          // if there is was no mentees present, the status will be 'done'
          status: sessionRef.value.deliverables.some((deliverable) => typeof deliverable.rating === 'number')
            ? // even if mentee wasn't present, we still want to display the deliverable rate
              // ||
              // (sessionRef.value.presences.length > 0 &&
              // sessionRef.value.presences
              // .filter((presence) => mentees.some((mentee) => mentee.id === presence.userId))
              // .every((presence) => !presence.wasPresent))

              'done'
            : 'pending',
          id: 'deliverables-rating',
          component: deliverablesRatingModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldMentorReviewSessionDeliverableRate,
        },
        {
          title: t('session.pendingActions.report.title'),
          // if the user has alredy sent the report, the status will be 'done'
          status: sessionRef.value.report ? 'done' : 'pending',
          id: 'report',
          component: ReportModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldMentorReviewSessionDeliverableReport,
        },
        {
          title: t('session.pendingActions.finished.title'),
          status: 'pending',
          id: 'finished',
          component: FinishedReviewModalContent,
          isActiveOnThisProgram: true,
          isStepOnly: true, // will just act as a final step (not a pending action)
        },
      ].filter((action) => action.isActiveOnThisProgram)
    }
    if (accountRef.value?.role === RoleEnumType.MENTEE) {
      return [
        {
          title: t('session.pendingActions.review.title'),
          // if the user has already rated the session, the status will be 'done'
          status: sessionRef.value.reviews.find(
            (review) => review.userId === accountRef.value?.id && typeof review.grade === 'number',
          )
            ? 'done'
            : 'pending',
          id: 'rating',
          component: RateSessionModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldReviewSessionRating,
        },
        {
          title: t('session.pendingActions.sendDeliverable.title'),
          // if the user has already sent a deliverable, the status will be 'done'
          status: sessionRef.value.deliverables.find((deliverable) => deliverable.userId === accountRef.value?.id)?.file
            ? 'done'
            : 'pending',
          id: 'deliverable',
          component: AddDeliverableModalContent,
          isActiveOnThisProgram: selectedProgramRef.value?.settings.shouldReviewSessionDeliverable,
        },
        {
          title: t('session.pendingActions.finished.title'),
          status: 'pending',
          id: 'finished',
          component: FinishedReviewModalContent,
          isActiveOnThisProgram: true,
          isStepOnly: true, // will just act as a final step (not a pending action)
        },
      ].filter((action) => action.isActiveOnThisProgram)
    }

    // default case
    return []
  }) as ComputedRef<
    {
      title: string
      status: 'done' | 'pending'
      id: ReviewSessionModalType['activeStep']
      component: ComponentFactoryType
      isActiveOnThisProgram: boolean | undefined
      isStepOnly?: boolean
    }[]
  >

  // filter the actions that are not just steps
  const pendingActionsRef = computed(() => actionsListRef.value.filter((action) => !action.isStepOnly))

  const currentPendingActionRef = computed(() => actionsListRef.value.find((action) => action.status === 'pending'))
  const nextPendingActionRef = computed(() => actionsListRef.value.find((action) => action.status === 'pending'))

  // mapping the actions to the steps structure
  const reviewStepsRef = computed(() =>
    actionsListRef.value.map((action) => ({ id: action.id, component: action.component })),
  )

  // function to call for every step
  function onClickNextStep() {
    if (!nextPendingActionRef.value) return

    modalsStore.setModalStep<ReviewSessionModalType>('review-session', nextPendingActionRef.value.id)
  }

  // function to call when clicking "complete session"
  function onClickCompletePendingActions() {
    console.log('onClickCompletePendingActions')
    if (!nextPendingActionRef.value) return

    console.log(nextPendingActionRef.value.id)

    // opening the review session modal
    modalsStore.toggleModal('session-details', false)
    modalsStore.toggleModal('review-session', true)

    modalsStore.updateModalAttachedData<ReviewSessionModalType>('review-session', {
      sessionId: sessionIdRef.value!,
    })

    // setting the next step
    onClickNextStep()
  }

  // function to call when clicking "fill later"
  function onCloseAndFillLater(cb?: () => void) {
    trackEvent(ANALYTICS_EVENTS.USER_CLICK_SESSION_REVIEW_FILL_LATER, {
      step: currentPendingActionRef.value?.id,
    })
    modalsStore.toggleModal('review-session', false)

    // additional callback to call after closing the modal
    if (cb && typeof cb === 'function') {
      cb()
    }
  }

  return {
    reviewStepsRef,
    pendingActionsRef,
    isSessionPendingRef,
    nextPendingActionRef,
    onClickCompletePendingActions,
    onClickNextStep,
    onCloseAndFillLater,
  }
}
