import type {
  MenteeMatchingConfirmationDTOType,
  MenteeMatchingConfirmationResponseType,
  MenteeMatchingDTOType,
  MenteeMatchingResponseType,
  MentorMatchingDTOType,
} from '@/features/matching/types'
import fetchClient from '@/lib/fetch'

export async function menteeMatchSuggestMentorMutation(
  data: MenteeMatchingDTOType,
): Promise<MenteeMatchingResponseType> {
  return fetchClient(`/v2/groups/matchmaking/suggest?programId=${data.programId}&criteria=${data.criteria}`, {
    method: 'GET',
  })
}

export async function menteeMatchConfirmMutation(
  data: MenteeMatchingConfirmationDTOType,
): Promise<MenteeMatchingConfirmationResponseType> {
  return fetchClient('/v2/groups/matchmaking/match', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function mentorMatchFillCriteriaMutation(data: MentorMatchingDTOType) {
  return fetchClient('/v2/groups/matchmaking/criteria', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export async function updateMentorMatchCriteriaMutation(data: MentorMatchingDTOType) {
  return fetchClient('/v2/groups/matchmaking/criteria', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}
