<template>
  <ModalStep
    id="new-matching-information"
    :header="{
      title: t('matching.newMatchingModal.matchingInformation.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramReminder :programId="selectedProgramIdRef" />
    </template>

    <template #bodyInformation>
      <p v-html="t('matching.newMatchingModal.matchingInformation.bodyInformation')"></p>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="button" color="white" :text="t('ui.button.back')" @emit:click="goBack" />
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          :text="t('ui.button.next')"
          @emit:click="goNext"
          iconPosition="right"
        >
          <template #icon>
            <ArrowIcon color="white" filled />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import ProgramReminder from '@/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import type { NewMatchingModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import { useI18n } from '@/lib/i18n'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { t } = useI18n()

function goNext() {
  modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-fill-criteria')
}

function goBack() {
  modalsStore.toggleModal('new-matching', false)
}
</script>

<style lang="scss">
@import './new-matching-information-modal-content.scss';
</style>
