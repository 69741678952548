<template>
  <Modal
    :modal="programCriteriasModal"
    :steps="[{ component: ProgramCriteriasModalContent, id: 'program-criterias' }]"
  />
</template>

<script lang="ts" setup>
import Modal from '@/features/ui/components/modal/modal.vue'
import ProgramCriteriasModalContent from '@/features/ui/pages/dashboard/dashboard-content/mentor/program-criterias-modal/program-criterias-modal-content/program-criterias-modal-content.vue'
import { selectGetProgramCriteriasModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const programCriteriasModal = selectGetProgramCriteriasModal(modalsStore)
</script>
