<template>
  <ModalStep
    :id="'edit-profile-picture-content'"
    :header="{
      title: t('auth.editProfile.picture.title'),
    }"
  >
    <template #bodyContent>
      <div v-if="imageUrlRef" class="picture-uploaded-container">
        <div class="picture-uploaded">
          <img v-if="imageUrlRef" :src="imageUrlRef" alt="uploaded picture" />
        </div>
        <div class="picture-side-info">
          <p>
            <strong>{{ accountRef?.firstName }}</strong
            >, <br />
            {{ t('auth.editProfile.picture.uploaded') }}
          </p>
          <button type="button" @click="changeProfilePicture">{{ t('ui.button.change') }}</button>
        </div>
      </div>
      <FileUpload v-else accept=".jpg, .png, .heic" :callback="(file) => (pictureFileRef = file)">
        <template #instructions>
          <p>{{ t('ui.input.file.instructions.maxSize') }} 25MB</p>
          <p>{{ t('ui.input.file.instructions.format') }} .jpg, .png, .HEIC</p>
          <p>{{ t('ui.input.file.instructions.minSize') }} 500x500</p>
        </template>
      </FileUpload>
      <div class="c-info-wrapper">
        <p class="c-info-label">
          <strong>{{ t('auth.editProfile.picture.examples.title') }}</strong>
        </p>
        <p class="c-info-text">
          {{ t('auth.editProfile.picture.examples.body') }}
        </p>
      </div>
      <div class="examples-pictures-container">
        <div class="picture">
          <img src="/images/picture-example-1.png" alt="example picture 1" />
        </div>
        <div class="picture">
          <img src="/images/picture-example-2.png" alt="example picture 2" />
        </div>
        <div class="picture">
          <img src="/images/picture-example-3.png" alt="example picture 3" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="button"
          color="white"
          @emit:click="
            () => modalsStore.setModalStep<ProfileInformationModalType>('profile-information', 'description')
          "
          :text="t('ui.button.back')"
        ></CustomButton>
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          :is-loading="isSubmittingRef"
          @emit:click="submitForm"
          :text="t('ui.button.confirm')"
          icon-position="right"
        >
          ></CustomButton
        >
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import heic2any from 'heic2any'
import { ref, watch, watchEffect } from 'vue'
import type { ZodError } from 'zod'
import z from 'zod'

import { updateUserMutation, V1UpdateUserDescriptionMutation } from '@/features/auth/api'
import useAccount from '@/features/auth/hooks/use-account.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import FileUpload from '@/features/ui/components/file-upload/file-upload.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetProfileInformationModal } from '@/features/ui/store/modal/modal.selectors'
import type { ProfileInformationModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { PROFILE_PICTURE_BUCKET_URL } from '@/utils/config/constants'
import { invalidateAccount, invalidateGroups, invalidateUser } from '@/utils/lib/vue-query'

const { t } = useI18n()
const toast = useToast()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const profileInformationModalRef = selectGetProfileInformationModal(modalsStore)
const { accountRef } = useAccount()

const pictureFileRef = ref<null | File>(null)
const convertedPictureRef = ref<null | File>(null)

const imageUrlRef = ref<string | null>(null)

// if convertedPicture is ready or the account already has a picture, show the picture
watchEffect(() => {
  if (convertedPictureRef.value) {
    imageUrlRef.value = URL.createObjectURL(convertedPictureRef.value)
    return
  }
  if (accountRef.value?.picture) {
    imageUrlRef.value = `${PROFILE_PICTURE_BUCKET_URL}${accountRef.value.picture}`
    return
  }
})

// when picture file changes, convert the file if it's a heic file
watch(pictureFileRef, (newPictureFile) => {
  if (!newPictureFile) {
    return
  }

  if (newPictureFile.type === 'image/heic') {
    heic2any({
      blob: newPictureFile,
      toType: 'image/jpeg',
      quality: 0.5,
    }).then((blob) => {
      // convert blob to file
      const convertedFile = new File([blob as Blob], newPictureFile.name.replace('.heic', '.jpg'), {
        type: 'image/jpeg',
      })
      convertedPictureRef.value = convertedFile
    })
  } else {
    convertedPictureRef.value = newPictureFile
  }
})

// when pictureFileRef changes, update the attachedData
watch(convertedPictureRef, (newProfilePicture) => {
  modalsStore.updateModalAttachedData<ProfileInformationModalType>('profile-information', {
    ...profileInformationModalRef.value.attachedData,
    profilePicture: newProfilePicture ?? undefined,
  })
})

const validationSchema = z.object({
  description: z.string().optional(),
  profilePicture: z.instanceof(File).optional(),
})

function closeModal() {
  modalsStore.toggleModal('profile-information', false)
}

// reset the picture file
function changeProfilePicture() {
  imageUrlRef.value = null
  convertedPictureRef.value = null
}

const isSubmittingRef = ref(false)

const { mutateAsync: updateUserProfilePicture } = useMutation({
  mutationFn: updateUserMutation,
  // we don't use onSuccess because there is chaining of mutations
})

const { mutateAsync: updateUserDescription } = useMutation({
  mutationFn: V1UpdateUserDescriptionMutation,
  // we don't use onSuccess because there is chaining of mutations
})

async function submitForm() {
  try {
    isSubmittingRef.value = true
    const data = await validationSchema.parseAsync({
      description: profileInformationModalRef.value.attachedData.description,
      profilePicture: profileInformationModalRef.value.attachedData.profilePicture,
    })

    if (data.profilePicture) {
      await updateUserProfilePicture({
        picture: data.profilePicture,
        userId: accountRef.value!.id!,
      })
    }

    if (data.description) {
      await updateUserDescription({
        userId: accountRef.value!.id!,
        description: data.description,
      })
    }

    await invalidateUser(accountRef.value!.id!)
    await invalidateAccount()
    await invalidateGroups(selectedProgramIdRef.value!)
    toast?.success(t('auth.editProfile.success'))
    closeModal()
  } catch (e: any) {
    // handle zod errors & other errors
    const error: ZodError = e
    if (error.errors) {
      error.errors.forEach((err) => {
        toast?.error(err.message)
      })
    } else {
      toast?.error(t('error.common'))
    }
  } finally {
    isSubmittingRef.value = false
  }
}
</script>

<style lang="scss">
@import './edit-profile-picture-content.scss';
</style>
