<template>
  <!-- if matching disabled, take full height -->
  <GridBlockBase
    :style="programRef?.settings.isMatchingEnabled ? '' : 'grid-row: 4 span !important;'"
    id="mentees-list"
    :title="t('dashboard.mentor.mentees.title')"
  >
    <template #icon>
      <MenteeIcon color="black" filled />
    </template>
    <template #body>
      <LoadingContent v-if="selectedGroupIdRef && isPendingRef" />
      <EmptyContent v-else-if="!menteesRef || menteesRef?.length === 0" :text="t('dashboard.mentor.mentees.noMentee')">
        <template #icon>
          <MenteeIcon color="black" />
        </template>
      </EmptyContent>
      <template v-else>
        <UserCard v-for="mentee in menteesRef" :key="mentee.id" :user="mentee" />
      </template>

      <div style="margin: 0 auto; margin-top: auto">
        <CustomButton
          v-if="menteesRef && menteesRef.length > 0 && selectedGroupIdRef"
          usage="button"
          color="primary"
          :text="t('ui.layout.nav.newSession')"
          @emit:click="openNewSessionModal"
        >
          <template #icon>
            <AddCalendarIcon color="white" filled />
          </template>
        </CustomButton>
      </div>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useGroup from '@/features/groups/hooks/use-group.hook'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { getMentees } from '@/features/groups/utils'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import AddCalendarIcon from '@/features/ui/components/icons/add-calendar-icon.vue'
import MenteeIcon from '@/features/ui/components/icons/mentee-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserCard from '@/features/users/components/user-card/user-card.vue'
import { useI18n } from '@/lib/i18n'
const { t } = useI18n()

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(selectedProgramIdRef)

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { groupRef, isPendingRef } = useGroup(selectedGroupIdRef)

const menteesRef = computed(() => groupRef.value?.users.filter(getMentees))

function openNewSessionModal() {
  modalsStore.toggleModal('new-session', true)
}
</script>

<style lang="scss">
@import './dashboard-mentees-list.scss';
</style>
