import ImageTool from '@editorjs/image'

import { convertToBase64 } from '@/utils/misc/convert-base64'

export const editorJSI18n = {
  messages: {
    blockTunes: {
      delete: {
        Delete: 'Supprimer',
      },
      moveUp: {
        'Move up': 'Bouger vers le haut',
      },
      moveDown: {
        'Move down': 'Bouger vers le bas',
      },
    },
  },
}

export const editorJSTools = {
  image: {
    class: ImageTool,
    config: {
      actions: [],
      uploader: {
        async uploadByFile(file: File) {
          console.log(file)
          const fileTobase64 = await convertToBase64(file)
          return {
            success: 1,
            file: {
              // file to base64
              url: fileTobase64,
            },
          }
        },
      },
    },
  },
}
