import type { I18n } from 'vue-i18n-bridge'

import { PermissionsGroupsEnumType, RoleEnumType } from '@/features/auth/types'
import dayjsClient from '@/lib/dayjs'
import { switchI18nLanguage, type TranslationKeysType } from '@/lib/i18n'

export const userRolesNamesMap = {
  ROLE_ADMIN: 'common.admin.capitalize',
  ROLE_MANAGER: 'common.manager.capitalize',
  ROLE_MENTOR: 'common.mentor.capitalize',
  ROLE_STUDENT: 'common.mentee.capitalize',
  ROLE_GUEST: 'common.guest.capitalize',
} as Record<RoleEnumType, TranslationKeysType>

const PERMISSIONS_GROUPS = {
  [PermissionsGroupsEnumType.ADMIN]: [RoleEnumType.ADMIN, RoleEnumType.MANAGER],
  [PermissionsGroupsEnumType.USER]: [RoleEnumType.MENTEE, RoleEnumType.MENTOR],
} as const

// return the permissions group for a given role
function checkPermissionsGroup(role: RoleEnumType | undefined, permissionGroup: PermissionsGroupsEnumType): boolean {
  return PERMISSIONS_GROUPS[permissionGroup].includes(role as never)
}

export default checkPermissionsGroup

/**
 * Switches the application language and updates related configurations.
 */
export async function switchLanguage(locale: string, i18nInstance: I18n) {
  await switchI18nLanguage(i18nInstance, locale)
  dayjsClient.locale(locale)
}
