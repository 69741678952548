import '@/styles/utils/vue-toastification.css'

import * as Sentry from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Toast from 'vue-toastification'

import { setupI18n } from './lib/i18n'
import App from './App.vue'
import router from './router'

import { initPosthog } from '@/lib/posthog'
import queryClient from '@/lib/vue-query'
import { ANALYTICS_DISABLED_KEY } from '@/utils/config/constants'

// internationalization
Vue.use(VueI18n, { bridge: true }) // you must specify '{ bridge: true }' plugin option when install vue-i18n (vue 2.7 compatibility)
export const i18nInstance = setupI18n()

async function init() {
  Vue.use(VueQueryPlugin, {
    queryClient,
  })

  Vue.config.productionTip = false

  Vue.use(Toast, {})

  Vue.use(i18nInstance)

  // disable all analytics tools for the user
  if (window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    console.warn('***********************')
    console.warn('ANALYTICS ARE DISABLED FOR THIS USER')
    console.warn('***********************')
  }

  // posthog script
  if (!window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    initPosthog()
  }

  // brevo script
  if (import.meta.env.VITE_ENV === 'production' && !window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    const brevo = document.createElement('script')
    brevo.id = 'brevo-conversations'
    brevo.type = 'text/javascript'
    brevo.innerHTML = `
        !function(e,n,o){n.BrevoConversationsID="611ce099bcb2d0387209fc56",n[o]=n[o]||function(){(n[o].q=n[o].q||[]).push(arguments)};var s=e.createElement("script");s.async=!0,s.src="https://conversations-widget.brevo.com/brevo-conversations.js",e.head&&e.head.appendChild(s)}(document,window,"BrevoConversations");
        `

    // Brevo script will be injected when white label data is loaded
    window.brevoScript = brevo
  }

  // sentry script
  if (import.meta.env.VITE_ENV === 'production' && !window.localStorage.getItem(ANALYTICS_DISABLED_KEY)) {
    Sentry.init({
      Vue,
      dsn: 'https://b981e456aa676c7d459c801ca47f1477@o995149.ingest.sentry.io/4506739714752512',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app')
}

init()
