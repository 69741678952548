import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import type { ImportUsersDTOType } from '@/features/admin/users/types'
import type { MenteeMatchingResponseType } from '@/features/matching/types'
import type { V2SessionType } from '@/features/sessions/types'
import {
  setModalStepAction,
  toggleModalAction,
  toggleShouldHideMatchingModalsAction,
  updateModalAttachedDatAction,
} from '@/features/ui/store/modal/modal.actions'
import subscribeToStore from '@/lib/zustand'

// common modal data type
type CommonModalType<T, D, S extends string[]> = {
  name: T
  activeStep: S[number]
  steps: S
  isOpen: boolean
  isSidebar?: boolean
  attachedData: Partial<D>
}

// onboarding modal type
export type OnboardingModalType = CommonModalType<
  'onboarding',
  {
    email: string
    firstName: string
    lastName: string
    role: JSX.Element
    phone: string
    picture: File
  },
  ['not-set-yet', 'not-set-yet', 'not-set-yet', 'not-set-yet']
>

const OnboardingModalItem = {
  name: 'onboarding',
  activeStep: 'not-set-yet',
  steps: ['not-set-yet', 'not-set-yet', 'not-set-yet', 'not-set-yet'],
  isOpen: false,
  attachedData: {},
} satisfies OnboardingModalType

// phone modal type
export type PhoneModalType = CommonModalType<'phone', object, ['ask-phone']>

const PhoneModalItem = {
  name: 'phone',
  activeStep: 'ask-phone',
  steps: ['ask-phone'],
  isOpen: false,
  attachedData: {},
} satisfies PhoneModalType

// support modal type
export type SupportModalType = CommonModalType<'support', object, ['support-help']>

const SupportModalItem = {
  name: 'support',
  activeStep: 'support-help',
  steps: ['support-help'],
  isOpen: false,
  attachedData: {},
} satisfies SupportModalType

// mentor matching modal
export type MentorMatchingModalType = CommonModalType<
  'mentor-matching',
  object,
  ['goal-reminder', 'fill-criteria', 'matching-filled', 'matching-no-groups']
>

const MentorMatchingModalItem = {
  name: 'mentor-matching',
  activeStep: 'goal-reminder',
  steps: ['goal-reminder', 'fill-criteria', 'matching-filled', 'matching-no-groups'],
  isOpen: false,
  attachedData: {},
} satisfies MentorMatchingModalType

// mentee matching modal
export type MenteeMatchingModalType = CommonModalType<
  'mentee-matching',
  {
    mentors: MenteeMatchingResponseType['mentors']
  },
  ['goal-reminder', 'fill-criteria', 'matching-loading', 'matching-confirmation', 'matching-no-groups']
>

const MenteeMatchingModalItem = {
  name: 'mentee-matching',
  activeStep: 'goal-reminder',
  steps: ['goal-reminder', 'fill-criteria', 'matching-loading', 'matching-confirmation', 'matching-no-groups'],
  isOpen: false,
  attachedData: {
    mentors: [],
  },
} satisfies MenteeMatchingModalType

// no program modal
export type NoProgramModalType = CommonModalType<'no-program', object, ['no-program-available']>

const NoProgramModalItem = {
  name: 'no-program',
  activeStep: 'no-program-available',
  steps: ['no-program-available'],
  isOpen: false,
  attachedData: {},
} satisfies NoProgramModalType

// with confirmation modal
export type ConfirmActionModalType = CommonModalType<
  'confirm-action',
  {
    fn: () => void
    cancelFn?: () => void
    title: string
    type: 'danger'
    information?: string
    content?: string
    btnText?: string
  },
  ['confirm-action']
>

const ConfirmActionModalItem = {
  name: 'confirm-action',
  activeStep: 'confirm-action',
  steps: ['confirm-action'],
  isOpen: false,
  attachedData: {},
} satisfies ConfirmActionModalType

// new session modal
export type NewSessionModalType = CommonModalType<'new-session', object, ['new-session']>

const newSessionModalItem = {
  name: 'new-session',
  activeStep: 'new-session',
  steps: ['new-session'],
  isOpen: false,
  attachedData: {},
} satisfies NewSessionModalType

// meeting modal
export type MeetingModalType = CommonModalType<
  'meeting',
  {
    isResized: boolean
  },
  ['meeting']
>
const meetingModalItem = {
  name: 'meeting',
  activeStep: 'meeting',
  steps: ['meeting'],
  isOpen: false,
  attachedData: {
    isResized: false,
  },
} satisfies MeetingModalType

// user profile modal
export type UserProfileModalType = CommonModalType<
  'user-profile',
  {
    userId: string
  },
  ['user-profile']
>

const userProfileModalItem = {
  name: 'user-profile',
  activeStep: 'user-profile',
  steps: ['user-profile'],
  isSidebar: true,
  isOpen: false,
  attachedData: {},
} satisfies UserProfileModalType

// password modal
export type PasswordModalType = CommonModalType<'change-password', object, ['change-password']>

const passwordModalItem = {
  name: 'change-password',
  activeStep: 'change-password',
  steps: ['change-password'],
  isOpen: false,
  attachedData: {},
} satisfies PasswordModalType

// profile information modal
export type ProfileInformationModalType = CommonModalType<
  'profile-information',
  {
    description?: string
    profilePicture?: File
  },
  ['description', 'profile-picture']
>

const profileInformationModalItem = {
  name: 'profile-information',
  activeStep: 'description',
  steps: ['description', 'profile-picture'],
  isOpen: false,
  attachedData: {},
} satisfies ProfileInformationModalType

// review session modal
export type ReviewSessionModalType = CommonModalType<
  'review-session',
  {
    sessionId: string
    rating: number
    review: string
  },
  ['rating', 'review', 'deliverable', 'presence', 'deliverables-rating', 'report', 'finished']
>

const reviewSessionModalItem = {
  name: 'review-session',
  activeStep: 'rating',
  steps: ['rating', 'review', 'deliverable', 'presence', 'deliverables-rating', 'report', 'finished'],
  isOpen: false,
  attachedData: {},
} satisfies ReviewSessionModalType

// session details modal
export type SessionDetailsModalType = CommonModalType<
  'session-details',
  {
    sessionId: string
  },
  ['session-details']
>

const sessionDetailsModalItem = {
  name: 'session-details',
  activeStep: 'session-details',
  steps: ['session-details'],
  isSidebar: true,
  isOpen: false,
  attachedData: {},
} satisfies SessionDetailsModalType

// admin users import
export type AdminUsersImportModalType = CommonModalType<
  'admin-users-import',
  { users: ImportUsersDTOType['users'] | null },
  ['users-import', 'users-import-confirmation']
>

const adminUsersImportModalItem = {
  name: 'admin-users-import',
  activeStep: 'users-import',
  steps: ['users-import', 'users-import-confirmation'],
  isOpen: false,
  attachedData: { users: null },
} satisfies AdminUsersImportModalType

// admin manual user import
export type AdminManualUserImportModalType = CommonModalType<
  'admin-manual-user-import',
  { users: ImportUsersDTOType['users'] | null },
  ['manual-user-import']
>

const adminManualUserImportModalItem = {
  name: 'admin-manual-user-import',
  activeStep: 'manual-user-import',
  steps: ['manual-user-import'],
  isOpen: false,
  attachedData: { users: null },
} satisfies AdminManualUserImportModalType

export type NewMatchingModalType = CommonModalType<
  'new-matching',
  {
    mentors: MenteeMatchingResponseType['mentors']
  },
  ['new-matching-info', 'new-matching-fill-criteria', 'new-matching-loading', 'new-matching-confirmation']
>

const newMatchingModalItem = {
  name: 'new-matching',
  activeStep: 'new-matching-info',
  steps: ['new-matching-info', 'new-matching-fill-criteria', 'new-matching-loading', 'new-matching-confirmation'],
  isOpen: false,
  attachedData: { mentors: [] },
} satisfies NewMatchingModalType

export type AddToCalendarModalType = CommonModalType<
  'add-to-calendar',
  {
    session: V2SessionType
  },
  ['add-to-calendar']
>

const addToCalendarModalItem = {
  name: 'add-to-calendar',
  activeStep: 'add-to-calendar',
  steps: ['add-to-calendar'],
  isOpen: false,
  attachedData: {},
} satisfies AddToCalendarModalType
export type TermsModalType = CommonModalType<'terms', object, ['terms']>

const termsModalItem = {
  name: 'terms',
  activeStep: 'terms',
  steps: ['terms'],
  isOpen: false,
  attachedData: {},
} satisfies TermsModalType

export type AdminGroupStructureModalType = CommonModalType<
  'admin-group-structure',
  { groupId: string },
  ['group-structure']
>

const adminGroupStructureModalItem = {
  name: 'admin-group-structure',
  activeStep: 'group-structure',
  steps: ['group-structure'],
  isOpen: false,
  attachedData: {},
} satisfies AdminGroupStructureModalType

export type ProgramCriteriasModalType = CommonModalType<
  'program-criterias',
  { programId: string },
  ['program-criterias']
>

const programCriteriasModalItem = {
  name: 'program-criterias',
  activeStep: 'program-criterias',
  steps: ['program-criterias'],
  isOpen: false,
  attachedData: {},
} satisfies ProgramCriteriasModalType

export type GroupSettingsModalType = CommonModalType<'group-settings', { groupId: string }, ['group-settings']>

const groupSettingsModalItem = {
  name: 'group-settings',
  activeStep: 'group-settings',
  steps: ['group-settings'],
  isOpen: false,
  attachedData: {},
} satisfies GroupSettingsModalType

export type EditSessionModalType = CommonModalType<
  'edit-session',
  {
    sessionId: string | null
  },
  ['edit-session']
>

const editSessionModalItem = {
  name: 'edit-session',
  activeStep: 'edit-session',
  steps: ['edit-session'],
  isOpen: false,
  attachedData: {},
} satisfies EditSessionModalType

export type NameMissingModalType = CommonModalType<'name-missing', object, ['name-missing']>

const nameMissingModalItem = {
  name: 'name-missing',
  activeStep: 'name-missing',
  steps: ['name-missing'],
  isOpen: false,
  attachedData: {},
} satisfies NameMissingModalType

export type ModalStoreType =
  | OnboardingModalType
  | PhoneModalType
  | SupportModalType
  | MentorMatchingModalType
  | MenteeMatchingModalType
  | NoProgramModalType
  | ConfirmActionModalType
  | NewSessionModalType
  | MeetingModalType
  | UserProfileModalType
  | PasswordModalType
  | ProfileInformationModalType
  | ReviewSessionModalType
  | SessionDetailsModalType
  | AdminUsersImportModalType
  | AdminManualUserImportModalType
  | NewMatchingModalType
  | AddToCalendarModalType
  | TermsModalType
  | AdminGroupStructureModalType
  | ProgramCriteriasModalType
  | GroupSettingsModalType
  | EditSessionModalType
  | NameMissingModalType

export type ModalsStoreValuesType = {
  modals: Record<ModalStoreType['name'], ModalStoreType>
  shouldHideMatchingModals: boolean
}

export const INITIAL_MODALS_STORE_VALUES: ModalsStoreValuesType = {
  modals: {
    'onboarding': OnboardingModalItem,
    'phone': PhoneModalItem,
    'change-password': passwordModalItem,
    'support': SupportModalItem,
    'mentor-matching': MentorMatchingModalItem,
    'mentee-matching': MenteeMatchingModalItem,
    'no-program': NoProgramModalItem,
    'confirm-action': ConfirmActionModalItem,
    'new-session': newSessionModalItem,
    'review-session': reviewSessionModalItem,
    'meeting': meetingModalItem,
    'user-profile': userProfileModalItem,
    'profile-information': profileInformationModalItem,
    'session-details': sessionDetailsModalItem,
    'new-matching': newMatchingModalItem,
    'admin-users-import': adminUsersImportModalItem,
    'admin-manual-user-import': adminManualUserImportModalItem,
    'add-to-calendar': addToCalendarModalItem,
    'terms': termsModalItem,
    'admin-group-structure': adminGroupStructureModalItem,
    'program-criterias': programCriteriasModalItem,
    'group-settings': groupSettingsModalItem,
    'edit-session': editSessionModalItem,
    'name-missing': nameMissingModalItem,
  },
  shouldHideMatchingModals: false,
}

const modalsStore = subscribeToStore(
  create(
    combine(INITIAL_MODALS_STORE_VALUES, (set, get) => ({
      toggleModal: (name: ModalStoreType['name'], isOpen: ModalStoreType['isOpen']) =>
        toggleModalAction(get, set, name, isOpen),
      updateModalAttachedData: <M extends ModalStoreType>(name: M['name'], attachedData: M['attachedData']) =>
        updateModalAttachedDatAction<M>(get, set, name, attachedData),
      setModalStep: <M extends ModalStoreType>(name: M['name'], step: M['steps'][number]) =>
        setModalStepAction<M>(get, set, name, step),
      toggleShouldHideMatchingModals: (isOpen: ModalsStoreValuesType['shouldHideMatchingModals']) =>
        toggleShouldHideMatchingModalsAction(get, set, isOpen),
    })),
  ),
)
export default modalsStore
