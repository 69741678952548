<template>
  <span :class="`c-status-badge c-status-badge--${badgeMap[badgeTypeRef].colors[status]}`">
    {{ t(badgeMap[badgeTypeRef].text[status]) }}
  </span>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { DeliverablesNotationPossibleStatusType, SessionStatusEnumType } from '@/features/sessions/types'
import type { TranslationKeysType } from '@/lib/i18n'
import { useI18n } from '@/lib/i18n'

type PossibleStatusType = SessionStatusEnumType | DeliverablesNotationPossibleStatusType

const props = defineProps<{ status: PossibleStatusType; type?: PossibleBadgeTypeType }>()

const { t } = useI18n()
const badgeTypeRef = ref<PossibleBadgeTypeType>(props.type ?? 'session')

const sessionStatusColorsMap = {
  PLANNED: 'info',
  FINISHED: 'success',
  WAIT_CONFIRMATION: 'warning',
  WAIT_REVIEWS: 'warning',
  CANCELLED: 'black',
  ONGOING: 'warning',
  NONE: 'black',
} as Record<PossibleStatusType, string>

const sessionStatusTextMap = {
  PLANNED: 'session.status.planned',
  FINISHED: 'session.status.finished',
  WAIT_CONFIRMATION: 'session.status.waitConfirmation',
  WAIT_REVIEWS: 'session.status.waitReviews',
  CANCELLED: 'session.status.cancelled',
  ONGOING: 'session.status.ongoing',
  NONE: 'session.status.none',
} as Record<PossibleStatusType, TranslationKeysType>

const userStatusColorsMap = {
  PLANNED: 'success',
  FINISHED: 'success',
  WAIT_CONFIRMATION: 'warning',
  WAIT_REVIEWS: 'success',
  CANCELLED: 'danger',
} as Record<PossibleStatusType, string>

const userStatusTextMap = {
  PLANNED: 'session.status.user.present',
  FINISHED: 'session.status.user.present',
  WAIT_CONFIRMATION: 'session.status.user.waitConfirmation',
  WAIT_REVIEWS: 'session.status.user.present',
  CANCELLED: 'session.status.user.absent',
} as Record<PossibleStatusType, TranslationKeysType>

const notationStatusColorsMap = {
  RATED: 'success',
  NOT_RATED: 'warning',
} as Record<DeliverablesNotationPossibleStatusType, string>

const notationStatusTextMap = {
  RATED: 'session.status.grade.rated',
  NOT_RATED: 'session.status.grade.toFill',
} as Record<DeliverablesNotationPossibleStatusType, TranslationKeysType>

type PossibleBadgeTypeType = 'session' | 'user' | 'notation'

const badgeMap = {
  session: { colors: sessionStatusColorsMap, text: sessionStatusTextMap },
  user: { colors: userStatusColorsMap, text: userStatusTextMap },
  notation: { colors: notationStatusColorsMap, text: notationStatusTextMap },
} as Record<PossibleBadgeTypeType, { colors: Record<string, string>; text: Record<string, string> }>
</script>
