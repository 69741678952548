<template>
  <div id="app">
    <router-view />
    <LoadingGuard />
    <MeetingWindow />
  </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import io from 'socket.io-client'
import { computed, onBeforeMount, ref, watch, watchEffect } from 'vue'

import { addLoginHistoryMutation } from '@/features/auth/api'
import { getUserData } from '@/features/auth/api'
import LoadingGuard from '@/features/auth/components/loading-guard/loading-guard.vue'
import useAccount from '@/features/auth/hooks/use-account.hook'
import useIsAuth from '@/features/auth/hooks/use-is-auth.hook'
import usePhone from '@/features/auth/hooks/use-phone.hook'
import authStore from '@/features/auth/stores/auth/auth.store'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import MeetingWindow from '@/features/meetings/components/meeting-window/meeting-window.vue'
import usePrograms from '@/features/programs/hooks/use-programs.hook'
import useRouter from '@/hooks/use-router.hook'
import useWhiteLabel from '@/hooks/use-white-label.hook'
import { identifyUser } from '@/lib/posthog'
import { ROUTES } from '@/utils/config/constants'
import { invalidateConversations } from '@/utils/lib/vue-query'
import convertHexToRGB from '@/utils/misc/hex-to-rgb'

const router = useRouter()

const { isAuthRef } = useIsAuth()
const { accountRef } = useAccount()
const { phoneRef } = usePhone()
const { whiteLabelRef } = useWhiteLabel()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const accountIdRef = computed(() => accountRef.value?.id)
const accountSupportIdRef = computed(() => accountRef.value?.supportId)
const accountFirstNameRef = computed(() => accountRef.value?.firstName)
const accountLastNameRef = computed(() => accountRef.value?.lastName)
const accountEmailRef = computed(() => accountRef.value?.email)
const accountPhoneRef = computed(() => phoneRef.value)
const accountRoleRef = computed(() => accountRef.value?.role)
const { programsRef } = usePrograms()

const socketRef = ref(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  io(import.meta.env.VITE_API_URL, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    reconnectionAttempts: import.meta.env.VITE_ENV === 'development' ? 1 : 100,
  }),
)

// when the user is auth for the first time, get its user data
watch(isAuthRef, async (isAuth) => {
  if (isAuth) {
    const userData = await getUserData()
    if (userData) {
      await authStore.setUserRole(userData.role)
      router?.push(ROUTES.INDEX).catch(() => {})
    }
  }
})

// listen to sockets
watch(
  accountIdRef,
  (accountId) => {
    if (accountId) {
      listenSocket(accountId)
    }
  },
  { immediate: true, flush: 'post' },
)

// identify user to posthog
watch(
  [accountRef, programsRef],
  ([account, programs]) => {
    if (account) {
      identifyUser(account, programs)
    }
  },
  { immediate: true, flush: 'post' },
)

// call activity mutation to update last user activity on mounted
const { mutate: addLoginHistory } = useMutation({
  mutationFn: addLoginHistoryMutation,
  onError: undefined,
})

watch(accountIdRef, (accountId) => {
  if (accountId) {
    addLoginHistory()
  }
})

// add visitor id to brevo
watch(accountSupportIdRef, (supportId) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (supportId && window.BrevoConversationsSetup) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.BrevoConversationsSetup.visitorId = supportId
  }
})

const brevoIsReadyRef = ref(false)
watchEffect(() => {
  if (!whiteLabelRef.value?.logo) return

  // setFavicon
  const linkFavicon = document.createElement('link')
  linkFavicon.rel = 'icon'
  linkFavicon.href = whiteLabelRef.value.favIcon ?? ''
  document.querySelector('head')!.appendChild(linkFavicon)
  // set custom style + set colors
  if (whiteLabelRef.value.colorPrimary && whiteLabelRef.value.colorSecondary) {
    const style = document.createElement('style')
    style.innerHTML = `:root {--color-white-label-primary: ${convertHexToRGB(whiteLabelRef.value.colorPrimary)}; --color-white-label-secondary: ${convertHexToRGB(whiteLabelRef.value.colorSecondary)}; --bs-primary-focus: rgba(${convertHexToRGB(whiteLabelRef.value.colorPrimary)}, 0.5); --bs-primary-light: rgba(${convertHexToRGB(whiteLabelRef.value.colorPrimary)}, 0.7); --color-primary: rgb(${convertHexToRGB(whiteLabelRef.value.colorPrimary)});--color-secondary: rgb(${convertHexToRGB(whiteLabelRef.value.colorSecondary)});--bs-primary: rgb(${convertHexToRGB(whiteLabelRef.value.colorPrimary)});--bs-secondary: rgb(${convertHexToRGB(whiteLabelRef.value.colorSecondary)});}`
    style.innerHTML += whiteLabelRef.value.customStyle
    document.querySelector('head')!.appendChild(style)
  }

  // setAppTitle
  document.querySelector('title')!.innerHTML = whiteLabelRef.value.companyTitle ?? ''

  // inject brevo script
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window.brevoScript) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    document.querySelector('head')!.appendChild(window.brevoScript)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (window.BrevoConversations) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.BrevoConversationsSetup = {
      colors: {
        buttonText: '#f0f0f0',
        buttonBg: `${whiteLabelRef.value.colorPrimary}`,
      },
      customWidgetButton: '.custom-chat-button',
      locale: {
        customStrings: {
          welcome: `Une question ? Nous sommes là pour vous aider ! \n\n Envoyez-nous un message ici ou contactez-nous par téléphone au ${whiteLabelRef.value.supportPhone}.`,
          offlineNote: `Nous sommes hors ligne. Laissez-nous un message ici ou par e-mail sur ${whiteLabelRef.value.supportEmail}.`,
        },
      },
    }

    brevoIsReadyRef.value = true
  }
})

// add user data to brevo
watch(
  [accountEmailRef, accountFirstNameRef, accountLastNameRef, accountPhoneRef, accountRoleRef, brevoIsReadyRef],
  ([email, firstName, lastName, phone, role]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (brevoIsReadyRef.value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.BrevoConversations('updateIntegrationData', {
        email: email ?? '',
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        phone: phone ?? '',
        role: role ?? '',
      })
    }
  },
  { flush: 'post' },
)

// watch for SAML token
onBeforeMount(async () => {
  // verify if the user is logged with SAML method
  var url_string = window.location.href
  var url = new URL(url_string)
  if (url.searchParams.get('SAML')) {
    localStorage.clear()
    await authStore.setAuthToken(url.searchParams.get('SAML')!)
  }
})

// listen to socket
function listenSocket(accountId: string) {
  socketRef.value.off(`USER_${accountId}`)
  socketRef.value.on(`USER_${accountId}`, (payload: any) => {
    switch (payload.action) {
      case 'SEND_MESSAGE':
        // invalidate the conversations query when the socket receives a new message
        invalidateConversations(selectedGroupIdRef.value!)
        break
      default:
        break
    }
  })
}
</script>
