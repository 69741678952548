<template>
  <div :class="`main-layout ${initialNavCollapsedState ? 'js-nav-collapsed' : ''}`">
    <MainNav />
    <div class="right-block">
      <TopNav />
      <div class="top-nav"></div>
      <main>
        <MobileNav />
        <slot name="middle"></slot>
      </main>
    </div>
    <slot name="outside"></slot>
    <AppHooks />
    <NameModal />
    <PhoneModal />
    <AddToCalendarModal />
    <NoProgramModal />
    <MentorMatchingModal />
    <MenteeMatchingModal />
    <NewMatchingModal />
    <GroupSettingsModal />

    <NewSessionModal />
    <EditSessionModal />
    <SessionDetailsModal />
    <ReviewSessionModal />

    <UserProfileModal />

    <ProgramsPopover />
    <PeriodsPopover />
    <GroupsPopover />
    <MoreActionsMenu />
    <ConfirmActionModal />

    <!-- Should be last modal so that it is opened on top of all other modals -->
    <TermsModal />

    <SystemMenu v-if="environmentRef === 'development'" />
  </div>
</template>

<script setup>
import { ref } from 'vue'

import AppHooks from './app-hooks.vue'

import NameModal from '@/features/auth/components/name-modal/name-modal.vue'
import GroupSettingsModal from '@/features/groups/components/group-settings-modal/group-settings-modal.vue'
import GroupsPopover from '@/features/groups/components/groups-popover/groups-popover.vue'
import TermsModal from '@/features/legal/components/terms-modal/terms-modal.vue'
import MenteeMatchingModal from '@/features/matching/components/matching-modals/mentee-matching-modal/mentee-matching-modal.vue'
import MentorMatchingModal from '@/features/matching/components/matching-modals/mentor-matching-modal/mentor-matching-modal.vue'
import NewMatchingModal from '@/features/matching/components/new-matching-modal/new-matching-modal.vue'
import NoProgramModal from '@/features/programs/components/no-program-modal/no-program-modal.vue'
import PeriodsPopover from '@/features/programs/components/periods-popover/periods-popover.vue'
import ProgramsPopover from '@/features/programs/components/programs-popover/programs-popover.vue'
import AddToCalendarModal from '@/features/sessions/components/add-to-calendar-modal/add-to-calendar-modal.vue'
import EditSessionModal from '@/features/sessions/components/edit-session-modal/edit-session-modal.vue'
import NewSessionModal from '@/features/sessions/components/new-session-modal/new-session-modal.vue'
import ReviewSessionModal from '@/features/sessions/components/review-session-modal/review-session-modal.vue'
import SessionDetailsModal from '@/features/sessions/components/session-details-modal/session-details-modal.vue'
import SystemMenu from '@/features/system/components/system-menu/system-menu.vue'
import ConfirmActionModal from '@/features/ui/components/confirm-action-modal/confirm-action-modal.vue'
import MainNav from '@/features/ui/components/layout/main-nav/main-nav.vue'
import MobileNav from '@/features/ui/components/layout/mobile-menu/mobile-menu.vue'
import PhoneModal from '@/features/ui/components/layout/phone-modal/phone-modal.vue'
import TopNav from '@/features/ui/components/layout/top-nav/top-nav.vue'
import MoreActionsMenu from '@/features/ui/components/more-actions-menu/more-actions-menu.vue'
import menuStore from '@/features/ui/store/menu/menu.store'
import UserProfileModal from '@/features/users/components/user-profile-modal/user-profile-modal.vue'

// get the initial collapsed state preference of the main nav (not reactive)
const initialNavCollapsedState = menuStore.isDesktopNavCollapsed

const environmentRef = ref(import.meta.env.VITE_ENV)
</script>

<style lang="scss">
@import '@/features/ui/components/layout/layout.scss';
</style>
