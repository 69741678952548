<template>
  <div :class="`dropdown ${className ? className : ''}`">
    <div class="dropdown__header" @click="toggleDropdown">
      <div v-if="title" class="dropdown__header__title">
        {{ title }}
      </div>
      <slot v-else name="header"></slot>
      <div class="dropdown__header__toggle-btn">
        <DropdownIcon color="black" :deployed="isOpenRef" />
      </div>
    </div>
    <div ref="dropdownContentRef" class="dropdown__content">
      <slot v-if="isLoadedRef"></slot>
      <LoadingContent v-else :text="t('ui.message.loading')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import DropdownIcon from '@/features/ui/components/icons/dropdown-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const isOpenRef = ref(false)
const dropdownContentRef = ref<HTMLDivElement | null>(null)

function toggleDropdown() {
  isOpenRef.value = !isOpenRef.value
}

const props = defineProps<{
  title?: string
  defaultOpen?: boolean
  isLoaded?: boolean
  className?: string
}>()

const isLoadedRef = computed(() => props.isLoaded ?? true)

onMounted(() => {
  if (props.defaultOpen) {
    isOpenRef.value = true
  }
})

watch(
  isLoadedRef,
  (isLoaded) => {
    if (props.defaultOpen) {
      isOpenRef.value = isLoaded
    }
  },
  { immediate: true, flush: 'post' },
)

watch(
  [isOpenRef, dropdownContentRef, isLoadedRef],
  async ([isOpen, dropdownContent]) => {
    if (!dropdownContent) {
      return
    }

    await nextTick()

    if (isOpen) {
      dropdownContent.style.height = `${dropdownContent.scrollHeight + 16}px`
    } else {
      dropdownContent.style.height = '0px'
    }
  },
  { immediate: true, flush: 'post' },
)
</script>

<style lang="scss">
@import './dropdown.scss';
</style>
