<template>
  <Modal
    v-if="selectedProgramRef && userAccountRef"
    :on-exit="() => modalsStore.toggleShouldHideMatchingModals(true)"
    :modal="mentorMatchingModalRef"
    :steps="
      selectedProgramRef?.settings.isMatchingEnabled
        ? userAccountRef?.matchingCriteria
          ? [{ component: FilledMatchingContent, id: 'matching-filled' }]
          : [
              { component: programGoalReminder, id: 'goal-reminder' },
              { component: MatchingModalContent, id: 'fill-criteria' },
            ]
        : [{ component: ClosedMatchingContent, id: 'matching-no-groups' }]
    "
  />
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/features/auth/types'
import useGroups from '@/features/groups/hooks/use-groups.hook'
import FilledMatchingContent from '@/features/matching/components/matching-modals/filled-matching-content/filled-matching-content.vue'
import MatchingModalContent from '@/features/matching/components/matching-modals/matching-modal-content/matching-modal-content.vue'
import ClosedMatchingContent from '@/features/matching/components/matching-modals/no-groups-matching-content/no-groups-matching-content.vue'
import programGoalReminder from '@/features/matching/components/matching-modals/program-goal-reminder/program-goal-reminder.vue'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import Modal from '@/features/ui/components/modal/modal.vue'
import {
  selectGetMentorMatchingModal,
  selectGetShouldHideMatchingModals,
} from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useUser from '@/features/users/hooks/use-user.hook'

const { accountRef } = useAccount()
const accountIdRef = computed(() => accountRef.value?.id)
const { userRef: userAccountRef } = useUser(accountIdRef)
const roleRef = computed(() => accountRef.value?.role)
const mentorMatchingModalRef = selectGetMentorMatchingModal(modalsStore)

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { programRef: selectedProgramRef, isPendingRef: isProgramPendingRef } = useProgram(selectedProgramIdRef)

const { groupsRef, isPendingRef: isGroupsPendingRef } = useGroups()

const shouldHideMatchingModalsRef = selectGetShouldHideMatchingModals(modalsStore)

// on mount, if it's a mentor, check if they have a mentorship group for the current program
// if the mentor doesn't have a mentorship group for the current program, show the mentor matching modal
watchEffect(() => {
  if (roleRef.value === RoleEnumType.MENTOR) {
    // if it's pending or the user manually ask for the modal to be closed, close the modal
    if (isGroupsPendingRef.value || isProgramPendingRef.value || shouldHideMatchingModalsRef.value) {
      modalsStore.toggleModal('mentor-matching', false)
      return
    }

    if (!mentorMatchingModalRef.value.isOpen && groupsRef.value.length === 0) {
      modalsStore.toggleModal('mentor-matching', true)
    } else if (groupsRef.value.length > 0 && mentorMatchingModalRef.value.isOpen) {
      modalsStore.toggleModal('mentor-matching', false)
    }
  }
})
</script>
