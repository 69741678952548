<template>
  <div class="c-btns-container" v-if="!isSessionPendingRef">
    <CustomButton
      usage="button"
      type="submit"
      color="danger"
      :text="`${accountRef?.role === RoleEnumType.MENTOR ? t('ui.button.cancel') : t('ui.button.decline')}`"
      @emit:click="cancelSession"
    />
    <CustomButton
      v-if="accountRef?.role === RoleEnumType.MENTOR"
      usage="button"
      type="submit"
      color="primary"
      text="Modifier"
      @emit:click="openEditSessionModal"
    >
      <template #icon>
        <EditIcon color="white" />
      </template>
    </CustomButton>
    <CustomButton usage="button" type="submit" color="primary" text="Rejoindre" @emit:click="joinMeeting">
      <template #icon>
        <CameraIcon color="white" />
      </template>
    </CustomButton>
  </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/features/auth/types'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { getMeetingLink } from '@/features/meetings/utils'
import { V1UpdateSessionStatusMutation } from '@/features/sessions/api'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/features/sessions/types'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CameraIcon from '@/features/ui/components/icons/camera-icon.vue'
import EditIcon from '@/features/ui/components/icons/edit-icon.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import type { ConfirmActionModalType, EditSessionModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useRouter from '@/hooks/use-router.hook'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { invalidateSession, invalidateSessions } from '@/utils/lib/vue-query'

const toast = useToast()
const router = useRouter()
const { t } = useI18n()

const { accountRef } = useAccount()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)
const { sessionRef, isPendingRef: isSessionPendingRef } = useSession(sessionIdRef)

function joinMeeting() {
  if (!sessionRef.value) return
  window.location.href = getMeetingLink(sessionRef.value)
  modalsStore.toggleModal('session-details', false)
}

function openEditSessionModal() {
  modalsStore.toggleModal('session-details', false)
  modalsStore.toggleModal('edit-session', true)
  modalsStore.updateModalAttachedData<EditSessionModalType>('edit-session', {
    sessionId: sessionIdRef.value!,
  })
}

// update the session status
const { mutate: updateSessionStatus } = useMutation({
  mutationFn: V1UpdateSessionStatusMutation,
  onSuccess() {
    modalsStore.toggleModal('session-details', false)
    invalidateSessions({ groupId: selectedGroupIdRef.value! })
    invalidateSession(sessionIdRef.value!)

    if (accountRef.value?.role === RoleEnumType.MENTOR) {
      toast?.success(t('session.cancel.success'))
    } else {
      toast?.success(t('session.decline.success'))
    }
  },
  onError() {
    modalsStore.toggleModal('session-details', false)
    toast?.error(t('error.common'))
  },
})

function cancelSession() {
  modalsStore.toggleModal('confirm-action', true)
  modalsStore.updateModalAttachedData<ConfirmActionModalType>('confirm-action', {
    title: t('session.cancel.confirmation.title'),
    content: t('ui.modal.confirmation.irreversible'),
    cancelFn() {
      modalsStore.toggleModal('confirm-action', false)
    },
    fn() {
      if (sessionRef.value?.id && accountRef.value?.id) {
        updateSessionStatus({
          sessionId: sessionRef.value.id,
          status: SessionStatusEnumType.CANCELLED,
        })
      } else {
        toast?.error(t('error.common'))
        throw new Error('Session or account not found')
      }
    },
  })
}
</script>
