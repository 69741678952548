<template>
  <AppLayout>
    <template #middle>
      <div class="c-btns-container">
        <CustomButton
          color="primary"
          usage="button"
          @emit:click="downloadDeliverable"
          :text="t('ui.button.download')"
        />
      </div>
    </template>
  </AppLayout>
</template>

<script setup lang="ts">
import AppLayout from '@/(routes)/(protected)/(app)/layout.vue'
import { getDeliverableURL } from '@/features/sessions/api'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import useRouter from '@/hooks/use-router.hook'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { downloadFile } from '@/utils/client/download-file'

const router = useRouter()
const toast = useToast()
const { t } = useI18n()

async function downloadDeliverable() {
  const params = router?.currentRoute.params
  if (!params) {
    toast?.error('No params found')
    return
  }
  const { userId, sessionId, deliverableId } = params
  if (!userId || !sessionId || !deliverableId) {
    toast?.error('No userId, sessionId or deliverableId found')
    return
  }
  const data = await getDeliverableURL({ userId, sessionId, deliverableId })
  downloadFile(data.url)
}
</script>
