<template>
  <div class="group-row" @click="openGroupStructure">
    <span class="group-row__name">{{ group.name }}</span>
    <UserInfo v-if="mentorRef" :user="mentorRef" :sub-info="mentorRef?.email" :is-sub-info-copyable="true" />
    <!-- empty div if no mentor -->
    <template v-else><div></div></template>
    <div class="group-row__mentees-list">
      <UserInfo
        v-for="mentee in menteesRef"
        :key="mentee.id"
        :user="mentee"
        :sub-info="mentee?.email"
        :is-sub-info-copyable="true"
      />
    </div>
    <div class="group-row__goals">
      <span class="group-row__goals__number">
        {{ group.completedSessions }}
        <!-- / {{ programRef?.periods.find((period) => period.id === selectedPeriodIdRef)?.totalSession || `∞` }} -->
      </span>
      <DonutProgressIcon
        :progress="
          // (group.completedSessions /
          //   (programRef?.periods.find((period) => period.id === selectedPeriodIdRef)?.totalSession || 1)) *
          // 100
          group.completedSessions > 0 ? 100 : 0
        "
        color="success"
      />
    </div>
    <div class="group-row__actions" data-action="admin-group" :data-attached="JSON.stringify(group)">
      <MoreOptionsIcon color="black" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { V2GroupType } from '@/features/groups/types'
import { getMentees, getMentor } from '@/features/groups/utils'
import DonutProgressIcon from '@/features/ui/components/icons/donut-progress-icon.vue'
import MoreOptionsIcon from '@/features/ui/components/icons/more-options-icon.vue'
import type { AdminGroupStructureModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserInfo from '@/features/users/components/user-info/user-info.vue'

const props = defineProps<{
  group: V2GroupType
}>()

const mentorRef = computed(() => props.group.users.find(getMentor))
const menteesRef = computed(() => props.group.users.filter(getMentees))

function openGroupStructure(e: MouseEvent) {
  // guard to not open the modal when clicking on the more options icon
  // guard to not open the modal when clicking on the sub info and this one is copyable
  if (
    (e.target as HTMLElement).closest('[data-action="admin-group"]') ||
    (e.target as HTMLElement).closest('[data-sub-info-copyable="true"]')
  ) {
    return
  }

  modalsStore.updateModalAttachedData<AdminGroupStructureModalType>('admin-group-structure', {
    groupId: props.group.id,
  })
  modalsStore.toggleModal('admin-group-structure', true)
}
</script>

<style lang="scss">
@import './group-row.scss';
</style>
