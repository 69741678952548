<template>
  <div
    class="groups-dropdown"
    v-if="
      groupsRef.length &&
      selectedGroupIdRef &&
      checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER) &&
      !isPendingRef
    "
    @click="onClick"
  >
    <GroupIcon color="primary" filled />
    <p class="label">{{ groupRef?.name }}</p>
    <div class="users-list">
      <UserBadge v-if="mentorRef" :user="mentorRef" noStatus variant="small" />
      <UsersBadgesStack :users="menteesRef" noStatus variant="small" />
    </div>
    <DropdownIcon color="primary" />
    <div class="c-notification-badge" v-if="doesUserHaveNotificationsInOtherGroupsRef"></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/features/auth/types'
import useGroup from '@/features/groups/hooks/use-group.hook'
import useGroups from '@/features/groups/hooks/use-groups.hook'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { getMentees, getMentor } from '@/features/groups/utils'
import UserBadge from '@/features/sessions/components/user-badge/user-badge.vue'
import UsersBadgesStack from '@/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import DropdownIcon from '@/features/ui/components/icons/dropdown-icon.vue'
import GroupIcon from '@/features/ui/components/icons/group-icon.vue'
import popoversStore from '@/features/ui/store/popover/popover.store'
import checkPermissionsGroup from '@/features/users/utils'
import useNotifications from '@/hooks/use-notifications.hook'

const { accountRef } = useAccount()
const { groupsRef } = useGroups()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { groupRef, isPendingRef } = useGroup(selectedGroupIdRef)
const { allNotificationsPerGroupRef } = useNotifications()
const mentorRef = computed(() => groupRef.value?.users.find(getMentor))
const menteesRef = computed(() => groupRef.value?.users.filter(getMentees) || [])

// check if the user has notifications in other groups
const doesUserHaveNotificationsInOtherGroupsRef = computed(() => {
  return groupsRef.value.some(
    (group) =>
      group.id !== selectedGroupIdRef.value &&
      allNotificationsPerGroupRef?.value?.find((notifPerGroup) => notifPerGroup.mentorshipGroupId === group.id)
        ?.notifications.total,
  )
})

function onClick(e: MouseEvent) {
  popoversStore.togglePopover('groups', true, e)
}
</script>

<style lang="scss">
@import './groups-dropdown.scss';
</style>
