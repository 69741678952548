<template>
  <AuthWrapper>
    <template #header>
      <h1 v-html="t('auth.login.title', { appTitle: whiteLabelRef.companyTitle })"></h1>
      <p
        v-if="whiteLabelRef.samlLoginEnabled === 'true' && whiteLabelRef.emailLoginEnabled === 'true'"
        v-html="t('auth.login.body', { appTitle: whiteLabelRef.companyTitle })"
      ></p>
    </template>
    <template #body>
      <div class="c-btns-container">
        <CustomButton
          v-if="whiteLabelRef.samlLoginEnabled === 'true'"
          usage="link"
          color="primary"
          :text="t('auth.login.quickLogin')"
          :link="{ url: ROUTES.LOGIN_WITH_SAML, isInternal: true }"
        ></CustomButton>
        <CustomButton
          v-if="whiteLabelRef.emailLoginEnabled === 'true'"
          usage="link"
          :color="whiteLabelRef.samlLoginEnabled === 'true' ? 'white' : 'primary'"
          :text="t('auth.login.classicLogin')"
          :link="{ url: ROUTES.lOGIN_WITH_EMAIL, isInternal: true }"
          data-test-id="submit-email"
        ></CustomButton>
      </div>
    </template>
  </AuthWrapper>
</template>

<script setup lang="ts">
import AuthWrapper from '@/features/auth/components/auth-wrapper/auth-wrapper.vue'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import useWhiteLabel from '@/hooks/use-white-label.hook'
import { useI18n } from '@/lib/i18n'
import { ROUTES } from '@/utils/config/constants'

const { whiteLabelRef } = useWhiteLabel()
const { t } = useI18n()
</script>

<style scoped lang="scss"></style>
