<template>
  <ModalStep
    :id="'edit-password-modal-content'"
    :header="{
      title: t('auth.password.modify.title'),
    }"
  >
    <template #bodyContent>
      <form>
        <CustomInput
          id="current-password"
          type="password"
          :label="t('auth.password.modify.currentPassword')"
          placeholder="********"
          :value="currentPasswordRef"
          @emit:change="currentPasswordChangeHandler"
        />
        <CustomInput
          id="new-password"
          type="password"
          :label="t('auth.password.modify.newPassword')"
          placeholder="********"
          :value="newPasswordRef"
          @emit:change="newPasswordChangeHandler"
          :errors="passwordErrors[0]"
        />
        <CustomInput
          id="confirm-new-password"
          type="password"
          :label="t('auth.password.modify.confirmNewPassword')"
          placeholder="********"
          :value="confirmNewPasswordRef"
          @emit:change="confirmNewPasswordChangeHandler"
        />
      </form>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="button"
          color="white"
          @emit:click="closeModal"
          :text="t('ui.button.cancel')"
        ></CustomButton>
        <CustomButton
          usage="button"
          type="button"
          color="danger"
          @emit:click="submitForm"
          :isDisabled="!confirmNewPasswordRef || !newPasswordRef || !currentPasswordRef"
          :isLoading="isPending"
          :text="t('ui.button.confirm')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import type { ZodError } from 'zod'
import z from 'zod'

import { changePasswordMutation } from '@/features/auth/api'
import useAccount from '@/features/auth/hooks/use-account.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const toast = useToast()
const { accountRef } = useAccount()

const currentPasswordRef = ref<null | string>(null)
const newPasswordRef = ref<null | string>(null)
const confirmNewPasswordRef = ref<null | string>(null)

const passwordSchema = z
  .string({
    invalid_type_error: 'Le mot de passe est requis',
    required_error: 'Le mot de passe est requis',
  })
  .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
  .regex(/(?=.*[a-z])/, 'Le mot de passe doit contenir au moins une lettre minuscule')
  .regex(/(?=.*[A-Z])/, 'Le mot de passe doit contenir au moins une lettre majuscule')
  .regex(/(?=.*[0-9])/, 'Le mot de passe doit contenir au moins un chiffre')

const validationSchema = z
  .object({
    // either no value or a valid phone number
    currentPassword: z.string(),
    newPassword: passwordSchema,
    confirmNewPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: `Les mots de passe ne correspondent pas`,
  })

// live validate the new password
const passwordErrors = computed(() => {
  if (!newPasswordRef.value) return []
  const result = passwordSchema.safeParse(newPasswordRef.value)
  if (!result.success) {
    return result.error.issues.map((issue) => issue.message)
  }
  return []
})

const { mutate: changePassword, isPending } = useMutation({
  mutationFn: changePasswordMutation,
  onError: () => {
    toast?.error('Une erreur est survenue')
  },
  onSuccess: async () => {
    toast?.success('Votre mot de passe a bien été modifié')
    modalsStore.toggleModal('change-password', false)
  },
})

async function submitForm() {
  try {
    const data = await validationSchema.parseAsync({
      currentPassword: currentPasswordRef.value,
      newPassword: newPasswordRef.value,
      confirmNewPassword: confirmNewPasswordRef.value,
    })

    if (!accountRef.value) {
      throw new Error('No account')
    }
    changePassword({
      userId: accountRef.value.id!,
      oldPassword: data.currentPassword,
      password: data.newPassword,
      confirmPassword: data.confirmNewPassword,
    })
  } catch (e: any) {
    // handle zod errors
    const error: ZodError = e
    error.errors.forEach((err) => {
      toast?.error(err.message)
    })
  }
}

function currentPasswordChangeHandler(value: string) {
  currentPasswordRef.value = value
}
function newPasswordChangeHandler(value: string) {
  newPasswordRef.value = value
}
function confirmNewPasswordChangeHandler(value: string) {
  confirmNewPasswordRef.value = value
}

function closeModal() {
  modalsStore.toggleModal('change-password', false)
}
</script>

<style lang="scss">
@import './edit-password-content.scss';
</style>
