<template>
  <section class="admin-groups-content">
    <UsersSearch :search-callback="searchCallback" @emit:result="setEmail" />
    <GridBlockBase id="groups-listing">
      <template #icon>
        <GroupIcon color="black" filled />
      </template>
      <template #headerExtra>
        <div class="filters-container">
          <CustomSelect
            id="group-nb-mentees-select"
            :value="nbMenteesRef"
            :options="nbMenteesOptionsRef"
            @emit:change="onGroupNbMenteesChange"
          />
        </div>
        <CustomButton
          color="primary"
          :text="t('admin.groups.addGroup')"
          usage="button"
          @emit:click="openAddGroupModal"
        />
      </template>
      <template #body>
        <GroupsTable :groups="groupsRef" :is-pending="isPendingRef" />
      </template>
      <template #footer>
        <div>
          <span class="table-sub-info"
            >Résultats {{ Math.min(Math.max(1, perPageRef * (pageRef - 1) + 1), totalGroupsRef ?? 1) }} à
            {{ Math.min(perPageRef * pageRef, totalGroupsRef ?? 1) }} sur {{ totalGroupsRef }}</span
          >
        </div>
        <div class="page-select-wrapper">
          <CustomSelect id="page-select" :value="`${pageRef}`" :options="pagesOptionsRef" @emit:change="onPageChange" />
          <span class="table-sub-info"> Total de pages : {{ totalPagesRef }}</span>
        </div>
      </template>
    </GridBlockBase>
  </section>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import GroupsTable from '@/features/admin/groups/components/groups-table/groups-table.vue'
import useGroups from '@/features/groups/hooks/use-groups.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import GroupIcon from '@/features/ui/components/icons/group-icon.vue'
import CustomSelect from '@/features/ui/components/select/custom-select.vue'
import UsersSearch from '@/features/ui/pages/admin/users/users-search/users-search.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import type { V2UserAdminDataType } from '@/features/users/types'
import { useI18n } from '@/lib/i18n'

const pageRef = ref<number>(1)
const perPageRef = ref<number>(20)
const { t } = useI18n()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

/**
 * Computes the options for the number of mentees in a group dropdown.
 * The maximum number of mentees is determined by the program's maxUserPerGroup setting.
 * If maxUserPerGroup is not set, it defaults to 0, resulting in only the "any" option.
 */
const nbMenteesRef = ref<string>('any')
const nbMenteesOptionsRef = computed<{ label: string; value: string }[]>(() => {
  return [
    { label: t('admin.groups.table.nbMentees.value.any'), value: 'any' },
    ...(maxMenteesPerGroupRef.value ?? []).map((maxMentees) => ({
      label: t('admin.groups.table.nbMentees.value', maxMentees),
      value: `${maxMentees}`,
    })),
  ]
})

// on program change, reset the number of mentees
watch(selectedProgramIdRef, () => {
  nbMenteesRef.value = 'any'
})

function onGroupNbMenteesChange(value: string) {
  nbMenteesRef.value = value
}

const userMailToFilterRef = ref<string | null>(null)
const setEmail = (user: V2UserAdminDataType | undefined) => {
  userMailToFilterRef.value = user?.email ?? null
}
const searchCallback = () => {
  pageRef.value = 1
}

const { groupsRef, totalPagesRef, totalGroupsRef, maxMenteesPerGroupRef, isPendingRef } = useGroups({
  pageRef,
  perPageRef,
  searchRef: userMailToFilterRef,
  nbMenteesRef,
})

const pagesOptionsRef = computed(() => {
  return Array.from({ length: totalPagesRef.value ?? 0 }, (_, i) => ({
    label: `${t('ui.pagination.page')} ${i + 1}`,
    value: `${i + 1}`,
  })) satisfies { label: string; value: string }[]
})

function onPageChange(value: number) {
  pageRef.value = value
}

function openAddGroupModal() {
  modalsStore.updateModalAttachedData('admin-group-structure', { groupId: null })
  modalsStore.toggleModal('admin-group-structure', true)
}
</script>

<style lang="scss">
@import './groups-content.scss';
</style>
