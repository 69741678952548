<template>
  <div class="file-upload-wrapper">
    <div
      class="file-upload"
      ref="fileUploadContainerRef"
      @dragenter="handleDragEnter"
      @dragleave="handleDragLeave"
      @dragover="handleDragOver"
      @drop="handleDrop"
    >
      <div class="icons-container">
        <FileIcon color="primary" filled />
        <FileIcon color="primary" filled />
        <AddFileIcon color="primary" filled />
      </div>
      <p class="title">
        {{ t('ui.input.file.upload.dragAndDrop') }}
      </p>
      <div class="instructions-container">
        <slot name="instructions"></slot>
      </div>
    </div>
    <input class="trigger-input" ref="fileUploadInputRef" type="file" :accept="accept" @change="handleFileChange" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'

import AddFileIcon from '@/features/ui/components/icons/add-file-icon.vue'
import FileIcon from '@/features/ui/components/icons/file-icon.vue'
import { useI18n } from '@/lib/i18n'

const props = defineProps<{
  accept: string
  callback: (file: File) => void
}>()

const { t } = useI18n()

const fileUploadContainerRef = ref<HTMLDivElement | null>(null)
const fileUploadInputRef = ref<HTMLInputElement | null>(null)

const handleDragEnter = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  fileUploadContainerRef.value?.classList.add('dragging')
}

const handleDragLeave = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  fileUploadContainerRef.value?.classList.remove('dragging')
}

const handleDragOver = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
}

const handleDrop = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  const file = e.dataTransfer?.files?.[0]
  console.log(file)
  if (!file) {
    return
  }
  props.callback(file)
}

const handleFileChange = (e: Event) => {
  const file = (e.target as HTMLInputElement).files?.[0]
  if (!file) {
    return
  }
  props.callback(file)
}

const handleClick = () => {
  fileUploadInputRef.value?.click()
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    fileUploadInputRef.value?.click()
  }
}

onMounted(() => {
  const fileUploadContainer = fileUploadContainerRef.value

  fileUploadContainer?.addEventListener('dragenter', handleDragEnter)
  fileUploadContainer?.addEventListener('click', handleClick)
  fileUploadContainer?.addEventListener('keydown', handleKeyDown)
})

onBeforeMount(() => {
  const fileUploadContainer = fileUploadContainerRef.value

  fileUploadContainer?.removeEventListener('dragenter', handleDragEnter)
  fileUploadContainer?.removeEventListener('click', handleClick)
  fileUploadContainer?.removeEventListener('keydown', handleKeyDown)
})
</script>

<style lang="scss">
@import './file-upload.scss';
</style>
