<template>
  <ModalStep
    id="finished-review-modal-content"
    :header="{
      title: t('session.finishedReview.title'),
    }"
  >
    <template #bodyContent>
      <div class="check-icon-wrapper">
        <CheckIcon color="success" />
      </div>
      <div class="c-info-wrapper">
        <p class="c-info-label" style="text-align: center">
          <strong>
            {{ t('session.finishedReview.body.part1') }}
          </strong>
        </p>
        <p class="c-info-text" style="text-align: center">
          {{ t('session.finishedReview.body.part2') }}
        </p>
      </div>
    </template>

    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.finish')"
          icon-position="right"
          @emit:click="onFinish"
        >
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CheckIcon from '@/features/ui/components/icons/check-icon.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { ANALYTICS_EVENTS, trackEvent } from '@/lib/posthog'

const toast = useToast()
const { t } = useI18n()

function onFinish() {
  trackEvent(ANALYTICS_EVENTS.USER_CLICK_COMPLETE_SESSION_REVIEW)
  modalsStore.toggleModal('review-session', false)
  toast?.success(t('session.finishedReview.success'))
}
</script>

<style lang="scss">
@import './finished-review-modal-content.scss';
</style>
