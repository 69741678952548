import { create } from 'zustand'
import { combine, persist } from 'zustand/middleware'

import { setAuthTokenAction, setUserRoleAction } from './auth.actions'

import type { RoleEnumType } from '@/features/auth/types'
import subscribeToStore from '@/lib/zustand'

export type AuthStoreValuesType = {
  authToken: string | null
  userRole: RoleEnumType | null
}

export const INITIAL_AUTH_STORE_VALUES: AuthStoreValuesType = {
  authToken: null,
  userRole: null,
}

export const AUTH_STORAGE_KEY = 'auth'

const authStore = subscribeToStore(
  create(
    persist(
      combine(INITIAL_AUTH_STORE_VALUES, (set, get) => ({
        setAuthToken: async (authToken: string) => setAuthTokenAction(get, set, authToken),
        setUserRole: (userRole: RoleEnumType) => setUserRoleAction(get, set, userRole),
      })),
      {
        name: AUTH_STORAGE_KEY,
      },
    ),
  ),
)

export default authStore
