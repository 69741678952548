<template>
  <Dropdown
    class-name="session-presences"
    :title="t('session.presences.title')"
    :default-open="false"
    :is-loaded="!isPendingRef"
  >
    <template v-if="sessionRef && sessionRef?.presences.length > 0">
      <template v-if="mentorRef">
        <UserCard
          v-for="user in [mentorRef, ...menteesRef]"
          :key="user.id"
          :user="user"
          :status="
            user.role === RoleEnumType.MENTOR
              ? SessionStatusEnumType.FINISHED
              : sessionRef?.presences.find((presence) => presence.userId === user?.id)?.wasPresent
                ? SessionStatusEnumType.FINISHED
                : SessionStatusEnumType.CANCELLED
          "
          :no-actions="true"
        />
      </template>
    </template>
    <EmptyContent v-else :text="t('session.presences.noPresences')" />
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RoleEnumType } from '@/features/auth/types'
import { getMentees, getMentor } from '@/features/groups/utils'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/features/sessions/types'
import Dropdown from '@/features/ui/components/dropdown/dropdown.vue'
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserCard from '@/features/users/components/user-card/user-card.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)

const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])

const mentorRef = computed(() => sessionRef.value?.users.find(getMentor))
</script>

<style lang="scss">
@import './session-presences.scss';
</style>
