import { useQuery } from '@tanstack/vue-query'
import { computed, type ComputedRef } from 'vue'

import { getFirstGroupMessage, V1_FIRST_GROUP_MESSAGE_KEY } from '@/features/chat/api'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'

export function useFirstGroupMessage(
  conversationIdRef: ComputedRef<string | null>,
  isFirstMessageNotSentRef: ComputedRef<boolean>,
) {
  const selectedMentorshipGroudIdRef = selectGetSelectedGroupId(groupsStore)
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

  const isGetFirstGroupMessageEnabledRef = computed(
    () =>
      !!conversationIdRef.value &&
      !!isFirstMessageNotSentRef.value &&
      !!selectedMentorshipGroudIdRef.value &&
      !!selectedProgramIdRef.value,
  )

  const { data, isPending, isLoading, isError } = useQuery({
    queryKey: [V1_FIRST_GROUP_MESSAGE_KEY, conversationIdRef],
    queryFn: () =>
      getFirstGroupMessage({
        mentorshipGroupId: selectedMentorshipGroudIdRef.value!,
        programId: selectedProgramIdRef.value!,
      }),
    enabled: isGetFirstGroupMessageEnabledRef, // we enable the query only when the conversationId is available and the first message has not been sent
    gcTime: 0, // we don't want any garbage collection
  })

  return {
    firstGroupMessageRef: data,
    isPendingRef: isPending,
    isLoadingRef: isLoading,
    isErrorRef: isError,
  }
}
