<template>
  <Dropdown
    class-name="session-reviews"
    :title="t('session.review.title')"
    :default-open="false"
    :is-loaded="!isPendingRef"
  >
    <template v-if="sessionRef && sessionRef.reviews.length > 0">
      <p class="reviews-rating-average">
        <span>{{ t('session.review.averageOfRates') }}</span>
        <span>
          {{
            Math.round(
              (sessionRef?.reviews.reduce((acc, review) => acc + review.grade, 0) / sessionRef?.reviews.length) * 10,
            ) / 10
          }}/5
        </span>
      </p>
      <div class="reviews-listing">
        <div v-for="user in [mentorRef, ...menteesRef]" :key="user?.id" class="review-card no-actions">
          <div class="review-card-top-row">
            <UserInfo v-if="user" :user="user" :sub-info="user?.role === RoleEnumType.MENTEE ? 'Mentoré' : 'Mentor'" />
            <div class="rating-container">
              <template v-if="(sessionRef.reviews.find((review) => review.userId === user?.id)?.grade ?? 0) > 0">
                <div
                  v-for="i in 5"
                  :key="i"
                  :class="`rating-container__star ${
                    (sessionRef.reviews.find((review) => review.userId === user?.id)?.grade ?? 0) >= i
                      ? 'activated'
                      : ''
                  }`"
                >
                  <StarIcon color="black" />
                </div>
              </template>
              <template v-else>
                <span>{{ t('session.review.noRate') }}</span>
              </template>
            </div>
          </div>
          <p
            class="review-card__comment"
            v-if="sessionRef.reviews.find((review) => review.userId === user?.id)?.review"
          >
            "{{ sessionRef.reviews.find((review) => review.userId === user?.id)?.review }}"
          </p>
        </div>
      </div>
    </template>
    <EmptyContent v-else :text="t('session.review.noReview')" />
  </Dropdown>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RoleEnumType } from '@/features/auth/types'
import { getMentees, getMentor } from '@/features/groups/utils'
import useSession from '@/features/sessions/hooks/use-session.hook'
import Dropdown from '@/features/ui/components/dropdown/dropdown.vue'
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import StarIcon from '@/features/ui/components/icons/star-icon.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserInfo from '@/features/users/components/user-info/user-info.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)

const { sessionRef, isPendingRef } = useSession(sessionIdRef)

const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])

const mentorRef = computed(() => sessionRef.value?.users.find(getMentor))
</script>

<style lang="scss">
@import './session-reviews.scss';
</style>
