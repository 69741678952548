<template>
  <div :class="`message ${message.userId === accountRef?.id ? 'message--own' : ''}`">
    <div class="message__content">
      <div class="message__content__avatar">
        <Avatar v-if="userRef" :user="userRef" />
      </div>
      <div class="message__content__text">
        <div v-html="formatMessage(message.content)"></div>
        <div class="message__content__text__info">
          <span>{{ sentAtRelativeTimeRef }}</span>
        </div>
      </div>
    </div>

    <div class="message__sub-info">
      <div class="message__sub-info__info">
        <template v-if="userRef">
          <span> {{ userRef.firstName }} {{ userRef.lastName }}</span
          ><span>·</span><span v-if="userRef.role">{{ t(userRolesNamesMap[userRef.role]) }}</span>
        </template>
        <template v-else>
          <span>{{ t('ui.message.loading') }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify'
import linkifyHtml from 'linkify-html'
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import type { V2MessageType } from '@/features/chat/types'
import Avatar from '@/features/users/components/avatar/avatar.vue'
import useUser from '@/features/users/hooks/use-user.hook'
import { userRolesNamesMap } from '@/features/users/utils'
import { useI18n } from '@/lib/i18n'
import { getRelativeTimeToDate } from '@/utils/dates'

const { t } = useI18n()

const props = defineProps<{
  message: V2MessageType
}>()

const { accountRef } = useAccount()

const messageUserIdRef = computed(() => props.message.userId)
const { userRef } = useUser(messageUserIdRef)

// when the message was sent
const sentAtRelativeTimeRef = computed(() => getRelativeTimeToDate(props.message.sentAt))

// format the message content for links, mails, etc.
function formatMessage(message: string) {
  const formatedMessage = linkifyHtml(DOMPurify.sanitize(message), {
    defaultProtocol: 'https',
    attributes: {
      target: '_blank',
      rel: 'noopener',
    },
  })
  return formatedMessage.trim()
}
</script>

<style lang="scss">
@import './message.scss';
</style>
