<template>
  <ModalStep
    id="session-details-modal-content"
    :onExitHandler="onExitHandler"
    :header="{
      title: t('common.session.capitalize'),
    }"
  >
    <template #headerAfterElement>
      <StatusBadge v-if="sessionRef?.status" :status="sessionRef?.status" />
    </template>
    <template #bodyContent>
      <component v-for="component in activeBodyComponentsRef" :key="component.name" :is="component" />
    </template>

    <template #footer v-if="activeFooterComponentRef">
      <component :is="activeFooterComponentRef" />
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/features/auth/types'
import { RoleEnumType } from '@/features/auth/types'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programStore from '@/features/programs/stores/programs/programs.store'
import SessionDeliverables from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-deliverables/session-deliverables.vue'
import SessionGeneralInformations from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-general-informations/session-general-informations.vue'
import SessionParticipants from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-participants/session-participants.vue'
import SessionPendingActions from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-pending-actions/session-pending-actions.vue'
import SessionPresences from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-presences/session-presences.vue'
import SessionReport from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-report/session-report.vue'
import SessionReviews from '@/features/sessions/components/session-details-modal/session-details-modal-content/components/session-reviews/session-reviews.vue'
import SessionDetailsPlannedFooter from '@/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-planned-footer.vue'
import SessionDetailsWaitConfirmationFooter from '@/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-wait-confirmation-footer.vue'
import SessionDetailsWaitReviewFooter from '@/features/sessions/components/session-details-modal/session-details-modal-content/footers/session-details-wait-review-footer.vue'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/features/sessions/types'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import StatusBadge from '@/features/ui/components/status-badge/status-badge.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import checkPermissionsGroup from '@/features/users/utils'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

defineProps<{
  onExitHandler: (callback?: () => void) => void
}>()

const selectedProgramIdRef = selectGetSelectedProgramId(programStore)
const { programRef } = useProgram(selectedProgramIdRef)
const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)
const { sessionRef } = useSession(sessionIdRef)

const { accountRef } = useAccount()

// active sidebar popup body component
// based on roles and session status
const activeBodyComponentsRef = computed(() => {
  // adview views
  if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN)) {
    const sessionStatus = sessionRef.value?.status
    switch (true) {
      case sessionStatus === SessionStatusEnumType.FINISHED || sessionStatus === SessionStatusEnumType.ONGOING:
        const views = [SessionGeneralInformations, SessionParticipants]

        if (programRef.value?.settings.shouldMentorReviewSessionDeliverableReport) {
          views.push(SessionReport)
        }

        views.push(SessionPresences)

        if (programRef.value?.settings.shouldMentorReviewSessionDeliverableRate) {
          views.push(SessionDeliverables)
        }

        views.push(SessionReviews)

        return views

      default:
        return [SessionGeneralInformations, SessionParticipants]
    }
    // users views
  } else if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.USER)) {
    const sessionStatus = sessionRef.value?.status
    switch (true) {
      case sessionStatus === SessionStatusEnumType.WAIT_REVIEW || sessionStatus === SessionStatusEnumType.FINISHED:
        const views = [SessionGeneralInformations, SessionPendingActions, SessionParticipants]

        if (
          accountRef.value?.role === RoleEnumType.MENTOR &&
          programRef.value?.settings.shouldMentorReviewSessionDeliverableRate
        ) {
          views.push(SessionDeliverables)
        }

        // if session is finished and user is mentor, show report
        if (
          sessionStatus === SessionStatusEnumType.FINISHED &&
          programRef.value?.settings.shouldMentorReviewSessionDeliverableReport
        ) {
          views.push(SessionReport)
        }

        return views

      default:
        return [SessionGeneralInformations, SessionParticipants]
    }
  }
  return []
})

// active sidebar popup footer component
// based on roles and session status
const activeFooterComponentRef = computed(() => {
  // admin views (they don't have footer actions atm)
  if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.ADMIN)) {
    return null

    // users views
  } else if (checkPermissionsGroup(accountRef.value?.role, PermissionsGroupsEnumType.USER)) {
    switch (sessionRef.value?.status) {
      case SessionStatusEnumType.PLANNED:
        return SessionDetailsPlannedFooter

      case SessionStatusEnumType.WAIT_CONFIRMATION:
        return SessionDetailsWaitConfirmationFooter

      case SessionStatusEnumType.WAIT_REVIEW:
        return SessionDetailsWaitReviewFooter

      default:
        return null
    }
  }
  return null
})
</script>

<style lang="scss">
@import './session-details-modal-content.scss';
</style>
