<template>
  <ModalStep
    id="manual-user-import-modal-content"
    :header="{
      title: t('admin.users.manualInvitationModal.title'),
    }"
  >
    <template #headerAfterElement>
      <ProgramsDropdown />
    </template>
    <template #bodyContent>
      <div class="user-import-form">
        <CustomInput
          type="email"
          :class="`${isNewUserRef && !isUserAlreadyInProgramRef ? '' : 'full-length-field'}`"
          id="user-email"
          :label="`${t('ui.input.email.label')} *`"
          :placeholder="t('ui.input.email.placeholder')"
          @emit:change="emailChangeHandler"
          :value="emailRef"
        />
        <template v-if="isNewUserRef && !isUserAlreadyInProgramRef">
          <CustomSelect
            id="user-role"
            :options="roleOptionsRef"
            :label="`${t('ui.input.role.label')} *`"
            @emit:change="roleChangeHandler"
            :value="roleRef"
          />
          <CustomInput
            type="text"
            id="user-firstname"
            :label="`${t('ui.input.firstName.label')} *`"
            :placeholder="t('ui.input.firstName.placeholder')"
            @emit:change="firstNameChangeHandler"
            :value="firstNameRef"
          />
          <CustomInput
            type="text"
            id="user-lastname"
            :label="`${t('ui.input.lastName.label')} *`"
            :placeholder="t('ui.input.lastName.placeholder')"
            @emit:change="lastNameChangeHandler"
            :value="lastNameRef"
          />
          <CustomInput
            type="phone"
            class="full-length-field"
            id="user-phone"
            :label="`${t('auth.phoneModal.input.label')} (${t('ui.input.label.optional')})`"
            :placeholder="t('auth.phoneModal.input.placeholder')"
            @emit:change="phoneChangeHandler"
            :value="phoneRef"
          />
          <!-- <CustomTextarea
            id="user-matching-criteria"
            class="full-length-field"
            label="Critère de mise en relation (optionnel)"
            placeholder="Je suis doctorant en mathématiques spécialisé en finance depuis 2018..."
            @emit:change="matchingCriteriaChangeHandler"
            :value="matchingCriteriaRef"
          /> -->
        </template>
      </div>
      <p style="text-align: center" v-if="isUserAlreadyInProgramRef">
        <strong>{{ t('admin.users.manualInvitationModal.alreadyInsideProgram') }}</strong>
      </p>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="submit" color="white" :text="t('ui.button.cancel')" @emit:click="onClose" />
        <CustomButton
          :is-disabled="!isEmailValidRef || isUserAlreadyInProgramRef"
          :is-loading="isCheckingUserRef || isImportAdminUsersPendingRef || isAddUserToProgramPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="
            isNewUserRef ? t('admin.users.manualInvitationModal.invite') : t('admin.users.manualInvitationModal.add')
          "
          @emit:click="onSubmit"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ref } from 'vue'
import { watch } from 'vue'
import type { ZodError } from 'zod'
import { z } from 'zod'

import { importAdminUsersMutation } from '@/features/admin/users/api'
import type { ImportUsersDTOType } from '@/features/admin/users/types'
import { doesUserExistMutation } from '@/features/auth/api'
import { RoleEnumType } from '@/features/auth/types'
import { addUserToProgramMutation, doesUserExistsInProgramMutation } from '@/features/programs/api'
import ProgramsDropdown from '@/features/programs/components/programs-dropdown/programs-dropdown.vue'
import useProgram from '@/features/programs/hooks/use-program.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import CustomSelect from '@/features/ui/components/select/custom-select.vue'
import type { ConfirmActionModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { invalidateAdminUsers } from '@/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)
const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

const validationSchema = z.object({
  // email is required
  email: z
    .string({ required_error: t('error.requiredEmailField'), invalid_type_error: t('error.requiredEmailField') })
    .email({
      message: t('error.invalidEmail'),
    }),
  // role is required
  role: z.nativeEnum(RoleEnumType, {
    required_error: t('error.requiredRoleField'),
    invalid_type_error: t('error.requiredRoleField'),
  }),
  // phone is optional
  phone: z.string().nullable(),
  // first name is required
  firstname: z.string({
    required_error: t('error.requiredFirstNameField'),
    invalid_type_error: t('error.requiredFirstNameField'),
  }),
  // last name is required
  lastname: z.string({
    required_error: t('error.requiredLastNameField'),
    invalid_type_error: t('error.requiredLastNameField'),
  }),
  // user program id is required (will be inferred from the selected program in the dropdown)
  programId: z.string().nullable(),
  // matching criteria is optional (temp remove it)
  // matchingCriteria: z.string().nullable(),
})

const emailRef = ref<string | null>(null)
const isEmailValidRef = ref<boolean>(false)
const isNewUserRef = ref<boolean>(false)
const isUserAlreadyInProgramRef = ref<boolean>(false)
const isCheckingUserRef = ref<boolean>(false)

// userId will be set if user already exists
const userIdRef = ref<string | null>(null)

async function isUserExists() {
  if (!emailRef.value) return false
  isUserAlreadyInProgramRef.value = false
  const doesUserExist = await doesUserExistMutation({ email: emailRef.value })

  if (doesUserExist.isExist) {
    userIdRef.value = doesUserExist.userId
    const doesUserExistInProgram = await doesUserExistsInProgramMutation({
      programId: selectedProgramIdRef.value!,
      userId: doesUserExist.userId,
    })

    if (doesUserExistInProgram.exist) {
      toast?.error(t('admin.users.manualInvitationModal.alreadyInsideProgram'))
      isUserAlreadyInProgramRef.value = true
    } else {
      return true
    }
  }
  return false
}

// when we switch programs, we check if the user exists
watch(selectedProgramIdRef, () => {
  isUserExists()
})

async function emailChangeHandler(value: string) {
  try {
    emailRef.value = value
    // first we validate the email
    z.string().email().parse(value)
    isEmailValidRef.value = true

    // if the email is correct, then we check if the user exists
    isCheckingUserRef.value = true
    const doesUserExist = await isUserExists()
    isNewUserRef.value = !doesUserExist

    isCheckingUserRef.value = false
  } catch (e) {
    isCheckingUserRef.value = false
    isEmailValidRef.value = false
    isNewUserRef.value = false
  }
}

const phoneRef = ref<string | null>(null)
function phoneChangeHandler(value: string) {
  phoneRef.value = value
}

const firstNameRef = ref<string | null>(null)
function firstNameChangeHandler(value: string) {
  firstNameRef.value = value
}

const lastNameRef = ref<string | null>(null)
function lastNameChangeHandler(value: string) {
  lastNameRef.value = value
}

const roleRef = ref<RoleEnumType | null>(null)
function roleChangeHandler(value: string) {
  roleRef.value = value as RoleEnumType
}
const roleOptionsRef = ref([
  { value: RoleEnumType.MENTOR, label: 'Mentor' },
  { value: RoleEnumType.MENTEE, label: 'Mentoré' },
  { value: RoleEnumType.MANAGER, label: 'Manager' },
])

// const matchingCriteriaRef = ref<string | null>(null)
// function matchingCriteriaChangeHandler(value: string) {
//   matchingCriteriaRef.value = value
// }

const { mutate: importAdminUsers, isPending: isImportAdminUsersPendingRef } = useMutation({
  mutationFn: importAdminUsersMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    await invalidateAdminUsers(selectedProgramIdRef.value!)
    toast?.success(t('admin.users.manualInvitationModal.add.success'))
    modalsStore.toggleModal('admin-manual-user-import', false)
  },
})

const { mutate: addUserToProgram, isPending: isAddUserToProgramPendingRef } = useMutation({
  mutationFn: addUserToProgramMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },

  onSuccess: async () => {
    await invalidateAdminUsers(selectedProgramIdRef.value!)
    toast?.success(t('admin.users.manualInvitationModal.invitation.success'))
    modalsStore.toggleModal('admin-manual-user-import', false)
  },
})

async function onSubmit() {
  modalsStore.toggleModal('confirm-action', true)
  modalsStore.updateModalAttachedData<ConfirmActionModalType>('confirm-action', {
    title: `${t('admin.users.import.confirmation.title', { programName: selectedProgramRef.value?.name, menteeName: firstNameRef.value })}`,
    type: 'danger',
    fn: async () => {
      try {
        // if it's a new user, we import it
        if (isNewUserRef.value) {
          const userToImport = {
            email: emailRef.value,
            phone: phoneRef.value,
            firstname: firstNameRef.value,
            lastname: lastNameRef.value,
            role: roleRef.value,
            programId: selectedProgramIdRef.value,
            matchingCriteria: null,
            sso: false,
          } satisfies ImportUsersDTOType['users'][number]

          await validationSchema.parseAsync(userToImport)

          // do stuff
          importAdminUsers({
            programId: selectedProgramIdRef.value!,
            periodId: selectedPeriodIdRef.value!,
            users: [userToImport],
          })
        } else {
          addUserToProgram({
            programId: selectedProgramIdRef.value!,
            userId: userIdRef.value!,
          })
        }
      } catch (e: any) {
        // handle zod errors
        const error: ZodError = e
        error.errors.forEach((err) => {
          toast?.error(err.message)
        })
      }
    },
  })
}

function onClose() {
  modalsStore.toggleModal('admin-manual-user-import', false)
}
</script>

<style lang="scss">
@import './manual-user-import-modal-content.scss';
</style>
