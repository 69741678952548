<template>
  <ModalStep
    id="new-session-content"
    :header="{
      title: sessionIdRef ? t('session.newSessionModal.modifySession.title') : t('session.newSessionModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="new-session__information">
        <p>
          <strong>{{ t('session.newSessionModal.generalInformation.label') }}</strong>
        </p>
        <ProgramReminder :programId="selectedProgramIdRef" />
        <GroupReminder :groupId="selectedGroupIdRef" />
      </div>
      <LoadingContent v-if="sessionIdRef && isSessionPendingRef" />
      <div v-else class="new-session__session-form">
        <CustomInput
          type="date"
          id="session-date"
          :label="`${t('session.newSessionModal.generalInformation.input.date.label')} *`"
          :placeholder="t('session.newSessionModal.generalInformation.input.date.placeholder')"
          @emit:change="sessionDateHander"
          :value="sessionDateRef"
        />
        <CustomSelect
          id="session-location-type"
          :options="[
            { value: 'visio', label: t('session.type.online') },
            { value: 'in-person', label: t('session.type.irl') },
          ]"
          :label="`${t('session.newSessionModal.generalInformation.input.type.label')} *`"
          @emit:change="sessionTypeHandler"
          :value="sessionTypeRef"
        />
        <CustomInput
          v-if="sessionTypeRef === 'in-person'"
          type="text"
          id="session-location"
          :label="`${t('session.newSessionModal.generalInformation.input.location.label')} *`"
          :placeholder="`${t('session.newSessionModal.generalInformation.input.location.placeholder')}`"
          @emit:change="sessionLocationHandler"
          :value="sessionLocationRef"
        />
        <CustomInput
          v-if="sessionDateRef"
          type="time"
          id="session-schedule"
          :placeholder="t('session.newSessionModal.generalInformation.input.schedule.placeholder')"
          :label="`${t('session.newSessionModal.generalInformation.input.schedule.label')} *`"
          @emit:change="sessionScheduleHandler"
          :value="sessionScheduleRef"
        />
        <CustomSelect
          v-if="sessionDateRef"
          id="session-duration"
          :options="[
            { value: '0.5', label: '30min' },
            { value: '0.75', label: '45min' },
            { value: '1', label: '1h' },
            { value: '1.5', label: '1h30' },
            { value: '2', label: '2h' },
            { value: '2.5', label: '2h30' },
            { value: '3', label: '3h' },
            { value: '3.5', label: '3h30' },
            { value: '4', label: '4h' },
            { value: '4.5', label: '4h30' },
            { value: '5', label: '5h' },
            { value: '5.5', label: '5h30' },
            { value: '6', label: '6h' },
          ]"
          :label="`${t('session.newSessionModal.generalInformation.input.duration.label')} *`"
          @emit:change="sessionDurationHandler"
          :value="sessionDurationRef"
        />
      </div>
      <div class="new-session__mentees-list">
        <p>
          <strong>{{ t('common.mentee.capitalize', menteesRef?.length ?? 1) }}</strong>
        </p>
        <div class="mentees-grid">
          <UserCard v-for="mentee in menteesRef" :key="mentee.id" :user="mentee" />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="submit" color="white" :text="t('ui.button.cancel')" @emit:click="onClose" />
        <CustomButton
          :is-loading="isSubmittingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="sessionIdRef ? t('session.newSessionModal.modify') : t('session.newSessionModal.plan')"
          @emit:click="onSubmit"
        >
          <template #icon>
            <AddCalendarIcon color="white" filled />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'
import type { ZodError } from 'zod'
import { z } from 'zod'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { sendMessageMutation } from '@/features/chat/api'
import GroupReminder from '@/features/groups/components/group-reminder/group-reminder.vue'
import useGroup from '@/features/groups/hooks/use-group.hook'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { getMentees } from '@/features/groups/utils'
import ProgramReminder from '@/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import { V1CreateSessionMutation, V1UpdateSessionMutation } from '@/features/sessions/api'
import useSession from '@/features/sessions/hooks/use-session.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import AddCalendarIcon from '@/features/ui/components/icons/add-calendar-icon.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import CustomSelect from '@/features/ui/components/select/custom-select.vue'
import { selectGetEditSessionModal } from '@/features/ui/store/modal/modal.selectors'
import type { SessionDetailsModalType } from '@/features/ui/store/modal/modal.store'
import type { EditSessionModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserCard from '@/features/users/components/user-card/user-card.vue'
import useRouter from '@/hooks/use-router.hook'
import useToast from '@/hooks/use-toasts.hook'
import dayjsClient from '@/lib/dayjs'
import { useI18n } from '@/lib/i18n'
import { ROUTES } from '@/utils/config/constants'
import { invalidateConversations, invalidateSession, invalidateSessions } from '@/utils/lib/vue-query'

const editSessionModalRef = selectGetEditSessionModal(modalsStore)
const sessionIdRef = computed(() => editSessionModalRef.value?.attachedData.sessionId || null)
const { sessionRef, isPendingRef: isSessionPendingRef } = useSession(sessionIdRef)

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { accountRef } = useAccount()
const { groupRef: selectedGroupRef } = useGroup(selectedGroupIdRef)

const menteesRef = computed(() => selectedGroupRef.value?.users.filter(getMentees))
const toast = useToast()
const router = useRouter()
const { t } = useI18n()

const isSubmittingRef = ref(false)
const validationSchema = z
  .object({
    // date is required
    date: z.string({ required_error: 'La date est requise', invalid_type_error: 'La date est requise' }),
    // type is required
    type: z.string({ required_error: 'Le type est requis', invalid_type_error: 'Le type est requis' }),
    // location is required if type is in-person
    location: z.string().nullable(),
    // schedule is required
    schedule: z.string({ required_error: `L'horaire est requis`, invalid_type_error: `L'horaire est requis` }),
    // duration is required
    duration: z.string({ required_error: 'La durée est requise', invalid_type_error: 'La durée est requise' }),
  })
  .refine(
    (data) => {
      if (data.type === 'in-person' && !data.location) {
        return false
      }
      return true
    },
    {
      message: 'Le lieu est requis si le type est en présentiel',
      path: ['location'],
    },
  )

// session date
const sessionDateRef = ref<string | null>(null)
function sessionDateHander(value: string) {
  console.log(value)
  sessionDateRef.value = value
}

// session type
const sessionTypeRef = ref<string | null>(null)
function sessionTypeHandler(value: string) {
  sessionTypeRef.value = value
}

// session location
const sessionLocationRef = ref<string | null>(null)
function sessionLocationHandler(value: string) {
  sessionLocationRef.value = value
}

// session schedule
const sessionScheduleRef = ref<string | null>(null)
function sessionScheduleHandler(value: string) {
  sessionScheduleRef.value = value
}

// session duration
const sessionDurationRef = ref<string | null>(null)
function sessionDurationHandler(value: string) {
  sessionDurationRef.value = value
}

watch(
  sessionRef,
  () => {
    if (sessionRef.value) {
      sessionDateRef.value = dayjsClient(sessionRef.value.from).format('YYYY-MM-DD')
      sessionTypeRef.value = sessionRef.value.location === 'visio' ? 'visio' : 'in-person'
      sessionLocationRef.value = sessionRef.value.location
      sessionScheduleRef.value = dayjsClient(sessionRef.value.from).format('HH:mm')
      sessionDurationRef.value = String(dayjsClient(sessionRef.value.to).diff(dayjsClient(sessionRef.value.from), 'h'))
    }
  },
  { immediate: true },
)

const { mutate: sendMessage } = useMutation({
  mutationFn: sendMessageMutation,
  onError: () => {
    toast?.error('Une erreur est survenue')
  },
  onSuccess: async (data, variables) => {
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateConversations(selectedGroupIdRef.value!) // invalidate conversations to refetch the session widget

    router?.push(ROUTES.CHAT(variables.conversationId))

    modalsStore.toggleModal('new-session', false)
  },
  onSettled: () => {
    isSubmittingRef.value = false
  },
})

const { mutate: createSession } = useMutation({
  mutationFn: V1CreateSessionMutation,
  onError: (data) => {
    if (data.message.includes('chevaucher')) {
      toast?.error('La session chevauche une autre session')
    } else {
      toast?.error('Une erreur est survenue')
    }
    isSubmittingRef.value = false
  },
  onSuccess: async (data) => {
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })

    toast?.success('Votre session a bien été créée')

    // if the session was created in the past, we direct the user to the session review
    if (dayjsClient(data.session.from).isBefore(dayjsClient())) {
      modalsStore.toggleModal('new-session', false)
      modalsStore.updateModalAttachedData<SessionDetailsModalType>('session-details', { sessionId: data.session.id })
      modalsStore.toggleModal('session-details', true)
      return
    }

    // else we send a message to the mentorship group
    const conversationId = selectedGroupRef.value?.conversationId

    if (!conversationId) {
      throw new Error('Conversation id is missing')
    }

    // chaining the sendMessage mutation and continue user-flow inside its own onSuccess
    sendMessage({
      conversationId,
      message: `***session=${data.session.id}`,
    })
  },
})

const { mutate: updateSession } = useMutation({
  mutationFn: V1UpdateSessionMutation,
  onError: () => {
    toast?.error('Une erreur est survenue lors de la mise à jour de la session')
  },
  onSuccess: async () => {
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(sessionIdRef.value!)

    toast?.success('Votre session a bien été mise à jour')
    modalsStore.updateModalAttachedData<EditSessionModalType>('edit-session', { sessionId: null })
    modalsStore.toggleModal('edit-session', false)
  },
})

function onClose() {
  if (sessionIdRef.value) {
    modalsStore.toggleModal('edit-session', false)
    modalsStore.updateModalAttachedData<EditSessionModalType>('edit-session', { sessionId: null })
  } else {
    modalsStore.toggleModal('new-session', false)
  }
}

// create the session
async function onSubmit() {
  try {
    isSubmittingRef.value = true
    const data = await validationSchema.parseAsync({
      date: sessionDateRef.value,
      type: sessionTypeRef.value,
      location: sessionLocationRef.value,
      schedule: sessionScheduleRef.value,
      duration: sessionDurationRef.value,
    })

    const fromDate = dayjsClient(`${dayjsClient(data.date).format('YYYY-MM-DD')}T${data.schedule}`).toISOString()
    const toDate = dayjsClient(fromDate).add(Number(data.duration), 'h').toISOString()
    const location = (data.type === 'in-person' ? data.location : data.type) as string

    // if the session already exist, update it
    if (sessionIdRef.value) {
      const sessionData = {
        sessionId: sessionIdRef.value,
        startAt: fromDate,
        endAt: toDate,
        location,
      }

      updateSession(sessionData)
      return

      // else create a new session
    } else {
      const newSessionData = {
        userId: accountRef.value!.id!,
        from: fromDate,
        to: toDate,
        mentoringGroupId: selectedGroupIdRef.value!,
        location,
      }

      createSession(newSessionData)
    }
  } catch (e: any) {
    // handle zod errors
    const error: ZodError = e
    error.errors.forEach((err) => {
      toast?.error(err.message)
    })
    isSubmittingRef.value = false
  }
}
</script>

<style lang="scss">
@import './new-session-content.scss';
</style>
