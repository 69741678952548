<template>
  <div
    v-if="props.modal.isOpen"
    ref="modalElRef"
    :class="`modal-wrapper ${modal.isSidebar ? 'modal-wrapper--sidebar' : ''}`"
    :id="`${props.modal.name}-modal`"
    :key="props.modal.name"
  >
    <div class="modal-backdrop" @click="!props.noExitOnBackdrop && onExitHandler()"></div>
    <div class="modal-container">
      <div class="number-steps-container" v-if="props.steps.length > 1">
        <div
          class="modal-number-step"
          :class="{ active: index <= activeStepComponentRef.index }"
          v-for="(step, index) in props.steps"
          :key="index"
        ></div>
      </div>
      <component :is="activeStepComponentRef.step?.component" :on-exit-handler="onExitHandler" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type { VNodeRef } from 'vue/types/vnode'

import type { ModalStoreType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import { selectGetCurrentRoute } from '@/store/route/route.selector'
import routeStore from '@/store/route/route.store'
import type { ComponentFactoryType } from '@/types'

const currentRouteRef = selectGetCurrentRoute(routeStore)

const modalElRef = ref<VNodeRef | undefined>()

const activeStepRef = computed(() => props.modal.activeStep)
const activeStepComponentRef = computed(() => {
  const activeStep = props.steps.find((step) => step.id === activeStepRef.value) ?? props.steps[0]
  const index = props.steps.findIndex((step) => step.id === activeStepRef.value)
  return { step: activeStep, index }
})

// function to handle all the callbacks when exiting the modal
function onExitCallback(callback?: () => void) {
  modalsStore.toggleModal(props.modal.name, false)
  callback?.()
  props.onExit?.()
}

// force close modal when route changes
watch(currentRouteRef, () => {
  if (props.modal.isOpen) {
    onExitCallback()
  }
})

// close modal animation + callback when exiting
function onExitHandler(callback?: () => void) {
  const modalEl = modalElRef.value! as unknown as HTMLDivElement
  modalEl.classList.add('modal-wrapper--transitioning-out')
  setTimeout(() => {
    modalEl.classList.remove('modal-wrapper--transitioning-out')
    onExitCallback(callback)
  }, 240) // call the callback after the transition ends (-10ms for safety)
}

const props = defineProps<{
  modal: ModalStoreType
  onExit?: () => void
  noExitOnBackdrop?: boolean
  steps: { component: ComponentFactoryType; id: ModalStoreType['activeStep'] }[]
}>()
</script>

<style lang="scss">
@import './modal.scss';
</style>
