<template>
  <div
    class="programs-dropdown"
    @click="onClick"
    v-if="
      checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.ADMIN) &&
      selectedPeriodIdRef &&
      selectedProgramRef &&
      selectedProgramRef.periods.length > 1
    "
  >
    <ClockIcon color="primary" filled />
    <p class="label">{{ getCondensedTimeRange(periodRef?.startAt, periodRef?.endAt) }}</p>
    <DropdownIcon color="primary" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/features/auth/types'
import useProgram from '@/features/programs/hooks/use-program.hook'
import {
  selectGetSelectedPeriodId,
  selectGetSelectedProgramId,
} from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import ClockIcon from '@/features/ui/components/icons/clock-icon.vue'
import DropdownIcon from '@/features/ui/components/icons/dropdown-icon.vue'
import popoversStore from '@/features/ui/store/popover/popover.store'
import checkPermissionsGroup from '@/features/users/utils'
import { getCondensedTimeRange } from '@/utils/dates'

const { accountRef } = useAccount()

const selectedPeriodIdRef = selectGetSelectedPeriodId(programsStore)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)
const periodRef = computed(() =>
  selectedProgramRef.value?.periods.find((period) => period.id === selectedPeriodIdRef.value),
)

const onClick = (event: MouseEvent) => {
  popoversStore.togglePopover('periods', true, event)
}
</script>

<style lang="scss">
@import './periods-dropdown.scss';
</style>
