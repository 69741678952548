import { computed } from 'vue'

import type {
  AddToCalendarModalType,
  AdminGroupStructureModalType,
  AdminManualUserImportModalType,
  AdminUsersImportModalType,
  ConfirmActionModalType,
  EditSessionModalType,
  GroupSettingsModalType,
  MeetingModalType,
  MenteeMatchingModalType,
  MentorMatchingModalType,
  ModalsStoreValuesType,
  NameMissingModalType,
  NewMatchingModalType,
  NewSessionModalType,
  NoProgramModalType,
  OnboardingModalType,
  PasswordModalType,
  PhoneModalType,
  ProfileInformationModalType,
  ProgramCriteriasModalType,
  ReviewSessionModalType,
  SessionDetailsModalType,
  SupportModalType,
  TermsModalType,
  UserProfileModalType,
} from '@/features/ui/store/modal/modal.store'

export function selectGetAllModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals as ModalsStoreValuesType['modals'])
}

export function selectGetShouldHideMatchingModals(state: ModalsStoreValuesType) {
  return computed(() => state.shouldHideMatchingModals)
}

export function selectGetOnboardingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['onboarding'] as OnboardingModalType)
}

export function selectGetPhoneModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['phone'] as PhoneModalType)
}

export function selectGetSupportModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['support'] as SupportModalType)
}

export function selectGetMentorMatchingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['mentor-matching'] as MentorMatchingModalType)
}

export function selectGetMenteeMatchingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['mentee-matching'] as MenteeMatchingModalType)
}

export function selectGetNoProgramModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['no-program'] as NoProgramModalType)
}

export function selectGetConfirmActionModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['confirm-action'] as ConfirmActionModalType)
}

export function selectGetNewSessionModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['new-session'] as NewSessionModalType)
}

export function selectGetMeetingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['meeting'] as MeetingModalType)
}

export function selectGetUserProfileModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['user-profile'] as UserProfileModalType)
}

export function selectGetPasswordModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['change-password'] as PasswordModalType)
}

export function selectGetProfileInformationModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['profile-information'] as ProfileInformationModalType)
}

export function selectGetReviewSessionModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['review-session'] as ReviewSessionModalType)
}

export function selectGetSessionDetailsModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['session-details'] as SessionDetailsModalType)
}

export function selectGetAdminUsersImportModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['admin-users-import'] as AdminUsersImportModalType)
}

export function selectGetAdminManualUserImportModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['admin-manual-user-import'] as AdminManualUserImportModalType)
}

export function selectGetNewMatchingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['new-matching'] as NewMatchingModalType)
}

export function selectGetAddToCalendarModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['add-to-calendar'] as AddToCalendarModalType)
}

export function selectGetTermsModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['terms'] as TermsModalType)
}

export function selectGetAdminGroupStructureModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['admin-group-structure'] as AdminGroupStructureModalType)
}

export function selectGetProgramCriteriasModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['program-criterias'] as ProgramCriteriasModalType)
}

export function selectGetGroupSettingsModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['group-settings'] as GroupSettingsModalType)
}

export function selectGetEditSessionModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['edit-session'] as EditSessionModalType)
}

export function selectGetNameMissingModal(state: ModalsStoreValuesType) {
  return computed(() => state.modals['name-missing'] as NameMissingModalType)
}
