import type {
  GetGroupDTOType,
  GetGroupsDTOType,
  GetGroupsNotificationsDTOType,
  GetGroupsNotificationsResponseType,
  GetGroupsResponseType,
  UpdateGroupDTOType,
  V1GroupDataType,
} from '@/features/groups/types'
import fetchClient from '@/lib/fetch'

export const GROUPS_QUERY_KEY = 'groups'
export function getGroups(data: GetGroupsDTOType): Promise<GetGroupsResponseType> {
  let url = `/v2/groups?programId=${data.programId}&page=${data.page}&perPage=${data.perPage}&filterEmail=${data.search}`
  if (data.nbMentees !== 'any') {
    url += `&filterNbMentees=${data.nbMentees}`
  }
  return fetchClient(url, {
    method: 'GET',
  })
}

export const GROUP_QUERY_KEY = 'group'
export function getGroup(data: GetGroupDTOType): Promise<V1GroupDataType> {
  return fetchClient(`/v2/groups/${data.groupId}`, {
    method: 'GET',
  })
}

export function updateGroupMutation(data: UpdateGroupDTOType): Promise<V1GroupDataType> {
  return fetchClient(`/v2/groups/${data.id}`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export const V1_MENTORSHIP_GROUPS_NOTIFICATIONS_QUERY_KEY = 'v1-groups-notifications'
export async function getGroupsNotifications(
  data?: GetGroupsNotificationsDTOType,
): Promise<GetGroupsNotificationsResponseType> {
  if (data?.mentorshipGroupId) {
    return fetchClient(`/v2/groups/notifications?mentorshipGroupId=${data.mentorshipGroupId}`, {
      method: 'GET',
    })
  }

  // get all the notifications from all groups
  return fetchClient(`/v2/groups/notifications`, {
    method: 'GET',
  })
}
