<template>
  <Modal :modal="confirmActionModal" :steps="[{ component: ConfirmActionContent, id: 'confirm-action' }]" />
</template>

<script setup lang="ts">
import ConfirmActionContent from '@/features/ui/components/confirm-action-modal/confirm-action-content/confirm-action-content.vue'
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetConfirmActionModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const confirmActionModal = selectGetConfirmActionModal(modalsStore)
</script>
