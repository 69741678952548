<template>
  <Modal :no-exit-on-backdrop="true" :modal="termsModalRef" :steps="[{ component: TermsModalContent, id: 'terms' }]" />
</template>

<script lang="ts" setup>
import { watch } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import TermsModalContent from '@/features/legal/components/terms-modal/terms-modal-content/terms-modal-content.vue'
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetTermsModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useWhiteLabel from '@/hooks/use-white-label.hook'

const termsModalRef = selectGetTermsModal(modalsStore)

const { accountRef, isPendingRef } = useAccount()
const { whiteLabelRef, isPendingRef: isWhiteLabelPendingRef } = useWhiteLabel()

watch(
  [accountRef, whiteLabelRef, isPendingRef, isWhiteLabelPendingRef],
  () => {
    const hasAcceptedTerms = accountRef.value?.hasAcceptedTerms

    // if the user has not accepted the terms, show the terms modal
    if (
      !hasAcceptedTerms &&
      whiteLabelRef.value.termsUrl &&
      isPendingRef.value === false &&
      isWhiteLabelPendingRef.value === false
    ) {
      modalsStore.toggleModal('terms', true)
    } else {
      modalsStore.toggleModal('terms', false)
    }
  },
  { immediate: true },
)
</script>
