import type {
  V1GetAdminDashboardAnalyticsDTOType,
  V1GetAdminDashboardAnalyticsResponseType,
} from '@/features/admin/analytics/types'
import fetchClient from '@/lib/fetch'

export const ADMIN_ANALYTICS_QUERY_KEY = 'ADMIN_ANALYTICS_QUERY_KEY'

export async function V1GetAdminDashboardAnalytics(
  data: V1GetAdminDashboardAnalyticsDTOType,
): Promise<V1GetAdminDashboardAnalyticsResponseType> {
  return fetchClient(`/v1/data/statistics?programId=${data.programId}&periodId=${data.periodId}`, {
    method: 'GET',
  })
}
