<template>
  <ModalStep
    id="users-import-modal-content"
    :header="{
      title: t('admin.users.excelImportModal.title'),
    }"
  >
    <template #headerAfterElement>
      <ProgramReminder :program-id="selectedProgramIdRef" />
    </template>
    <template #bodyContent>
      <LoadingContent v-if="isImportLoadingRef" :text="t('admin.users.excelImportModal.importation.inProgress')" />

      <div v-else-if="isImportValidRef" class="file-uploading-container file-uploading-container--valid">
        <div class="file-uploading-container__information">
          <FileIcon color="primary" filled />
          <div class="deliverable-information">
            <p class="deliverable-information__name">{{ fileRef?.name }}</p>
            <p class="deliverable-information__sub-info">{{ t('admin.users.excelImportModal.file.valid') }}</p>
          </div>
        </div>

        <div class="file-uploading-container__status">
          <div class="deliverable-remove-btn" @click="removeFile">
            <CancelIcon color="danger" />
          </div>
        </div>
      </div>
      <template v-else>
        <div class="c-info-wrapper">
          <p class="c-info-label">
            <strong>{{ t('admin.users.excelImportModal.instructions.title') }}</strong>
          </p>
          <p class="c-info-text">
            {{ t('admin.users.excelImportModal.instructions.body.part1') }}
            <a
              href="https://constel-education.notion.site/Structuration-du-fichier-Excel-pour-l-import-d-utilisateurs-df86bc966470475ca59710436752d391?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ t('admin.users.excelImportModal.instructions.body.part2') }}
            </a>
            {{ t('admin.users.excelImportModal.instructions.body.part3') }}
          </p>
        </div>
        <FileUpload :callback="onFileChange" :accept="`.xlsx`">
          <template #instructions>
            <span>{{ t('ui.input.file.instructions.format') }} .xlsx</span>
          </template>
        </FileUpload>
      </template>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" type="submit" color="white" :text="t('ui.button.cancel')" @emit:click="onClose" />
        <CustomButton
          :isDisabled="!isImportValidRef"
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.next')"
          @emit:click="onNext"
          icon-position="right"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { isValidPhoneNumber } from 'libphonenumber-js'
import { ref } from 'vue'
import { read, utils } from 'xlsx'
import { z } from 'zod'

import type { ImportUsersDTOType } from '@/features/admin/users/types'
import { ImportUserKeysEnumType } from '@/features/admin/users/types'
import ProgramReminder from '@/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import FileUpload from '@/features/ui/components/file-upload/file-upload.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import CancelIcon from '@/features/ui/components/icons/cancel-icon.vue'
import FileIcon from '@/features/ui/components/icons/file-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import type { AdminUsersImportModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()
const toast = useToast()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

// the file that the user wants to upload
const fileRef = ref<File | null>(null)

function onFileChange(file: File) {
  fileRef.value = file
  importXLSX()
}

// remove the file
function removeFile() {
  fileRef.value = null
  isImportValidRef.value = false
}

const isImportValidRef = ref<boolean>(false)
const isImportLoadingRef = ref<boolean>(false)

async function importXLSX() {
  if (!fileRef.value) return
  const file = fileRef.value
  const reader = new FileReader()

  reader.onload = async (e) => {
    isImportLoadingRef.value = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    const data = new Uint8Array(e.target.result)
    const workbook = read(data, { type: 'array' })

    // Assuming that your data is in the first sheet.
    const firstSheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[firstSheetName]

    // Convert Excel sheet to JSON.
    const jsonData = utils.sheet_to_json(worksheet)

    // assign to importedUsersRef
    const usersToImport = jsonData as ImportUsersDTOType['users']
    const errors: string[] = []

    for (let user of usersToImport) {
      if (!user.programId) {
        user.programId = selectedProgramIdRef.value!
      }

      if (user.email) {
        user.email = user.email.toString()
        user.email = user.email.trim()
        user.email = user.email.toLowerCase()

        // verify if email is valid
        try {
          z.string().email().parse(user.email)
        } catch (error: any) {
          errors.push(
            t('admin.users.excelImportModal.error.invalidEmailFor', {
              firstName: user.firstname,
              lastName: user.lastname,
            }),
          )
        }
      }

      if (user.phone) {
        user.phone = user.phone.toString()
        user.phone = user.phone.split('.').join('')
        user.phone = user.phone.split(' ').join('')
        if (user.phone.startsWith('0')) {
          user.phone = user.phone.substring(1, user.phone.length)
          user.phone = '+33' + user.phone
        }

        // verify if phone number is valid
        if (!isValidPhoneNumber(user.phone)) {
          errors.push(
            t('admin.users.excelImportModal.error.invalidPhoneFor', {
              firstName: user.firstname,
              lastName: user.lastname,
            }),
          )
        }
      }

      // set the sso
      if (!user.sso) {
        user.sso = false
      } else {
        user.sso = user.sso.toString()
        user.sso = user.sso.trim()
        user.sso = user.sso.toLowerCase()
        user.sso = Boolean(user.sso === 'true')
      }

      // mapping the keys with required condition
      const keys = [
        {
          id: ImportUserKeysEnumType.email,
          required: true,
        },
        {
          id: ImportUserKeysEnumType.firstName,
          required: true,
        },
        {
          id: ImportUserKeysEnumType.lastName,
          required: true,
        },
        {
          id: ImportUserKeysEnumType.role,
          required: true,
        },
        {
          id: ImportUserKeysEnumType.matchingCriteria,
          required: false,
        },
        {
          id: ImportUserKeysEnumType.phone,
          required: false,
        },
        {
          id: ImportUserKeysEnumType.programId,
          required: false,
        },
        {
          id: ImportUserKeysEnumType.sso,
          required: true,
        },
      ] satisfies { id: ImportUserKeysEnumType; required: boolean }[]

      // checking if all keys are present inside the code
      const isAllKeysExist = Object.values(ImportUserKeysEnumType).every((key) => keys.some((k) => k.id === key))

      // otherwise, it means that there are missing keys inside the code
      if (!isAllKeysExist) {
        toast?.error(t('error.common'))
        isImportValidRef.value = false
        isImportLoadingRef.value = false
        return
      }

      // checking if all keys are present for the user
      const mandatoryKeys = keys.filter((k) => k.required).map((k) => k.id)
      const doesMandatoryKeysExist = mandatoryKeys.map((k) => ({
        key: k,
        value: user[k],
        exist: typeof user[k] !== 'undefined',
      }))

      const missingKeys = doesMandatoryKeysExist.filter((k) => !k.exist)

      if (missingKeys.length > 0) {
        toast?.error(
          `${t('admin.users.excelImportModal.error.missingColumns')} "${missingKeys.map((k) => k.key).join(', ')}" (${user.firstname} ${user.lastname})`,
        )
        isImportValidRef.value = false
        isImportLoadingRef.value = false
        return
      }

      // remove unwanted keys
      for (const key in user) {
        const validKeys = keys.map((k) => k.id) as string[]
        if (!validKeys.includes(key)) {
          delete user[key as keyof typeof user]
        }
      }
    }

    // do not proceed if there are errors
    if (errors?.length > 0) {
      for (const error of errors) {
        toast?.error(error)
      }

      isImportValidRef.value = false
      isImportLoadingRef.value = false
      return
    }

    // import is valid, mark as valid and stop loading
    isImportValidRef.value = true
    isImportLoadingRef.value = false
    toast?.success(t('admin.users.excelImportModal.validStructure'))

    modalsStore.updateModalAttachedData<AdminUsersImportModalType>('admin-users-import', {
      users: usersToImport,
    })
  }

  // Read file contents as an array buffer.
  reader.readAsArrayBuffer(file)
}

function onNext() {
  modalsStore.setModalStep<AdminUsersImportModalType>('admin-users-import', 'users-import-confirmation')
}

function onClose() {
  modalsStore.toggleModal('admin-users-import', false)
}
</script>

<style lang="scss">
@import './users-import-modal-content.scss';
</style>
