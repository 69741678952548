<template>
  <GridBlockBase id="planned-sessions" :title="t('dashboard.shared.plannedSessions.title')">
    <template #icon>
      <CalendarIcon color="black" filled />
    </template>
    <template #body>
      <div class="dashboard-planned-sessions-list">
        <!-- we check for the mentorshipgroups ref as well because it is on the dashboard (we don't want to show the loading state if there is no group yet) -->
        <SessionsList
          :sessions="plannedSessionsRef"
          :isPending="isPendingRef && groupsRef.length > 0"
          :emptyText="t('dashboard.shared.plannedSessions.noSession')"
        />
      </div>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useGroups from '@/features/groups/hooks/use-groups.hook'
import SessionsList from '@/features/sessions/components/sessions-list/sessions-list.vue'
import useSessions from '@/features/sessions/hooks/use-sessions.hook'
import { getPlannedSessions } from '@/features/sessions/utils'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import CalendarIcon from '@/features/ui/components/icons/calendar-icon.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()
const { groupsRef } = useGroups()
const { sessionsRef, isPendingRef } = useSessions()
const plannedSessionsRef = computed(() => sessionsRef.value.filter(getPlannedSessions))
</script>

<style lang="scss">
@import './dashboard-planned-sessions.scss';
</style>
