<template>
  <Modal
    :modal="profileInformationModalRef"
    :steps="[
      { component: EditProfileInformationContent, id: 'description' },
      { component: EditProfilePictureContent, id: 'profile-picture' },
    ]"
  />
</template>

<script lang="ts" setup>
import EditProfileInformationContent from '@/features/auth/components/profile-information-modal/edit-profile-information-content/edit-profile-information-content.vue'
import EditProfilePictureContent from '@/features/auth/components/profile-information-modal/edit-profile-picture-content/edit-profile-picture-content.vue'
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetProfileInformationModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const profileInformationModalRef = selectGetProfileInformationModal(modalsStore)
</script>
