<template>
  <div
    :class="`conversation-item ${routeParamsRef === conversation.conversationId ? 'conversation-item--active' : ''}`"
    @click="goToConversation"
  >
    <div class="conversation-item__info">
      <template v-if="isChatGroupRef">
        <div class="conversation-group-avatar">
          <GroupIcon color="white" filled />
        </div>
        <p>{{ groupRef?.name }}</p>
      </template>
      <template v-else>
        <Avatar :user="correspondentRef" />
        <p>{{ correspondentRef?.firstName }} {{ correspondentRef?.lastName }}</p>
      </template>
      <span v-if="hasNotificationRef" class="c-notification-badge"></span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import useGroupConversationsWithoutFirstMessage from '@/features/chat/hooks/use-group-conversations-without-first-message.hook'
import type { V2ConversationType } from '@/features/chat/types'
import useGroup from '@/features/groups/hooks/use-group.hook'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import GroupIcon from '@/features/ui/components/icons/group-icon.vue'
import Avatar from '@/features/users/components/avatar/avatar.vue'
import useRouter from '@/hooks/use-router.hook'
import { selectGetCurrentRoute } from '@/store/route/route.selector'
import routeStore from '@/store/route/route.store'
import { ROUTES } from '@/utils/config/constants'

const { accountRef } = useAccount()
const router = useRouter()

const currentRouteRef = selectGetCurrentRoute(routeStore)

const routeParamsRef = computed(() => currentRouteRef.value?.params.roomId)

const correspondentRef = computed(() => props.conversation.users.find((user) => user.id !== accountRef.value?.id))
const isChatGroupRef = computed(() => props.conversation.users.length > 2)
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const { groupRef } = useGroup(selectedGroupIdRef)

// group has notifications
const { groupConversationsWithoutFirstMessageSentRef } = useGroupConversationsWithoutFirstMessage()
const hasNotificationRef = computed(() => {
  return (
    // check if the conversation is in the list of group conversations without first message sent
    groupConversationsWithoutFirstMessageSentRef.value.some(
      (c) => c.conversationId === props.conversation.conversationId,
    ) ||
    // check if the user has not seen the conversation
    !props.conversation.users.find((user) => user.id === accountRef.value?.id)?.hasSeen
  )
})

// go to a specific conversation
function goToConversation() {
  router?.push(ROUTES.CHAT(props.conversation.conversationId)).catch(() => {})
}

const props = defineProps<{
  conversation: V2ConversationType
}>()
</script>

<style lang="scss">
@import './conversation-item.scss';
</style>
