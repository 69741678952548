import { USERS_QUERY_KEY } from '@/features/admin/users/api'
import { V1_AUTH_USER_QUERY_KEY, V1_PHONE_QUERY_KEY, V1_USER_DATA_QUERY_KEY } from '@/features/auth/api'
import { V1_CONVERSATIONS_QUERY_KEY } from '@/features/chat/api'
import { GROUP_QUERY_KEY, GROUPS_QUERY_KEY, V1_MENTORSHIP_GROUPS_NOTIFICATIONS_QUERY_KEY } from '@/features/groups/api'
import { V1_PROGRAMS_QUERY_KEY } from '@/features/programs/api'
import {
  DELIVERABLE_QUERY_KEY,
  DELIVERABLES_QUERY_KEY,
  SESSION_QUERY_KEY,
  V1_SESSIONS_QUERY_KEY,
} from '@/features/sessions/api'
import { V1_USER_QUERY_KEY } from '@/features/users/api'
import queryClient from '@/lib/vue-query'

export async function invalidateNotifications() {
  await queryClient.invalidateQueries({ queryKey: [V1_MENTORSHIP_GROUPS_NOTIFICATIONS_QUERY_KEY] })
}

// invalidate all sessions by mentorship group id (don't forget about notifications)
export async function invalidateSessions({ groupId }: { groupId?: string }) {
  await queryClient.invalidateQueries({
    queryKey: [V1_SESSIONS_QUERY_KEY, { groupId }],
  })
  await queryClient.invalidateQueries({ queryKey: [SESSION_QUERY_KEY] })
  await invalidateNotifications()
}

// invalidate session by id
export async function invalidateSession(sessionId: string) {
  await queryClient.invalidateQueries({ queryKey: [SESSION_QUERY_KEY, { id: sessionId }], refetchType: 'all' })
  await invalidateNotifications()
}

// invalidate deliverable by user id
export async function invalidateDeliverable({ userId, sessionId }: { userId: string; sessionId: string }) {
  await queryClient.invalidateQueries({ queryKey: [DELIVERABLE_QUERY_KEY, { userId, sessionId }] })
}

// invalidate deliverables
export async function invalidateDeliverables({ sessionId }: { sessionId?: string }) {
  await queryClient.invalidateQueries({ queryKey: [DELIVERABLES_QUERY_KEY, { sessionId }] })
}

// invalidate profile information
export async function invalidateAccount() {
  await queryClient.invalidateQueries({ queryKey: [V1_USER_DATA_QUERY_KEY] })
  await queryClient.invalidateQueries({ queryKey: [V1_AUTH_USER_QUERY_KEY] })
}

// invalidate user profile
export async function invalidateUser(userId: string) {
  await queryClient.invalidateQueries({ queryKey: [V1_USER_QUERY_KEY, { userId }], refetchType: 'all' })
}

// invalidate phone
export async function invalidatePhone() {
  await queryClient.invalidateQueries({ queryKey: [V1_PHONE_QUERY_KEY] })
}

// invalidate all conversations by mentorship group id (don't forget about notifications)
export async function invalidateConversations(groupId: string) {
  await queryClient.invalidateQueries({ queryKey: [V1_CONVERSATIONS_QUERY_KEY, { groupId }] })
  await invalidateNotifications()
}

// invalidate groups by program id + all individual groups
export async function invalidateGroups(programId: string) {
  await queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEY, { programId }] })
  await queryClient.invalidateQueries({ queryKey: [GROUP_QUERY_KEY] })
}

// invalidate group by group id
export async function invalidateGroup(groupId: string) {
  await queryClient.invalidateQueries({ queryKey: [GROUP_QUERY_KEY, { groupId }] })
}

// invalidate all admin users by program id
export async function invalidateAdminUsers(programId: string) {
  await queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY, { programId }], refetchType: 'all' })
  await queryClient.invalidateQueries({ queryKey: [V1_USER_QUERY_KEY] })
}

// reset all (fresh start)
export async function resetMainQueriesCache(programId: string) {
  await queryClient.resetQueries({ queryKey: [V1_PROGRAMS_QUERY_KEY] })
  await queryClient.resetQueries({ queryKey: [V1_SESSIONS_QUERY_KEY] })
  await queryClient.resetQueries({ queryKey: [V1_CONVERSATIONS_QUERY_KEY] })
  await invalidateGroups(programId)
  await invalidateNotifications()
}
