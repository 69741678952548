<template>
  <GridBlockBase
    id="program-progress"
    :title="t('dashboard.shared.programProgress.title')"
    :subtitle="getFullTimeRange(selectedProgramRef?.latestPeriod?.startAt, selectedProgramRef?.latestPeriod?.endAt)"
  >
    <template #icon>
      <ProgramIcon color="black" />
    </template>
    <template #body>
      <LoadingContent v-if="isPendingRef" />
      <StatAndIcon
        v-else
        :disabled="completedSessionsRef.length === 0"
        color="success"
        :stat="completedSessionsRef.length"
        :total="sessionsGoalRef"
        :text="t('dashboard.shared.programProgress.doneSessions')"
      >
        <template #icon>
          <DonutProgressIcon
            v-if="completedSessionsRef.length < sessionsGoalRef"
            color="primary"
            :progress="(completedSessionsRef.length / Math.max(1, sessionsGoalRef)) * 100"
          />
          <CheckIcon v-else color="primary" />
        </template>
      </StatAndIcon>
      <StatAndIcon
        :disabled="pendingActionsRef === 0"
        color="warning"
        :stat="pendingActionsRef"
        :text="t('dashboard.shared.programProgress.pendingActions')"
      >
        <template #icon>
          <WaitingIcon color="black" />
        </template>
      </StatAndIcon>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import StatAndIcon from '@/features/dashboards/components/stat-and-icon/stat-and-icon.vue'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import useSessions from '@/features/sessions/hooks/use-sessions.hook'
import { getCompletedSessions } from '@/features/sessions/utils'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import CheckIcon from '@/features/ui/components/icons/check-icon.vue'
import DonutProgressIcon from '@/features/ui/components/icons/donut-progress-icon.vue'
import ProgramIcon from '@/features/ui/components/icons/program-icon.vue'
import WaitingIcon from '@/features/ui/components/icons/waiting-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import useNotifications from '@/hooks/use-notifications.hook'
import { useI18n } from '@/lib/i18n'
import { getFullTimeRange } from '@/utils/dates'

const { t } = useI18n()

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { programRef: selectedProgramRef, isPendingRef } = useProgram(selectedProgramIdRef)

const sessionsGoalRef = computed(() => selectedProgramRef.value?.latestPeriod?.sessionsGoal ?? 0)

const { sessionsRef } = useSessions()
const completedSessionsRef = computed(() => sessionsRef.value.filter(getCompletedSessions))

const { pendingActionsRef } = useNotifications()
</script>
