import { computed } from 'vue'

import type {
  MoreActionsStoreType,
  MoreActionsStoreValuesType,
} from '@/features/ui/components/more-actions-menu/store/more-actions.store'

export function selectGetMoreActions(
  state: MoreActionsStoreValuesType,
  name: MoreActionsStoreType['name'] | undefined,
) {
  return computed(() => (name ? state.moreActions[name] : undefined))
}
