<template>
  <div class="program-reminder">
    <ProgramIcon color="black" />
    <span>{{ selectedProgramRef?.name }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useProgram from '@/features/programs/hooks/use-program.hook'
import ProgramIcon from '@/features/ui/components/icons/program-icon.vue'

const props = defineProps<{
  programId: string | null
}>()

const programIdRef = computed(() => props.programId)
const { programRef: selectedProgramRef } = useProgram(programIdRef)
</script>

<style lang="scss">
@import './program-reminder.scss';
</style>
