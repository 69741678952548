<template>
  <SearchBar
    ref="searchBarRef"
    :placeholder="t('admin.users.listing.searchPlaceholder')"
    :value="searchRef"
    @emit:change="onSearchChange"
    @emit:clear="onClearSearch"
  >
    <template v-if="searchRef?.length" #searchBarResults>
      <UsersSearchResults
        :filter-by-role="filterByRole"
        :remove-from-results="removeFromResults"
        :search="searchRef"
        :search-bar-ref="searchBarRef"
        :callback="setUserMailToFilter"
      />
    </template>
  </SearchBar>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { onMounted } from 'vue'

import type { RoleEnumType } from '@/features/auth/types'
import SearchBar from '@/features/ui/components/search-bar/search-bar.vue'
import UsersSearchResults from '@/features/ui/pages/admin/users/users-search/users-search-results/users-search-results.vue'
import type { V2UserAdminDataType } from '@/features/users/types'
import { useI18n } from '@/lib/i18n'

const searchBarRef = ref<any | null>(null)
const searchRef = ref<string | null>(null)

const props = defineProps<{
  searchCallback: () => void
  onMountFocus?: boolean
  filterByRole?: RoleEnumType
  removeFromResults?: V2UserAdminDataType[]
}>()

const { t } = useI18n()

const emit = defineEmits(['emit:result'])

onMounted(() => {
  if (props.onMountFocus) {
    searchBarRef.value.$el.querySelector('input')?.focus()
  }
})

const onClearSearch = () => {
  emit('emit:result', null)
}

const onSearchChange = (value: string) => {
  props.searchCallback()
  searchRef.value = value
  if (value === '') {
    emit('emit:result', null)
  }
}

const setUserMailToFilter = (user: V2UserAdminDataType) => {
  emit('emit:result', user)
  searchRef.value = null
}
</script>
