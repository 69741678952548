import type { StoreApi } from 'zustand'

import type {
  MoreActionsStoreType,
  MoreActionsStoreValuesType,
} from '@/features/ui/components/more-actions-menu/store/more-actions.store'
import { INITIAL_MORE_ACTIONS_STORE_VALUES } from '@/features/ui/components/more-actions-menu/store/more-actions.store'

export function setAttachedDataAction(
  get: StoreApi<MoreActionsStoreValuesType>['getState'],
  set: StoreApi<MoreActionsStoreValuesType>['setState'],
  name: MoreActionsStoreType['name'],
  attachedData: MoreActionsStoreType['attachedData'],
) {
  const moreActions = get().moreActions
  const moreAction = moreActions[name]
  if (moreAction) {
    moreActions[name] = { ...moreAction, attachedData } as any
    set({ moreActions })
  }
}

export function resetMoreActionsStoreAction(set: StoreApi<MoreActionsStoreValuesType>['setState']) {
  set(INITIAL_MORE_ACTIONS_STORE_VALUES)
}
