<template>
  <Modal
    id="admin-users-import"
    :modal="adminUsersImportModalRef"
    :steps="[
      { id: 'users-import', component: UsersImportModalContent },
      { id: 'users-import-confirmation', component: usersImportConfirmationModalContent },
    ]"
  />
</template>

<script setup lang="ts">
import Modal from '@/features/ui/components/modal/modal.vue'
import usersImportConfirmationModalContent from '@/features/ui/pages/admin/users/users-import-modal/users-import-confirmation-modal-content/users-import-confirmation-modal-content.vue'
import UsersImportModalContent from '@/features/ui/pages/admin/users/users-import-modal/users-import-modal-content/users-import-modal-content.vue'
import { selectGetAdminUsersImportModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const adminUsersImportModalRef = selectGetAdminUsersImportModal(modalsStore)
</script>
