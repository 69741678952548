import Vue, { ref } from 'vue'
import VueRouter from 'vue-router'

import ForgotPassword from '@/(routes)/(auth)/forgot-password/page.vue'
import Login from '@/(routes)/(auth)/login/page.vue'
import EmailLogin from '@/(routes)/(auth)/login-with-email/page.vue'
import AdminDashboard from '@/(routes)/(protected)/(app)/admin/dashboard/page.vue'
import DownloadDelivrable from '@/(routes)/(protected)/(app)/admin/download-deliverable/page.vue'
import AdminGroups from '@/(routes)/(protected)/(app)/admin/groups/page.vue'
import AdminSessions from '@/(routes)/(protected)/(app)/admin/sessions/page.vue'
import AdminUsers from '@/(routes)/(protected)/(app)/admin/users/page.vue'
import Chat from '@/(routes)/(protected)/(app)/chat/page.vue'
import Dashboard from '@/(routes)/(protected)/(app)/dashboard/page.vue'
import IndexProfile from '@/(routes)/(protected)/(app)/profile/page.vue'
import Sessions from '@/(routes)/(protected)/(app)/sessions/page.vue'
import authStore from '@/features/auth/stores/auth/auth.store'
import { RoleEnumType } from '@/features/auth/types'
import dayjsClient from '@/lib/dayjs'
import { loadLocaleMessages, switchI18nLanguage } from '@/lib/i18n'
import { PosthogClient } from '@/lib/posthog'
import { i18nInstance } from '@/main'
import routeStore from '@/store/route/route.store'
import { I18N_LOCAL_STORAGE_KEY, ROUTES } from '@/utils/config/constants'

Vue.use(VueRouter)

const routes = [
  {
    path: ROUTES.LOGIN,
    name: 'Login',
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: ROUTES.lOGIN_WITH_EMAIL,
    name: 'EmailLogin',
    component: EmailLogin,
    meta: {
      public: true,
    },
  },
  {
    path: ROUTES.FORGOT_PASSWORD(':token?'),
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      public: true,
    },
  },
  {
    path: ROUTES.CHAT(':roomId?'),
    name: 'Chat',
    component: Chat,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.DASHBOARD,
    name: 'IndexMentoringGroup',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.PROFILE,
    name: 'NewIndexProfile',
    component: IndexProfile,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.SESSIONS,
    name: 'SessionIndex',
    component: Sessions,
    meta: {
      requiresAuth: true,
      rejectGuest: true,
      rejectAdmin: true,
    },
  },
  {
    path: ROUTES.LOGIN_WITH_SAML,
    name: 'SAML',
    beforeEnter() {
      if (import.meta.env.VITE_ENV === 'development') {
        location.href = 'http://localhost:8086'
      } else {
        location.href = 'https://sp.constel-education.com/auth'
      }
    },
  },
  {
    path: ROUTES.ADMIN.SESSIONS,
    name: 'AdminSessions',
    component: AdminSessions,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.GROUPS,
    name: 'AdminGroups',
    component: AdminGroups,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.DASHBOARD,
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.USERS,
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
  {
    path: ROUTES.ADMIN.DOWNLOAD_DELIVERABLE(':sessionId', ':userId', ':deliverableId'),
    name: 'DownloadDelivrable',
    component: DownloadDelivrable,
    meta: {
      requiresAuth: true,
      rejectMentor: true,
      rejectMentee: true,
      rejectGuest: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export const currentRouteRef = ref(router.currentRoute)

router.beforeEach(async (to, from, next) => {
  routeStore.setCurrentRoute(to)

  // get local from local storage
  let paramsLocal = window.localStorage.getItem(I18N_LOCAL_STORAGE_KEY)
  if (!paramsLocal) {
    paramsLocal = navigator.language.split('-')[0]
  }

  // load the messages
  await loadLocaleMessages(i18nInstance, paramsLocal)

  // if the locale is different from the current locale, set the new locale
  if (paramsLocal !== i18nInstance.locale) {
    switchI18nLanguage(i18nInstance, paramsLocal)
    dayjsClient.locale(paramsLocal)
  }

  if (to.matched.some((record) => record.meta.rejectGuest)) {
    if (authStore.userRole === RoleEnumType.GUEST) {
      return next({ name: 'confirmAccountInformations' })
    }
  }

  if (to.matched.some((record) => record.meta.rejectMentor)) {
    if (authStore.userRole === RoleEnumType.MENTOR)
      return next({
        name: 'IndexMentoringGroup',
      })
  }
  if (to.matched.some((record) => record.meta.rejectMentee)) {
    if (authStore.userRole === RoleEnumType.MENTEE)
      return next({
        name: 'IndexMentoringGroup',
      })
  }
  if (to.matched.some((record) => record.meta.rejectAdmin)) {
    if (authStore.userRole === RoleEnumType.ADMIN) return next({ name: 'AdminDashboard' })
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if (!store.getters.isAuthenticated && store.getters.authSaml)
    //   return next({ name: 'SAML' })
    if (!authStore.authToken || !authStore.userRole) {
      const loginpath = window.location.pathname // enregistre la page de provenance
      next({ name: 'Login', query: { from: loginpath } })
    }
  }
  if (to.matched.some((record) => record.meta.public)) {
    // if (!store.getters.isAuthenticated && store.getters.authSaml)
    //   next({ name: 'SAML' })
    if (authStore.authToken && authStore.userRole) {
      if (authStore.userRole === RoleEnumType.MENTOR || authStore.userRole === RoleEnumType.MENTEE)
        return next({
          name: 'IndexMentoringGroup',
        })
      else if (authStore.userRole === RoleEnumType.ADMIN || RoleEnumType.MANAGER)
        return next({ name: 'AdminDashboard' })
      else if (authStore.userRole === RoleEnumType.GUEST) return next({ name: 'confirmAccountInformations' })
    }
  }
  // 404
  if (!to.matched.length) {
    if (authStore.authToken && authStore.userRole) {
      if (authStore.userRole === RoleEnumType.MENTOR || authStore.userRole === RoleEnumType.MENTEE)
        return next({
          name: 'IndexMentoringGroup',
        })
      else if (authStore.userRole === RoleEnumType.ADMIN || authStore.userRole === RoleEnumType.MANAGER)
        return next({ name: 'AdminDashboard' })
      else if (authStore.userRole === RoleEnumType.GUEST) return next({ name: 'confirmAccountInformations' })
    } else {
      return next({ name: 'Login' })
    }
  }

  next()
})

// send pageview to posthog
router.afterEach(() => {
  PosthogClient.capture('$pageview')
})

export default router
