<template>
  <ModalStep
    id="deliverables-rating-modal-content"
    :header="{
      title: t('session.deliverablesRatingModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label" style="text-align: center">
          <strong>{{ t('session.deliverablesRatingModal.body') }}</strong>
        </p>
      </div>
      <div class="users-listing" v-if="menteesWithDeliverablesRatesRef.length > 0">
        <Dropdown
          :is-loaded="true"
          v-for="mentee in menteesWithDeliverablesRatesRef"
          :key="mentee.id"
          class-name="user-card"
        >
          <template #header>
            <div class="user-card__header">
              <UserInfo :user="mentee" :sub-info="mentee.role === RoleEnumType.MENTEE ? 'Mentoré' : 'Mentor'" />
              <StatusBadge
                :status="
                  mentee.deliverableRate !== null
                    ? DeliverablesNotationPossibleStatusType.RATED
                    : DeliverablesNotationPossibleStatusType.NOT_RATED
                "
                type="notation"
              />
            </div>
          </template>
          <template #default>
            <div class="rating-body">
              <div class="rating-body__label">
                {{ t('session.deliverablesRatingModal.whatNoteDoYouGive', { name: mentee.firstName }) }}
              </div>
              <div class="rating-body__deliverable-rating">
                <div class="deliverable-container">
                  <LoadingContent v-if="isDeliverablesPendingRef" />
                  <div
                    v-else-if="returnDeliverableByUserId({ menteeId: mentee.id, deliverables: deliverablesRef })?.file"
                    class="deliverable-wrapper"
                  >
                    <div class="deliverable-info-wrapper">
                      <FileIcon color="primary" filled />
                      <div class="deliverable-info">
                        <p class="deliverable-info__name">
                          {{ returnDeliverableByUserId({ menteeId: mentee.id, deliverables: deliverablesRef })?.file }}
                        </p>
                        <p class="deliverable-info__sub-info">
                          {{
                            t('session.deliverablesRatingModal.sentAt', {
                              date: getDate(
                                returnDeliverableByUserId({ menteeId: mentee.id, deliverables: deliverablesRef })
                                  ?.updatedAt,
                              ),
                            })
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="deliverable-dl-button">
                      <CustomButton
                        usage="button"
                        color="primary"
                        :text="t('ui.button.download')"
                        @emit:click="
                          downloadDeliverable({
                            menteeId: mentee.id,
                            deliverableId: returnDeliverableByUserId({
                              menteeId: mentee.id,
                              deliverables: deliverablesRef,
                            })?.id,
                            sessionId: selectedSessionInReviewIdRef,
                            toast,
                            t,
                          })
                        "
                      >
                        <template #icon>
                          <DownloadIcon color="white" />
                        </template>
                      </CustomButton>
                    </div>
                  </div>
                  <div v-else class="deliverable-empty">
                    <p>{{ t('session.deliverable.noDeliverable') }}</p>
                  </div>
                </div>
                <div class="rating-container">
                  <div class="rating-container__input">
                    <CustomInput
                      type="number"
                      min="0"
                      max="20"
                      :id="`rating-${mentee.id}`"
                      placeholder="00"
                      :value="mentee.deliverableRate ? mentee.deliverableRate.toString() : null"
                      @emit:change="(event) => registerDeliverableRate(mentee.id, event)"
                    />
                  </div>
                  <p class="rating-container__total">/20</p>
                </div>
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <p v-else style="text-align: center" v-html="t('session.deliverablesRatingModal.noMentees')"></p>
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="onCloseAndFillLater()"
        />
        <CustomButton
          :is-loading="isAddDeliverablesRatesPendingRef"
          :is-disabled="menteesWithDeliverablesRatesRef.some((mentee) => mentee.deliverableRate === null)"
          usage="button"
          type="submit"
          color="primary"
          :text="t('ui.button.continue')"
          icon-position="right"
          @emit:click="onFinish"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'

import { RoleEnumType } from '@/features/auth/types'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { getMentees } from '@/features/groups/utils'
import { V1AddDeliverableRateMutation } from '@/features/sessions/api'
import useDeliverables from '@/features/sessions/hooks/use-deliverables.hook'
import { useSessionInReview } from '@/features/sessions/hooks/use-in-review-session.hook'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { DeliverablesNotationPossibleStatusType } from '@/features/sessions/types'
import { downloadDeliverable, returnDeliverableByUserId } from '@/features/sessions/utils'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import Dropdown from '@/features/ui/components/dropdown/dropdown.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import DownloadIcon from '@/features/ui/components/icons/download-icon.vue'
import FileIcon from '@/features/ui/components/icons/file-icon.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import StatusBadge from '@/features/ui/components/status-badge/status-badge.vue'
import { selectGetReviewSessionModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserInfo from '@/features/users/components/user-info/user-info.vue'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { getDate } from '@/utils/dates'
import { invalidateSession, invalidateSessions } from '@/utils/lib/vue-query'

const toast = useToast()
const { t } = useI18n()

const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)
const selectedSessionInReviewIdRef = computed(() => reviewSessionModalRef.value.attachedData.sessionId ?? null)
const { sessionRef } = useSession(selectedSessionInReviewIdRef)
const menteesRef = computed(() => sessionRef.value?.users.filter(getMentees) ?? [])
const isAddDeliverablesRatesPendingRef = ref(false)

const { onClickNextStep, onCloseAndFillLater } = useSessionInReview()

// fetch all deliverables
const { deliverablesRef, isPendingRef: isDeliverablesPendingRef } = useDeliverables(selectedSessionInReviewIdRef)

// mentees array with deliverableRate property
let menteesWithDeliverablesRatesRef = ref<((typeof menteesRef.value)[number] & { deliverableRate: number | null })[]>(
  [],
)

// assign deliverableRate property to mentees array
watch(
  menteesRef,
  (newMenteesValue) => {
    menteesWithDeliverablesRatesRef.value = newMenteesValue
      // even if mentee wasn't present, we still want to display the deliverable rate
      // .filter((mentee) => {
      //   return (
      //     sessionRef.value?.presences.find((presence) => presence.userId === mentee.id && presence.wasPresent) ?? false
      //   )
      // })
      .map((mentee) => ({
        ...mentee,
        deliverableRate:
          // we return the rating if the deliverable has been rated, otherwise null as empty input value
          returnDeliverableByUserId({ menteeId: mentee.id, deliverables: deliverablesRef.value })?.rating ?? null,
      }))
  },
  { immediate: true },
)

// register the deliverable rate of a mentee
function registerDeliverableRate(menteeId: string, newValue: string) {
  const rating = parseFloat(parseFloat(newValue).toFixed(1)) ?? null
  menteesWithDeliverablesRatesRef.value = menteesWithDeliverablesRatesRef.value.map((mentee) => {
    // if the mentee is the one we are looking for, we update its deliverable rate
    if (mentee.id === menteeId) {
      return {
        ...mentee,
        deliverableRate: rating,
      }
    }
    return mentee
  })
}

// add deliverable rate mutation
const { mutateAsync: addDeliverableRate } = useMutation({
  mutationFn: V1AddDeliverableRateMutation,
  onError: (error) => {
    console.error(error)
    toast?.error(error.message)
  },
})

async function onFinish() {
  try {
    isAddDeliverablesRatesPendingRef.value = true

    // first check if all rates are valid (between 0 and 20)
    if (
      menteesWithDeliverablesRatesRef.value.some((mentee) => typeof mentee.deliverableRate !== 'number') ||
      menteesWithDeliverablesRatesRef.value.some(
        (mentee) => mentee.deliverableRate! < 0 || mentee.deliverableRate! > 20,
      )
    ) {
      toast?.error(t('session.deliverablesRatingModal.invalidRate'))
      return
    }

    // we await for all the deliverable rates to be added
    const deliverablesRatePromises = menteesWithDeliverablesRatesRef.value
      // we filter the mentees that have a deliverable rate
      .filter((mentee) => typeof mentee.deliverableRate === 'number')
      // we crate a promise for each deliverable rate
      .map((mentee) =>
        addDeliverableRate({
          sessionId: selectedSessionInReviewIdRef.value!,
          userId: mentee.id,
          rate: mentee.deliverableRate!,
        }),
      )

    await Promise.all(deliverablesRatePromises)

    // after every deliverable has been rated, we invalidate the session and go to the next step
    await invalidateSessions({ groupId: selectedGroupIdRef.value! })
    await invalidateSession(reviewSessionModalRef.value.attachedData.sessionId!)
    toast?.success(t('session.deliverablesRatingModal.success'))

    onClickNextStep()
  } catch (error) {
    console.error(error)
    toast?.error(t('error.common'))
  } finally {
    isAddDeliverablesRatesPendingRef.value = false
  }
}
</script>

<style lang="scss">
@import './deliverables-rating-modal-content.scss';
</style>
