<template>
  <div
    :title="`${t('chat.conversation.seenBy')} ${usersWhoSawTheConversationRef.map((user) => user.firstName).join(', ')}`"
    :class="`conversation-seen-by ${usersWhoSawTheConversationRef.length === 0 ? 'conversation-seen-by--empty' : ''}`"
  >
    <UsersBadgesStack :users="usersWhoSawTheConversationRef" noStatus />
    <SeenIcon color="primary" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import type { V2ConversationType } from '@/features/chat/types'
import UsersBadgesStack from '@/features/sessions/components/users-badges-stack/users-badges-stack.vue'
import SeenIcon from '@/features/ui/components/icons/seen-icon.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const props = defineProps<{
  conversation: V2ConversationType
}>()

const { accountRef } = useAccount()

// filter users who saw the conversation (remove own account)
const usersWhoSawTheConversationRef = computed(() =>
  props.conversation.users.filter((u) => u.id !== accountRef.value?.id).filter((user) => user.hasSeen),
)
</script>

<style lang="scss">
@import './conversation-seen-by.scss';
</style>
