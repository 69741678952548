<template>
  <Modal id="new-session" :modal="newSessionModalRef" :steps="[{ component: NewSessionContent, id: 'new-session' }]" />
</template>

<script setup lang="ts">
import NewSessionContent from '@/features/sessions/components/new-session-modal/new-session-content/new-session-content.vue'
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetNewSessionModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const newSessionModalRef = selectGetNewSessionModal(modalsStore)
</script>
