import type { StoreApi } from 'zustand'

import { type AuthStoreValuesType } from './auth.store'

import type { RoleEnumType } from '@/features/auth/types'

export async function setAuthTokenAction(
  get: StoreApi<AuthStoreValuesType>['getState'],
  set: StoreApi<AuthStoreValuesType>['setState'],
  authToken: string | null,
) {
  if (!authToken) {
    set({
      authToken: null,
      userRole: null,
    })
  } else {
    set({
      authToken,
    })
  }
}

export async function setUserRoleAction(
  get: StoreApi<AuthStoreValuesType>['getState'],
  set: StoreApi<AuthStoreValuesType>['setState'],
  userRole: RoleEnumType,
) {
  set({ userRole })
}
