<template>
  <Modal
    :no-exit-on-backdrop="true"
    :modal="missingNameModalRef"
    :steps="[{ component: NameModalContent, id: 'missing-name' }]"
  />
</template>

<script lang="ts" setup>
import { watchEffect } from 'vue'

import NameModalContent from '@/features/auth/components/name-modal/name-modal-contenet/name-modal-content.vue'
import useAccount from '@/features/auth/hooks/use-account.hook'
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetNameMissingModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const missingNameModalRef = selectGetNameMissingModal(modalsStore)

const { accountRef } = useAccount()

watchEffect(() => {
  // if the user has not been asked for his phone number or if he has not declined this popup, show the phone modal
  if (accountRef.value && (!accountRef.value?.firstName || !accountRef.value?.lastName)) {
    modalsStore.toggleModal('name-missing', true)
  } else if (accountRef.value && accountRef.value?.firstName && accountRef.value?.lastName) {
    modalsStore.toggleModal('name-missing', false)
  }
})
</script>
