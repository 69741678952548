<template>
  <Modal :modal="userProfileModal" :steps="[{ component: UserProfileModalContent, id: 'user-profile' }]" />
</template>

<script setup lang="ts">
import Modal from '@/features/ui/components/modal/modal.vue'
import { selectGetUserProfileModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserProfileModalContent from '@/features/users/components/user-profile-modal/user-profile-modal-content/user-profile-modal-content.vue'

const userProfileModal = selectGetUserProfileModal(modalsStore)
</script>
