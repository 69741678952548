import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import useIsAuth from '@/features/auth/hooks/use-is-auth.hook'
import { getConversations, V1_CONVERSATIONS_QUERY_KEY } from '@/features/chat/api'
import type { V1ConversationType, V2ConversationType } from '@/features/chat/types'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'

function V1ToV2DataMiddleware({ data }: { data: V1ConversationType[] | undefined }): V2ConversationType[] {
  const conversations = data?.map((conversation) => {
    return {
      conversationId: conversation.conversationId,
      messages: conversation.messages.map((message) => ({
        ...message,
        sentAt: message.sendAt,
      })),
      users: conversation.users?.map((user) => ({
        id: user.id,
        role: user.role,
        firstName: user.firstname,
        lastName: user.lastname,
        picture: user.profilePicture,
        hasSeen: conversation.views.includes(user.id),
      })),
    }
  })

  return conversations || []
}

function useConversations() {
  const { isAuthRef } = useIsAuth()

  const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

  const isGetConversationsEnabledRef = computed(() => !!(isAuthRef.value && selectedGroupIdRef.value))

  const { data, isPending } = useQuery({
    queryKey: [V1_CONVERSATIONS_QUERY_KEY, { groupId: selectedGroupIdRef }],
    queryFn: () => getConversations({ mentorshipGroupId: selectedGroupIdRef.value! }),
    enabled: isGetConversationsEnabledRef,
  })

  const conversationsRef = computed(() => V1ToV2DataMiddleware({ data: data.value }))

  return {
    conversationsRef,
    isPendingRef: isPending,
  }
}

export default useConversations
