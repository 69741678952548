import dayjsClient from '@/lib/dayjs'

export function getCondensedTimeRange(startAt: string | undefined, endAt: string | undefined) {
  if (!startAt || !endAt) return '... → ...'

  const startAtFormattedDate =
    dayjsClient(startAt).format('MMM YYYY').slice(0, 1).toUpperCase() + dayjsClient(startAt).format('MMM YYYY').slice(1)
  const endAtFormattedDate =
    dayjsClient(endAt).format('MMM YYYY').slice(0, 1).toUpperCase() + dayjsClient(endAt).format('MMM YYYY').slice(1)

  return `${startAtFormattedDate} → ${endAtFormattedDate}`
}

export function getFullTimeRange(startAt: string | undefined, endAt: string | undefined) {
  if (!startAt || !endAt) return '... → ...'

  const startAtFormattedDate =
    dayjsClient(startAt).format('D MMMM YYYY').slice(0, 1).toUpperCase() +
    dayjsClient(startAt).format('D MMMM YYYY').slice(1)
  const endAtFormattedDate =
    dayjsClient(endAt).format('D MMMM YYYY').slice(0, 1).toUpperCase() +
    dayjsClient(endAt).format('D MMMM YYYY').slice(1)

  return `${startAtFormattedDate} → ${endAtFormattedDate}`
}

export function getDurationRange(startAt: string | undefined, endAt: string | undefined) {
  if (!startAt || !endAt) return '...'

  const startAtFormattedDate = dayjsClient(startAt).format('HH[h]mm')
  const endAtFormattedDate = dayjsClient(endAt).format('HH[h]mm')

  return `${startAtFormattedDate} → ${endAtFormattedDate}`
}

export function getRelativeTimeToDate(date: string | undefined) {
  if (!date) return '...'

  // Get current time and target date as dayjs objects
  const now = dayjsClient()
  const targetDate = dayjsClient(date)

  // Check if the target date is valid
  if (!targetDate.isValid()) return 'Invalid date'

  // Calculate the difference between the target date and now in milliseconds
  const diffInMilliseconds = targetDate.diff(now)

  // Use duration to get a human-readable countdown (e.g., "3 days", "1 hour")
  const countdown = dayjsClient.duration(diffInMilliseconds).humanize(true)

  return countdown
}

export function getDate(date: string | undefined) {
  if (!date) return '...'
  return dayjsClient(date).format('D MMMM YYYY')
}

export function extractShortDay(date: string | undefined) {
  if (!date) return '...'
  return dayjsClient(date).format('ddd')
}

export function extractDay(date: string | undefined) {
  if (!date) return '...'
  return dayjsClient(date).format('DD')
}

export function extractDayNumber(date: string | undefined) {
  if (!date) return '...'
  return dayjsClient(date).format('D').padStart(2, '0')
}
