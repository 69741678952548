import { computed } from 'vue'

import type { ProgramsStoreValuesType } from '@/features/programs/stores/programs/programs.store'

export function selectGetSelectedProgramId(state: ProgramsStoreValuesType) {
  return computed(() => state.selectedProgramId)
}
export function selectGetSelectedPeriodId(state: ProgramsStoreValuesType) {
  return computed(() => state.selectedPeriodId)
}
