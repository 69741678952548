import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { persist } from 'zustand/middleware'

import {
  resetProgramStoreAction,
  setSelectedPeriodIdAction,
  setSelectedProgramIdAction,
} from '@/features/programs/stores/programs/programs.actions'
import subscribeToStore from '@/lib/zustand'

export type ProgramsStoreValuesType = {
  selectedProgramId: string | null
  selectedPeriodId: string | null
}

export const INITIAL_PROGRAMS_STORE_VALUES: ProgramsStoreValuesType = {
  selectedProgramId: null,
  selectedPeriodId: null,
}

export const PROGRAMS_STORAGE_KEY = 'programs'

const programsStore = subscribeToStore(
  create(
    persist(
      combine(INITIAL_PROGRAMS_STORE_VALUES, (set, get) => ({
        setSelectedProgramId: (programId: string) => setSelectedProgramIdAction(get, set, programId),
        setSelectedPeriodId: (periodId: string) => setSelectedPeriodIdAction(get, set, periodId),
        reset: () => resetProgramStoreAction(set),
      })),
      {
        name: PROGRAMS_STORAGE_KEY, // unique name to store the data under in local storage
      },
    ),
  ),
)
export default programsStore
