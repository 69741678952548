import groupsStore from '@/features/groups/stores/groups/groups.store'
import programsStore from '@/features/programs/stores/programs/programs.store'
import { PosthogClient } from '@/lib/posthog'
import queryClient from '@/lib/vue-query'
import { ANALYTICS_DISABLED_KEY, ROUTES } from '@/utils/config/constants'

export default async function logout() {
  console.log('logout')
  programsStore.reset()
  groupsStore.reset()
  await queryClient.cancelQueries()
  await queryClient.clear()
  const hasAnalyticsDisabled = window.localStorage.getItem(ANALYTICS_DISABLED_KEY)

  window.localStorage.clear()

  // if analytics were disabled, keep it disabled even when logging out
  if (hasAnalyticsDisabled) {
    window.localStorage.setItem(ANALYTICS_DISABLED_KEY, 'true')
  }

  PosthogClient.reset() // https://posthog.com/docs/product-analytics/identify#3-reset-after-logout
  window.location.href = ROUTES.LOGIN
}
