<template>
  <Modal
    id="admin-users-import"
    :modal="adminManualUserImportModalRef"
    :steps="[{ id: 'manual-user-import', component: ManualUserImportModalContent }]"
  />
</template>

<script setup lang="ts">
import Modal from '@/features/ui/components/modal/modal.vue'
import ManualUserImportModalContent from '@/features/ui/pages/admin/users/manual-user-import-modal/manual-user-import-modal-content/manual-user-import-modal-content.vue'
import { selectGetAdminManualUserImportModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'

const adminManualUserImportModalRef = selectGetAdminManualUserImportModal(modalsStore)
</script>
