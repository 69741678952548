<template>
  <LoadingContent v-if="isPending" />
  <div v-else-if="users.length > 0" class="users-table-wrapper">
    <div class="users-table">
      <div class="users-table__header">
        <span>{{ t('admin.users.table.header.user') }}</span>
        <span>{{ t('admin.users.table.header.role') }}</span>
        <span>{{ t('admin.users.table.header.group') }}</span>
        <span>{{ t('admin.users.table.header.actions') }}</span>
      </div>
      <div class="users-table__content">
        <UserRow v-for="user in users" :key="user.id" :user="user" />
      </div>
    </div>
  </div>
  <EmptyContent v-else :text="t('admin.users.table.empty')">
    <template #icon>
      <UserIcon color="black" filled />
    </template>
  </EmptyContent>
</template>

<script setup lang="ts">
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import UserIcon from '@/features/ui/components/icons/user-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import UserRow from '@/features/ui/pages/admin/users/users-content/users-table/user-row/user-row.vue'
import type { V2UserDataType } from '@/features/users/types'
import { useI18n } from '@/lib/i18n'

defineProps<{
  isPending: boolean
  users: V2UserDataType[]
}>()

const { t } = useI18n()
</script>

<style lang="scss">
@import './users-table.scss';
</style>
