<template>
  <div class="programs-dropdown" @click="onClick">
    <ProgramIcon color="primary" />
    <p class="label">{{ selectedProgramRef?.name }}</p>
    <DropdownIcon color="primary" />
  </div>
</template>

<script setup lang="ts">
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import DropdownIcon from '@/features/ui/components/icons/dropdown-icon.vue'
import ProgramIcon from '@/features/ui/components/icons/program-icon.vue'
import popoversStore from '@/features/ui/store/popover/popover.store'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

function onClick(e: MouseEvent) {
  popoversStore.togglePopover('programs', true, e)
}
</script>

<style lang="scss">
@import './programs-dropdown.scss';
</style>
