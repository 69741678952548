<template>
  <ModalStep
    id="new-matching-fill-criteria"
    :header="{
      title: t('matching.newMatchingModal.fillCriteria.title'),
    }"
  >
    <template #headerBeforeElement>
      <ProgramReminder :programId="selectedProgramIdRef" />
    </template>

    <template #bodyInformation>
      <p v-html="t('matching.newMatchingModal.fillCriteria.bodyInformation')"></p>
    </template>
    <template #bodyContent>
      <MatchingInformationContainer />
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton usage="button" color="white" :text="t('ui.button.back')" @emit:click="goBack" />
        <CustomButton
          :isLoading="isSubmittingRef"
          usage="button"
          color="primary"
          :text="t('matching.newMatchingModal.fillCriteria.button.lookForMentor')"
          @emit:click="onSubmit"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'

import { menteeMatchSuggestMentorMutation } from '@/features/matching/api'
import MatchingInformationContainer from '@/features/matching/components/matching-modals/matching-modal-content/matching-information-container/matching-information-container.vue'
import useMatchingInformation from '@/features/matching/hooks/use-matching-information.hook'
import ProgramReminder from '@/features/programs/components/program-reminder/program-reminder.vue'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import type { NewMatchingModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()
const toast = useToast()

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const { isSubmittingRef, onSubmit } = useMatchingInformation({
  onSubmitCallback,
})

function goBack() {
  modalsStore.toggleModal('new-matching', false)
}

const { mutate: menteeMatchSuggestMentor } = useMutation({
  mutationFn: menteeMatchSuggestMentorMutation,
  onError: (error) => {
    toast?.error(t('error.common'))

    // if an error occurs, we go back to the previous step
    modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-fill-criteria')
  },
  onSuccess: (data) => {
    if (data.message === 'No mentor available') {
      toast?.error(t('matching.fillingCriterias.noMentorAvailable'))
      modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-fill-criteria')
    } else {
      // we set the matched mentor id in the modal attached data and go to next step
      modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-confirmation')

      console.log(data.mentors)
      modalsStore.updateModalAttachedData<NewMatchingModalType>('new-matching', {
        mentors: data.mentors,
      })
    }
  },
  onSettled: () => {
    isSubmittingRef.value = false
  },
  onMutate: () => {
    isSubmittingRef.value = true
    //  we show the loading screen (the matching will be done in the background)
    modalsStore.setModalStep<NewMatchingModalType>('new-matching', 'new-matching-loading')
  },
})

function onSubmitCallback(filledCriteria: string) {
  menteeMatchSuggestMentor({
    criteria: filledCriteria,
    programId: selectedProgramIdRef.value!,
  })
}
</script>

<style lang="scss">
@import './new-matching-fill-criteria-modal-content.scss';
</style>
