<template>
  <div :class="`user-card ${noActions ? 'no-actions' : ''}`" @click="onClick">
    <UserInfo :user="user" :sub-info="shownSubInfoRef" />
    <StatusBadge v-if="status" :status="status" type="user" />
    <div v-if="slots.actions && !noActions" class="user-card__actions">
      <slot name="actions"></slot>
    </div>
    <div
      v-if="!noActions"
      class="user-card__more-options"
      data-action="user-card"
      :data-attached="JSON.stringify(user)"
    >
      <MoreOptionsIcon color="black" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useSlots } from 'vue'

import type { RoleEnumType } from '@/features/auth/types'
import type { SessionStatusEnumType } from '@/features/sessions/types'
import MoreOptionsIcon from '@/features/ui/components/icons/more-options-icon.vue'
import StatusBadge from '@/features/ui/components/status-badge/status-badge.vue'
import type { UserProfileModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import UserInfo from '@/features/users/components/user-info/user-info.vue'
import type { V2UserDataType } from '@/features/users/types'
import { userRolesNamesMap } from '@/features/users/utils'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()
const slots = useSlots()

const props = defineProps<{
  user: V2UserDataType
  status?: SessionStatusEnumType
  subInfo?: string
  noActions?: boolean
  overrideOnClick?: boolean
}>()

const shownSubInfoRef = computed(() =>
  props.subInfo ? props.subInfo : `${t(userRolesNamesMap[props.user.role as RoleEnumType])}`,
)

const emit = defineEmits(['emit:click'])

function onClick(e: Event) {
  // guard to not open the modal when clicking on the more options icon
  if ((e.target as HTMLElement).closest('[data-action="user-card"]') || props.noActions) {
    return
  }

  if (props.overrideOnClick) {
    emit('emit:click', props.user)
  } else {
    openUserProfile()
  }
}

function openUserProfile() {
  modalsStore.toggleModal('user-profile', true)
  modalsStore.updateModalAttachedData<UserProfileModalType>('user-profile', { userId: props.user.id })
}
</script>

<style lang="scss">
@import './user-card.scss';
</style>
