import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

import { getWhiteLabelData, WHITE_LABEL_QUERY_KEY } from '@/features/ui/api/global-data'
import type { V1WhiteLabelDataType, V2WhiteLabelDataType } from '@/types/global-data'

function V1ToV2DataMiddleware(data?: V1WhiteLabelDataType): Partial<V2WhiteLabelDataType> {
  return {
    logo: data?.setting.find((item) => item.name === 'LOGO')?.value,
    logoSmall: data?.setting.find((item) => item.name === 'LOGO_SMALL')?.value,
    favIcon: data?.setting.find((item) => item.name === 'FAVICON')?.value,
    colorPrimary: data?.setting.find((item) => item.name === 'COLOR_PRIMARY')?.value,
    colorSecondary: data?.setting.find((item) => item.name === 'COLOR_SECONDARY')?.value,
    customStyle: data?.setting.find((item) => item.name === 'CUSTOM_STYLE')?.value,
    companyTitle: data?.setting.find((item) => item.name === 'TITLE')?.value,
    authSaml: data?.setting.find((item) => item.name === 'AUTH_SAML')?.value,
    customStyles: data?.setting.find((item) => item.name === 'CUSTOM_STYLE')?.value,
    supportPhone: data?.setting.find((item) => item.name === 'SUPPORT_PHONE')?.value,
    supportEmail: data?.setting.find((item) => item.name === 'SUPPORT_EMAIL')?.value,
    termsUrl: data?.setting.find((item) => item.name === 'TERMS_URL')?.value,
    emailLoginEnabled: data?.setting.find((item) => item.name === 'EMAIL_LOGIN_ENABLED')?.value,
    samlLoginEnabled: data?.setting.find((item) => item.name === 'SAML_LOGIN_ENABLED')?.value,
  }
}

const useWhiteLabel = () => {
  const queryResult = useQuery({
    queryKey: [WHITE_LABEL_QUERY_KEY],
    queryFn: getWhiteLabelData,
  })

  const whiteLabelRef = computed(() => V1ToV2DataMiddleware(queryResult.data.value))

  return {
    whiteLabelRef,
    isPendingRef: queryResult.isPending,
    isErrorRef: queryResult.isError,
  }
}

export default useWhiteLabel
