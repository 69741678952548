<template>
  <div class="main-nav">
    <div class="collapse-button" @click="menuStore.toggleDesktopNavCollapsed">
      <CollapseIcon color="black" :collapsed="menuStore.isDesktopNavCollapsed" />
    </div>
    <div class="logo-wrapper">
      <div class="logo">
        <img :src="whiteLabelRef.logo" alt="logo" />
      </div>
      <div class="logo small-format">
        <img :src="whiteLabelRef.logoSmall || whiteLabelRef.logo" alt="logo symbol" />
      </div>
    </div>
    <nav>
      <ul>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.ADMIN)">
          <router-link :to="ROUTES.ADMIN.DASHBOARD" :class="isAdminDashboardActive ? 'active' : ''">
            <DashboardIcon :color="'black'" :filled="isAdminDashboardActive" />
            <span>{{ t('ui.layout.nav.dashboard') }}</span>
          </router-link>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.ADMIN)">
          <router-link :to="ROUTES.ADMIN.GROUPS" :class="isAdminGroupsActive ? 'active' : ''">
            <GroupIcon :color="'black'" :filled="isAdminGroupsActive" />
            <span>{{ t('ui.layout.nav.groups') }}</span>
          </router-link>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.ADMIN)">
          <router-link :to="ROUTES.ADMIN.USERS" :class="isAdminUsersActive ? 'active' : ''">
            <UserIcon :color="'black'" :filled="isAdminUsersActive" />
            <span>{{ t('ui.layout.nav.users') }}</span>
          </router-link>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.ADMIN)">
          <router-link :to="ROUTES.ADMIN.SESSIONS" :class="isAdminSessionsActive ? 'active' : ''">
            <CalendarIcon :color="'black'" :filled="isAdminSessionsActive" />
            <span>{{ t('ui.layout.nav.sessions') }}</span>
          </router-link>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER)">
          <router-link :to="ROUTES.DASHBOARD" :class="isDashboardActive ? 'active' : ''">
            <DashboardIcon :color="'black'" :filled="isDashboardActive" />
            <span>{{ t('ui.layout.nav.dashboard') }}</span>
          </router-link>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER)">
          <router-link :to="ROUTES.SESSIONS" :class="isSessionsActive ? 'active' : ''">
            <CalendarIcon :color="'black'" :filled="isSessionsActive" />
            <span>{{ t('ui.layout.nav.sessions') }}</span>
            <NotificationsBadge :numberOfNotifications="groupNotificationsRef?.sessions" />
          </router-link>
        </li>
        <li
          @click="openNewSessionModal"
          class="main-nav-mobile-only"
          v-if="accountRef?.role === RoleEnumType.MENTOR && selectedGroupIdRef"
        >
          <a role="button">
            <AddCalendarIcon :color="'black'" :filled="false" />
            <span>{{ t('ui.layout.nav.newSession') }}</span>
          </a>
        </li>
        <li @click="closeMobileMenu" v-if="checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER)">
          <router-link :to="ROUTES.CHAT()" :class="isMessagesActive ? 'active' : ''">
            <MessageIcon :color="'black'" :filled="isMessagesActive" />
            <span>{{ t('ui.layout.nav.messages') }}</span>
            <NotificationsBadge :numberOfNotifications="groupNotificationsRef?.messages" />
          </router-link>
        </li>
        <li
          @click="closeMobileMenu"
          v-if="
            checkPermissionsGroup(accountRef?.role, PermissionsGroupsEnumType.USER) && programRef?.settings.driveUrl
          "
        >
          <a :href="programRef.settings.driveUrl" target="_blank" rel="noopener">
            <FolderIcon :color="'black'" :filled="false" />
            <span>{{ t('ui.layout.nav.sharedDrive') }}</span>
            <div class="external-link-badge">
              <ArrowIcon :color="'black'" />
            </div>
          </a>
        </li>
        <li @click="closeMobileMenu">
          <a role="button" href="#brevoConversationsExpanded">
            <SupportIcon :color="'black'" :filled="false" />
            <span>{{ t('ui.layout.nav.support') }}</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="main-nav__bottom-container">
      <MainActionWidget />
    </div>
  </div>
</template>

<script setup lang="ts">
import useAccount from '@/features/auth/hooks/use-account.hook'
import { PermissionsGroupsEnumType } from '@/features/auth/types'
import { RoleEnumType } from '@/features/auth/types'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import AddCalendarIcon from '@/features/ui/components/icons/add-calendar-icon.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import CalendarIcon from '@/features/ui/components/icons/calendar-icon.vue'
import CollapseIcon from '@/features/ui/components/icons/collapse-icon.vue'
import DashboardIcon from '@/features/ui/components/icons/dashboard-icon.vue'
import FolderIcon from '@/features/ui/components/icons/folder-icon.vue'
import GroupIcon from '@/features/ui/components/icons/group-icon.vue'
import MessageIcon from '@/features/ui/components/icons/message-icon.vue'
import SupportIcon from '@/features/ui/components/icons/support-icon.vue'
import UserIcon from '@/features/ui/components/icons/user-icon.vue'
import MainActionWidget from '@/features/ui/components/layout/main-action-widget/main-action-widget.vue'
import NotificationsBadge from '@/features/ui/components/notifications-badge/notifications-badge.vue'
import menuStore from '@/features/ui/store/menu/menu.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import checkPermissionsGroup from '@/features/users/utils'
import useNotifications from '@/hooks/use-notifications.hook'
import useRouter from '@/hooks/use-router.hook'
import useWhiteLabel from '@/hooks/use-white-label.hook'
import { useI18n } from '@/lib/i18n'
import { ROUTES } from '@/utils/config/constants'

const { t } = useI18n()
const router = useRouter()
const { whiteLabelRef } = useWhiteLabel()
const { accountRef } = useAccount()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(selectedProgramIdRef)

const { groupNotificationsRef } = useNotifications()

const isAdminDashboardActive = router?.currentRoute.path === ROUTES.ADMIN.DASHBOARD
const isAdminGroupsActive = router?.currentRoute.path === ROUTES.ADMIN.GROUPS
const isAdminUsersActive = router?.currentRoute.path === ROUTES.ADMIN.USERS
const isAdminSessionsActive = router?.currentRoute.path === ROUTES.ADMIN.SESSIONS
const isDashboardActive = router?.currentRoute.path === ROUTES.DASHBOARD
const isSessionsActive = router?.currentRoute.path === ROUTES.SESSIONS
const isMessagesActive = router?.currentRoute.path.includes(ROUTES.CHAT())

function openNewSessionModal() {
  modalsStore.toggleModal('new-session', true)
}

function closeMobileMenu() {
  menuStore.closeMobileMenu()
}
</script>

<style lang="scss">
@import './main-nav.scss';
</style>
