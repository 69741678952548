import type { Route } from 'vue-router'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import subscribeToStore from '@/lib/zustand'

export type RouteStoreType = {
  currentRoute: Route | null
}

export const INITIAL_ROUTE_STORE_VALUE: RouteStoreType = {
  currentRoute: null,
}

const routeStore = subscribeToStore(
  create(
    combine(INITIAL_ROUTE_STORE_VALUE, (set) => ({
      setCurrentRoute: (currentRoute: Route) => {
        set({ currentRoute })
      },
    })),
  ),
)

export default routeStore
