<template>
  <ModalStep
    id="confirm-action-content"
    :header="{
      title: confirmActionModalRef.attachedData.title
        ? t(confirmActionModalRef.attachedData.title)
        : t('ui.modal.confirmation.title'),
    }"
  >
    <template #bodyInformation v-if="confirmActionModalRef.attachedData.information">
      <p v-html="t(confirmActionModalRef.attachedData.information)"></p>
    </template>
    <template #bodyContent v-if="confirmActionModalRef.attachedData.content">
      <p v-html="t(confirmActionModalRef.attachedData.content)"></p>
    </template>
    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          :is-loading="isLoadingRef"
          usage="button"
          type="button"
          color="white"
          :text="t('ui.button.cancel')"
          @emit:click="
            () => {
              confirmActionModalRef.attachedData.cancelFn?.()
              modalsStore.toggleModal('confirm-action', false)
            }
          "
        />
        <CustomButton
          usage="button"
          type="submit"
          color="danger"
          :text="t('ui.button.confirm')"
          @emit:click="handleConfirm"
        />
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import CustomButton from '@/features/ui/components/button/custom-button.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetConfirmActionModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'

const confirmActionModalRef = selectGetConfirmActionModal(modalsStore)

const isLoadingRef = ref(false)

const toast = useToast()
const { t } = useI18n()

async function handleConfirm() {
  if (confirmActionModalRef.value.attachedData) {
    isLoadingRef.value = true
    try {
      await confirmActionModalRef.value.attachedData.fn?.()
      modalsStore.toggleModal('confirm-action', false)
    } catch (e) {
      console.error(e)
      toast?.error(t('error.common'))
    } finally {
      isLoadingRef.value = false
    }
  }
}
</script>

<style lang="scss">
@import './confirm-action-content.scss';
</style>
