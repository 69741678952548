<template>
  <div class="popover-wrapper">
    <div ref="popoverRef" :id="`${popover.name}-popover`" class="popover">
      <slot name="content"></slot>
      <!-- <div class="popover__arrow">
        <DropdownIcon color="black" />
      </div> -->
    </div>
    <div class="popover-backdrop"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

import type { PopoverStoreType } from '@/features/ui/store/popover/popover.store'
import popoversStore from '@/features/ui/store/popover/popover.store'
import useWindowSize from '@/hooks/use-window-size.hook'
import { BREAKPOINTS } from '@/utils/config/constants'

const props = defineProps<{
  popover: PopoverStoreType
}>()
const isOpenRef = computed(() => props.popover.isOpen ?? false)

const popoverRef = ref<HTMLDivElement | null>(null)

const { widthRef } = useWindowSize()

const closePopover = () => {
  if (props.popover) {
    popoversStore.togglePopover(props.popover.name, false)
  }
}

watchEffect((onCleanup) => {
  const popover = popoverRef.value
  let dataActionFadeOutTimeout: any
  const openClass = 'popover--open'
  if (!popover) return

  const onClose = () => {
    popover.classList.remove(openClass)
    dataActionFadeOutTimeout = setTimeout(() => {
      // moving th menu out of the screen
      popover.style.left = '-1000px'
      popover.style.top = '-1000px'
      closePopover()
    }, 240) // transition duration (-10ms for safety)
  }

  const handleOnMouseLeave = () => {
    onClose()
  }

  const handleOnMissClick = (e: MouseEvent) => {
    if (!popover.contains(e.target as Node) && isOpenRef.value) {
      popover.classList.remove(openClass)
      closePopover()
    }
  }

  if (isOpenRef.value) {
    props.popover.event?.stopPropagation()
    popover.classList.add(openClass)

    // position the menu at the mouse position
    // be careful with the position, it should not go outside the window
    // on mobile device, the menu should be positioned at the bottom of the screen (CSS)
    // so this part should be run only on desktop
    if (widthRef.value >= BREAKPOINTS.DESKTOP && props.popover.event) {
      const rect = popover.getBoundingClientRect()
      const x = props.popover.event.clientX
      const y = props.popover.event.clientY - 8
      const windowHeight = window.innerHeight
      const safetyMargin = 32

      if (x < rect.width - safetyMargin) {
        popover.style.left = `${x}px`
      } else {
        popover.style.left = `${x - rect.width}px`
      }

      if (y < rect.height) {
        popover.style.top = `${y}px`
      } else if (y > windowHeight - rect.height - safetyMargin) {
        popover.style.top = `${y - rect.height}px`
      } else {
        popover.style.top = `${y}px`
      }
    } else {
      popover.style.left = ''
      popover.style.top = ''
    }
  }

  document.addEventListener('click', handleOnMissClick)
  popover.addEventListener('mouseleave', handleOnMouseLeave)

  onCleanup(() => {
    onClose()
    popover.removeEventListener('mouseleave', handleOnMouseLeave)
    document.removeEventListener('click', handleOnMissClick)
    clearTimeout(dataActionFadeOutTimeout)
  })
})
</script>

<style lang="scss">
@import './popover.scss';
</style>
