<template>
  <ModalStep
    :id="'name-modal-content'"
    :header="{
      title: t('auth.nameModal.title'),
    }"
  >
    <template #bodyInformation>
      <strong>
        {{ t('auth.nameModal.body') }}
      </strong>
    </template>
    <template #bodyContent>
      <form @submit.prevent="submitForm">
        <CustomInput
          id="firstName"
          type="text"
          :label="`${t('ui.input.firstName.label')} *`"
          :placeholder="t('ui.input.firstName.placeholder')"
          @emit:change="firstNameChangeHandler"
          :value="firstNameRef"
          ref="firstNameInputRef"
        />
        <CustomInput
          id="lastName"
          type="text"
          :label="`${t('ui.input.lastName.label')} *`"
          :placeholder="t('ui.input.lastName.placeholder')"
          @emit:change="lastNameChangeHandler"
          :value="lastNameRef"
          ref="lastNameInputRef"
        />
      </form>
    </template>
    <template #footer>
      <div class="c-btns-container c-btn-center">
        <CustomButton
          usage="button"
          type="button"
          color="primary"
          @emit:click="submitForm"
          :isDisabled="!firstNameRef.length || !lastNameRef.length"
          :isLoading="isSubmittingRef"
          :text="t('ui.button.confirm')"
        ></CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ref, watch } from 'vue'

import { updateUserMutation } from '@/features/auth/api'
import useAccount from '@/features/auth/hooks/use-account.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { invalidateAccount } from '@/utils/lib/vue-query'

const toast = useToast()
const { accountRef } = useAccount()
const { t } = useI18n()

const firstNameRef = ref('')
const lastNameRef = ref('')
const firstNameInputRef = ref<any | null>(null)
const lastNameInputRef = ref<any | null>(null)

const { mutate: updateUser, isPending: isSubmittingRef } = useMutation({
  mutationFn: updateUserMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    toast?.success(t('auth.nameModal.success'))
    invalidateAccount()
    modalsStore.toggleModal('name-missing', false)
  },
})

watch(
  accountRef,
  (account) => {
    if (account?.firstName) {
      console.log('account.firstName', account.firstName)
      firstNameRef.value = account.firstName
      firstNameInputRef.value?.set(account.firstName)
    }

    if (account?.lastName) {
      console.log('account.lastName', account.lastName)
      lastNameRef.value = account.lastName
      lastNameInputRef.value?.set(account.lastName)
    }
  },
  { immediate: true },
)

function firstNameChangeHandler(value: string) {
  firstNameRef.value = value
}

function lastNameChangeHandler(value: string) {
  lastNameRef.value = value
}

async function submitForm() {
  await updateUser({
    firstName: firstNameRef.value,
    lastName: lastNameRef.value,
  })
}
</script>

<style lang="scss" scoped>
@import './name-modal-content.scss';
</style>
