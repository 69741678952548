<template>
  <div :class="`input-wrapper ${id} ${hasErrors ? 'has-errors' : ''}`">
    <!-- input label -->
    <label v-if="label" class="input-label" :for="id">
      <span>{{ label }}</span>
    </label>
    <!-- input element -->
    <div class="input-el-wrapper">
      <textarea
        :disabled="isDisabled"
        class="input"
        v-model="valueRef"
        :data-value="valueRef"
        :placeholder="placeholder"
        @input="onChange"
        @keyup="onKeyUp"
        @click="onClick"
      ></textarea>
    </div>
    <div v-if="errors" class="input-errors-wrapper">{{ errors }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import debounce from '@/utils/misc/debounce'

interface InputPropsType {
  id: string
  errors?: string
  label?: string
  placeholder: string
  value?: string | null
  isDisabled?: boolean
  onChangeDebounceDelay?: number
}

const props = defineProps<InputPropsType>()

// set default value v-model
const valueRef = ref(props.value || '')

defineExpose({
  clear() {
    valueRef.value = ''
  },
  set(message: string) {
    valueRef.value = message
  },
})

const emit = defineEmits(['emit:change', 'emit:onkeyup', 'emit:click'])

const onChange = debounce(
  (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    // Emit the new value to the parent with the event name 'update:modelValue'
    emit('emit:change', value)
  },
  props.onChangeDebounceDelay ?? 500,
  false,
)

const onKeyUp = (event: KeyboardEvent) => {
  emit('emit:onkeyup', event)
}

const onClick = (event: MouseEvent) => {
  emit('emit:click', event)
}

// Computed properties for conditional logic
const hasErrors = computed(() => !!props.errors)
</script>

<style scoped lang="scss">
@import './custom-textarea.scss';
</style>
