<template>
  <div class="c-btns-container c-btn-center">
    <CustomButton
      :is-loading="isSessionPendingRef"
      usage="button"
      type="submit"
      color="primary"
      :text="t('session.pendingActions.completeSession')"
      @emit:click="onClickCompletePendingActions"
    >
      <template #icon>
        <EditIcon color="white" />
      </template>
    </CustomButton>
  </div>
</template>

<script setup lang="ts">
import { useSessionInReview } from '@/features/sessions/hooks/use-in-review-session.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import EditIcon from '@/features/ui/components/icons/edit-icon.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

const { isSessionPendingRef, onClickCompletePendingActions } = useSessionInReview()
</script>
