import { type StoreApi } from 'zustand'

import groupsStore from '@/features/groups/stores/groups/groups.store'
import {
  INITIAL_PROGRAMS_STORE_VALUES,
  type ProgramsStoreValuesType,
} from '@/features/programs/stores/programs/programs.store'

export function setSelectedProgramIdAction(
  get: StoreApi<ProgramsStoreValuesType>['getState'],
  set: StoreApi<ProgramsStoreValuesType>['setState'],
  programId: string,
) {
  // we set the selected period to undefined when a new program is selected
  set({ selectedProgramId: programId, selectedPeriodId: null })

  // we also invalidate the mentorship groups selected id
  groupsStore.setSelectedGroupId(null)
}

export function setSelectedPeriodIdAction(
  get: StoreApi<ProgramsStoreValuesType>['getState'],
  set: StoreApi<ProgramsStoreValuesType>['setState'],
  periodId: string,
) {
  set({ selectedPeriodId: periodId })
}

export function resetProgramStoreAction(set: StoreApi<ProgramsStoreValuesType>['setState']) {
  set(INITIAL_PROGRAMS_STORE_VALUES)
}
