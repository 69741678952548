import authStore from '@/features/auth/stores/auth/auth.store'

function authRequestInterceptor(input: RequestInfo, init?: RequestInit, isFormData?: boolean) {
  if (!window) {
    return Promise.reject()
  }
  const authToken = authStore.authToken
  const headers = new Headers(init?.headers)

  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`)
  }

  if (!headers.has('Content-type') && !isFormData) {
    headers.set('Content-type', 'application/json')
  }
  if (!headers.has('Accept')) {
    headers.set('Accept', 'application/json')
  }

  return fetch(input, { ...init, headers })
}

async function fetchClient(input: RequestInfo, init?: RequestInit, isFormData?: boolean): Promise<any> {
  if (!window) {
    return Promise.reject()
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  const response = await authRequestInterceptor(`${import.meta.env.VITE_API_URL}${input}`, init, isFormData)
  const json = await response.json()

  if (!response.ok) {
    throw new Error(json.message)
  }

  return json
}

export default fetchClient
