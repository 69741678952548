<template>
  <Popover :popover="programsPopoverRef">
    <template #content>
      <PopoverRow
        v-for="program in programsRef"
        :key="program.id"
        :is-active="selectedProgramIdRef === program.id"
        @emit:click="() => handleSwitchProgram(program.id)"
      >
        <template #content>
          <CheckFullIcon color="primary" :is-checked="selectedProgramIdRef === program.id" />
          <p>{{ t('common.program.capitalize') }} : {{ program.name }}</p>
        </template>
      </PopoverRow>
    </template>
  </Popover>
</template>

<script setup lang="ts">
import usePrograms from '@/features/programs/hooks/use-programs.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CheckFullIcon from '@/features/ui/components/icons/check-full-icon.vue'
import Popover from '@/features/ui/components/popover/popover.vue'
import PopoverRow from '@/features/ui/components/popover/popover-row/popover-row.vue'
import { selectGetProgramsPopover } from '@/features/ui/store/popover/popover.selectors'
import popoversStore from '@/features/ui/store/popover/popover.store'
import { useI18n } from '@/lib/i18n'

const { programsRef } = usePrograms()
const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

const programsPopoverRef = selectGetProgramsPopover(popoversStore)

const { t } = useI18n()

const handleSwitchProgram = (programId: string) => {
  programsStore.setSelectedProgramId(programId)
  popoversStore.togglePopover('programs', false)
}
</script>

<style lang="scss">
@import './programs-popover.scss';
</style>
