<template>
  <AuthWrapper>
    <template #header>
      <h1 v-html="t('auth.login.title', { appTitle: whiteLabelRef.companyTitle })"></h1>
    </template>
    <template #body>
      <form class="login-with-email-form" @submit.prevent="handleLoginWithPassword">
        <CustomInput
          type="email"
          id="email"
          data-test-id="input-email"
          :label="`${t('ui.input.email.label')} *`"
          :placeholder="t('ui.input.email.placeholder')"
          @emit:change="handleEmailChange"
          :defaultOnChangeDelay="500"
          :value="emailRef"
          :errors="emailErrorsRef"
        />

        <div v-if="isSSOUserRef === false">
          <CustomInput
            type="password"
            id="password"
            data-test-id="input-password"
            :label="`${t('ui.input.password.label')} *`"
            :placeholder="t('ui.input.password.placeholder')"
            :value="passwordRef"
            :errors="passwordErrorsRef"
            @emit:change="handlePasswordChange"
            :defaultOnChangeDelay="10"
          />
          <router-link :to="ROUTES.FORGOT_PASSWORD()">{{ t('auth.login.forgotPassword') }}</router-link>
        </div>
        <p v-if="isCheckingSSOUserRef">
          {{ t('auth.login.checkingSSOUser') }}
        </p>
        <!-- <p v-else-if="isSSOUserRef">
          {{ t('auth.login.ssoUser') }}
        </p> -->
        <div class="c-btns-container">
          <CustomButton
            v-if="isSSOUserRef === false"
            type="submit"
            data-test-id="submit-connect-email"
            usage="button"
            color="primary"
            :text="t('auth.login.login')"
          ></CustomButton>
          <div v-else-if="isSSOUserRef === true">
            <CustomButton
              type="button"
              usage="button"
              color="primary"
              :text="t('auth.login.quickLogin')"
              @emit:click.prevent="redirectToSAMLProvider"
            ></CustomButton>
          </div>
        </div>
      </form>
      <div class="c-btns-container">
        <CustomButton
          type="button"
          usage="button"
          color="white"
          :text="t('ui.button.back')"
          tabindex="-1"
          @emit:click.prevent="router?.push(ROUTES.LOGIN).catch(() => {})"
        ></CustomButton>
      </div>
    </template>
  </AuthWrapper>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { ref, watch } from 'vue'

import { checkIfUserIsSSOLogin, login } from '@/features/auth/api'
import AuthWrapper from '@/features/auth/components/auth-wrapper/auth-wrapper.vue'
import authStore from '@/features/auth/stores/auth/auth.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import useRouter from '@/hooks/use-router.hook'
import useToast from '@/hooks/use-toasts.hook'
import useWhiteLabel from '@/hooks/use-white-label.hook'
import { useI18n } from '@/lib/i18n'
import { EMAIL_REGEX, ROUTES } from '@/utils/config/constants'

const { whiteLabelRef } = useWhiteLabel()
const { t } = useI18n()
const toast = useToast()
const router = useRouter()

const emailRef = ref('')
const passwordRef = ref('')
const isSSOUserRef = ref<boolean | undefined>(undefined)
const emailErrorsRef = ref('')
const passwordErrorsRef = ref('')

const { mutate: checkSSOUser, isPending: isCheckingSSOUserRef } = useMutation({
  mutationFn: checkIfUserIsSSOLogin,
  onError: () => {
    toast?.error(t('auth.login.error'))
  },
  onSuccess: async (data) => {
    isSSOUserRef.value = data.isSSO
  },
})

watch(emailRef, (value) => {
  if (value.length > 3) {
    checkSSOUser(value)
  }
})
const isValidEmail = (email: string) => {
  return EMAIL_REGEX.test(email)
}

const handleEmailChange = (value: string) => {
  if (!isValidEmail(value)) {
    emailErrorsRef.value = t('auth.login.email.errors.invalid')
    return
  } else {
    emailErrorsRef.value = ''
    isSSOUserRef.value = undefined
    emailRef.value = value
  }
}

const handlePasswordChange = (value: string) => {
  passwordRef.value = value
}

const redirectToSAMLProvider = () => {
  window.location.href = ROUTES.LOGIN_WITH_SAML
}

async function handleLoginWithPassword() {
  if (!isValidEmail(emailRef.value)) {
    emailErrorsRef.value = t('auth.login.email.errors.invalid')
    return
  }
  if (passwordRef.value.length < 3) {
    passwordErrorsRef.value = t('auth.login.password.errors.invalid')
    return
  }

  try {
    const loginData = await login({
      email: emailRef.value,
      password: passwordRef.value,
    })
    if (loginData?.error) {
      toast?.error(t('error.common'))
      throw new Error(loginData.error)
    }
    await authStore.setAuthToken(loginData.token)
  } catch (error) {
    console.error(error)
    toast?.error(t('auth.login.error'))
  }
}
</script>

<style scoped lang="scss">
@import '@/features/ui/pages/auth/login-with-email/login-with-email.scss';
</style>
