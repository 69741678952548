<template>
  <div class="c-btns-container" v-if="!isSessionPendingRef">
    <CustomButton
      usage="button"
      type="submit"
      color="danger"
      :text="t('ui.button.decline')"
      @emit:click="cancelSession"
    />
    <CustomButton
      usage="button"
      type="submit"
      color="primary"
      :text="t('ui.button.accept')"
      @emit:click="acceptSession"
    />
  </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import { V1UpdateSessionStatusMutation } from '@/features/sessions/api'
import useSession from '@/features/sessions/hooks/use-session.hook'
import { SessionStatusEnumType } from '@/features/sessions/types'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import { selectGetSessionDetailsModal } from '@/features/ui/store/modal/modal.selectors'
import type { ConfirmActionModalType } from '@/features/ui/store/modal/modal.store'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { invalidateSession, invalidateSessions } from '@/utils/lib/vue-query'

const { t } = useI18n()
const toast = useToast()

const { accountRef } = useAccount()
const selectedGroupIdRef = selectGetSelectedGroupId(groupsStore)

const sessionDetailsModalRef = selectGetSessionDetailsModal(modalsStore)

const sessionIdRef = computed(() => sessionDetailsModalRef.value.attachedData.sessionId ?? null)
const { sessionRef, isPendingRef: isSessionPendingRef } = useSession(sessionIdRef)

function acceptSession() {
  if (sessionRef.value?.id && accountRef.value?.id) {
    updateSessionStatus({
      sessionId: sessionRef.value.id,
      status: SessionStatusEnumType.PLANNED,
    })
  } else {
    toast?.error(t('error.common'))
    throw new Error('Session or account not found')
  }
}

// update the session status
const { mutate: updateSessionStatus } = useMutation({
  mutationFn: V1UpdateSessionStatusMutation,
  onSuccess(data, variables) {
    modalsStore.toggleModal('session-details', false)
    invalidateSessions({ groupId: selectedGroupIdRef.value! })
    invalidateSession(sessionIdRef.value!)
    // show a success message based on the status
    if (variables.status === SessionStatusEnumType.CANCELLED) {
      toast?.success(t('session.cancel.success'))
    } else {
      toast?.success(t('session.accept.success'))
    }
  },
  onError() {
    modalsStore.toggleModal('session-details', false)
    toast?.error(t('error.common'))
  },
})

function cancelSession() {
  modalsStore.toggleModal('confirm-action', true)
  modalsStore.updateModalAttachedData<ConfirmActionModalType>('confirm-action', {
    title: t('session.cancel.confirmation.title'),
    content: t('ui.modal.confirmation.irreversible'),
    cancelFn() {
      modalsStore.toggleModal('confirm-action', false)
    },
    fn() {
      if (sessionRef.value?.id && accountRef.value?.id) {
        updateSessionStatus({
          sessionId: sessionRef.value.id,
          status: SessionStatusEnumType.CANCELLED,
        })
      } else {
        toast?.error(t('error.common'))
        throw new Error('Session or account not found')
      }
    },
  })
}
</script>
