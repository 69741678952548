import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { computed } from 'vue'

import { getUsers, USERS_QUERY_KEY } from '@/features/admin/users/api'
import type { GetUsersResponseType } from '@/features/admin/users/types'
import type { RoleEnumType } from '@/features/auth/types'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import type { V2UserAdminDataType } from '@/features/users/types'
import { MAX_USERS_PER_PAGE } from '@/utils/config/constants'

function V1ToV2DataMiddleware({
  data,
  selectedProgramId,
}: {
  data: GetUsersResponseType | undefined
  selectedProgramId: string | null
}): V2UserAdminDataType[] {
  const users = data?.users?.map((user) => {
    return {
      id: user.id,
      firstName: user.identity?.firstname || '...',
      lastName: user.identity?.lastname || '...',
      picture: user?.personalInformation?.profilPicture ?? null,
      role: user.role,
      email: user.email,
      hasGroup: user.mentorshipGroupUsers.some(
        (groupUser) => groupUser.mentorshipGroup.programId === selectedProgramId,
      ),
    } satisfies V2UserAdminDataType
  })

  return users || []
}

const useUsers = ({
  pageRef,
  perPageRef,
  searchRef,
  selectedRoleRef,
  selectedHasGroupRef,
}: {
  pageRef?: Ref<number>
  perPageRef?: Ref<number>
  searchRef?: Ref<string | null>
  selectedRoleRef?: Ref<RoleEnumType | 'ALL'>
  selectedHasGroupRef?: Ref<'YES' | 'NO' | 'ALL'>
} = {}) => {
  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)

  const getUsersPageRef = computed(() => pageRef?.value || 1)
  const getUsersPerPageRef = computed(() => perPageRef?.value || MAX_USERS_PER_PAGE)
  const getUsersSearchRef = computed(() => searchRef?.value || '')
  const getUsersRoleRef = computed(() => selectedRoleRef?.value || 'ALL')
  const getUsersHasGroupRef = computed(() => selectedHasGroupRef?.value || 'ALL')

  // Check if all the required data is available to make the query
  const isUsersQueryEnabledRef = computed(() => !!selectedProgramIdRef.value)

  const usersQuery = useQuery({
    queryKey: [
      USERS_QUERY_KEY,
      {
        programId: selectedProgramIdRef,
        search: getUsersSearchRef,
        role: getUsersRoleRef,
        hasGroup: getUsersHasGroupRef,
        perPage: getUsersPerPageRef,
        page: getUsersPageRef,
      },
    ],
    queryFn: () =>
      getUsers({
        programId: selectedProgramIdRef.value!,
        page: getUsersPageRef.value!,
        perPage: getUsersPerPageRef.value,
        search: getUsersSearchRef.value,
        role: getUsersRoleRef.value,
        hasGroup: getUsersHasGroupRef.value,
      }),
    enabled: isUsersQueryEnabledRef,
  })

  const data = computed(() => {
    return V1ToV2DataMiddleware({
      data: usersQuery.data.value,
      selectedProgramId: selectedProgramIdRef.value,
    })
  })

  const totalPagesRef = computed(() => usersQuery.data.value?.totalPages)
  const totalUsersRef = computed(() => usersQuery.data.value?.totalUsers)

  return {
    usersRef: data,
    totalPagesRef,
    totalUsersRef,
    isPendingRef: usersQuery.isPending,
  }
}

export default useUsers
