import type { AuthMeetingDTOType, AuthMeetingResponseType, ReadRoomUrlDTOType } from '@/features/meetings/types'
import fetchClient from '@/lib/fetch'

export async function readRoomUrlMutation(data: ReadRoomUrlDTOType): Promise<void> {
  const dataAdmin = new FormData()
  dataAdmin.append('sessionId', data.roomId)
  return fetchClient(`/v1/meeting/OpenMeeting`, {
    method: 'POST',
    body: dataAdmin,
  })
}

export const V1_MEETING_QUERY_KEY = 'meeting'
export async function getAuthMeeting(data: AuthMeetingDTOType): Promise<AuthMeetingResponseType> {
  return fetchClient(`/v1/meeting/auth?sessionId=${data.sessionId}`, {
    method: 'GET',
  })
}
