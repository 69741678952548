<template>
  <div ref="searchResultsRef" class="users-search-results">
    <div ref="searchResultsAnchorRef" class="users-search-results__scroll-anchor"></div>
    <LoadingContent v-if="usersPendingRef" />
    <template v-else-if="filteredUsersRef.length">
      <UserCard
        v-for="user in filteredUsersRef"
        :key="user.id"
        :user="user"
        :sub-info="user.email"
        @emit:click="onSelectUser"
        :override-on-click="true"
      />
    </template>
    <EmptyContent v-else text="Aucun utilisateur trouvé">
      <template #icon>
        <UserIcon color="black" filled />
      </template>
    </EmptyContent>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { computed } from 'vue'
import { watch } from 'vue'

import useUsers from '@/features/admin/users/hooks/use-users.hook'
import type { RoleEnumType } from '@/features/auth/types'
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import UserIcon from '@/features/ui/components/icons/user-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import UserCard from '@/features/users/components/user-card/user-card.vue'
import type { V2UserAdminDataType } from '@/features/users/types'

const props = defineProps<{
  searchBarRef: any
  search: string | null
  callback: (user: V2UserAdminDataType) => void
  filterByRole?: RoleEnumType
  removeFromResults?: V2UserAdminDataType[]
}>()

const searchRef = computed(() => props.search)
const searchResultsRef = ref<HTMLElement | null>(null)
const searchResultsAnchorRef = ref<HTMLElement | null>(null)

const userMailToFilterRef = ref<string | null | undefined>(null)

const onSelectUser = (user: V2UserAdminDataType) => {
  props.callback(user)
  userMailToFilterRef.value = user.email
  ;(props.searchBarRef as any).set(user.email)
}

const filterByRoleRef = ref<RoleEnumType | 'ALL'>(props.filterByRole ?? 'ALL')

const { usersRef, isPendingRef: usersPendingRef } = useUsers({
  pageRef: ref(1),
  perPageRef: ref(5),
  searchRef,
  selectedRoleRef: filterByRoleRef,
})

const filteredUsersRef = computed(() => {
  return usersRef.value.filter((user) => {
    return !props.removeFromResults?.some((removedUser) => removedUser.id === user.id)
  })
})

watch(
  usersRef,
  () => {
    searchResultsAnchorRef.value?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  },
  { flush: 'post', immediate: true },
)
</script>

<style lang="scss">
@import './users-search-results.scss';
</style>
