import { computed, ref, watchEffect } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/features/auth/types'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import useUser from '@/features/users/hooks/use-user.hook'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { slugify } from '@/utils/misc/slugify'

const MATCHING_CRITERIA_CLASS_PREFIX = 'matching-criteria-input'
export const MATCHING_CRITERIA_LOCAL_STORAGE_KEY = 'saved-matching-information'

export default function useMatchingInformation({
  onSubmitCallback,
}: {
  onSubmitCallback: (filledCriteria: string) => void
}) {
  const MATCHING_CRITERIA_REGEX = /%{(.*?)}%/g

  // Function to adjust input width relative to its placeholder
  function returnInputWidth(placeholder: string) {
    const tempSpan: HTMLSpanElement = document.createElement('span')

    // Set span properties
    tempSpan.style.visibility = 'hidden'
    tempSpan.style.position = 'absolute'
    tempSpan.textContent = placeholder

    // Measure and set width
    document.body.appendChild(tempSpan)
    const width: number = tempSpan.offsetWidth
    document.body.removeChild(tempSpan)

    // Add small padding (20px) to the width and ensure minimum width of 160px
    return Math.max(width + 20, 160)
  }

  // function generated with AI
  // see more explanation there: https://github.com/consteleducation/constel_education/issues/1678
  function fillTemplate(template: string, filledCriteria: string) {
    const placeholders = template.match(MATCHING_CRITERIA_REGEX)
    if (!placeholders) return null

    // Étape 2 : Construire un pattern regex pour extraire les entrées utilisateur de la phrase stockée
    const escapedRegex = /[-[\]/{}()*+?.\\^$|]/g
    let regexPattern = template.replace(escapedRegex, '\\$&') // Échapper les caractères spéciaux

    console.log(placeholders)
    // Remplacer les placeholders par des captures regex
    placeholders.forEach(function (placeholder) {
      const escapedPlaceholder = placeholder.replace(escapedRegex, '\\$&')
      regexPattern = regexPattern.replace(escapedPlaceholder, '(.*?)')
    })

    console.log(regexPattern)
    const finalRegex = new RegExp(`^${regexPattern}$`)
    const matches = filledCriteria.match(finalRegex)
    let userInputs: string[] = []
    if (matches) {
      matches.shift()
      userInputs = matches
    } else {
      // no match found
    }

    let modifiedCriteria = template
    let inputIndex = 0

    placeholders.forEach((placeholder) => {
      const placeholderContent = placeholder.slice(2, -2)
      const inputValue = userInputs[inputIndex]
      const indexLetter = `${MATCHING_CRITERIA_CLASS_PREFIX}-${inputIndex}`
      const inputWidth = returnInputWidth(placeholderContent)
      const inputField = `<input class="${indexLetter}" value="${inputValue}" placeholder="${placeholderContent}" style="width: ${inputWidth}px;" />`

      const escapedPlaceholder = placeholder.replace(escapedRegex, '\\$&')
      const placeholderRegex = new RegExp(`${escapedPlaceholder}`)
      modifiedCriteria = modifiedCriteria.replace(placeholderRegex, inputField)
      inputIndex++
    })

    return modifiedCriteria
  }

  function prepareMatchingInformationHTML(template: string, filledCriteria: string | null | undefined) {
    // if the criteria is already filled, return the filled criteria
    if (filledCriteria) {
      return fillTemplate(template, filledCriteria)
    }

    // return the response in a string format with all input placeholders with keys
    let inputIndex = 0
    return template.replace(MATCHING_CRITERIA_REGEX, (match) => {
      const matchText = match.replace(/%/g, '').replace(/{/g, '').replace(/}/g, '')
      const matchClass = slugify(matchText)
      // has index in letters
      const indexLetter = `${MATCHING_CRITERIA_CLASS_PREFIX}-${inputIndex}`
      inputIndex++
      const inputWidth = returnInputWidth(matchText)
      return `<input class="${indexLetter}" placeholder="${matchText}" style="width: ${inputWidth}px;" />`
    })
  }

  const isSubmittingRef = ref(false)

  const { accountRef } = useAccount()

  const accountIdRef = computed(() => accountRef.value?.id)
  const { userRef } = useUser(accountIdRef)
  const userCriteriaRef = computed(() => userRef.value?.matchingCriteria)
  const roleRef = computed(() => accountRef.value?.role)
  const toast = useToast()
  const { t } = useI18n()

  const matchingInformationRef = ref<string | null>(null)

  const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
  const { programRef: selectedProgramRef } = useProgram(selectedProgramIdRef)

  // get the matching information from the program
  watchEffect(() => {
    if (selectedProgramRef.value) {
      if (roleRef.value === RoleEnumType.MENTOR) {
        matchingInformationRef.value = selectedProgramRef.value.settings.mentorMatchingCriteria ?? null
      }
      if (roleRef.value === RoleEnumType.MENTEE) {
        matchingInformationRef.value = selectedProgramRef.value.settings.menteeMatchingCriteria ?? null
      }
    }
  })

  const onSubmit = (e: Event) => {
    e.preventDefault()
    if (!matchingInformationRef.value) return

    const matchToExtract = Array.from(matchingInformationRef.value.matchAll(MATCHING_CRITERIA_REGEX)).map((m) => m)

    // get all inputs values
    const fillValues: Record<string, string> = {}
    let filledCriteria = matchingInformationRef.value

    let inputIndex = 0
    matchToExtract.forEach(([key]) => {
      const indexLetter = `${MATCHING_CRITERIA_CLASS_PREFIX}-${inputIndex}`
      inputIndex++
      fillValues[indexLetter] = document.querySelector<HTMLInputElement>(`.${indexLetter}`)!.value ?? null
      filledCriteria = filledCriteria.replace(key, fillValues[indexLetter])
    })

    // replace all inputs placeholders with values
    Array.from(Object.entries(fillValues)).forEach(([key, value]) => {
      filledCriteria = filledCriteria.replace(key, value)
    })

    if (accountRef.value?.id && filledCriteria && selectedProgramIdRef.value) {
      onSubmitCallback(filledCriteria)
      // save the filled criteria in the local storage
      window.localStorage.setItem(MATCHING_CRITERIA_LOCAL_STORAGE_KEY, filledCriteria)
    } else {
      toast?.error(t('error.common'))
    }
  }

  return {
    prepareMatchingInformationHTML,
    userCriteriaRef,
    matchingInformationRef,
    isSubmittingRef,
    onSubmit,
  }
}
