<template>
  <GridBlockBase
    id="dashboard-matching"
    v-if="!isAdminDashboardAnalyticsPendingRef && adminDashboardAnalyticsRef"
    :title="t('admin.dashboard.matching.title')"
    :subtitle="`
${getFullTimeRange(adminDashboardAnalyticsRef.periodStart, adminDashboardAnalyticsRef.periodEnd)}
    `"
  >
    <template #icon>
      <ProgramIcon color="black" />
    </template>
    <template #body>
      <div class="dashboard-row">
        <StatAndIcon
          color="black"
          v-if="adminDashboardAnalyticsRef.totalMatches"
          :stat="adminDashboardAnalyticsRef.totalMatches"
          :text="t('admin.dashboard.matching.nbMatching')"
        >
          <template #icon>
            <GoalIcon color="black" />
          </template>
        </StatAndIcon>
        <StatAndIcon
          color="black"
          v-if="adminDashboardAnalyticsRef.totalPeriodSessions && adminDashboardAnalyticsRef.nbSessionPeriod"
          :stat="`${percentageMenteesMatchedOnTotalGoalRef}%`"
          :text="t('admin.dashboard.matching.ofGoalReached')"
        >
          <template #icon>
            <DonutProgressIcon
              v-if="percentageMenteesMatchedOnTotalGoalRef < 100"
              color="primary"
              :progress="percentageMenteesMatchedOnTotalGoalRef"
            />
            <CheckIcon v-else color="primary" />
          </template>
        </StatAndIcon>
      </div>
      <div class="dashboard-row">
        <StatAndIcon
          color="black"
          display="row"
          v-if="adminDashboardAnalyticsRef.totalMentors"
          :stat="adminDashboardAnalyticsRef.totalMentors"
          :text="t('admin.dashboard.common.nbMentors')"
        >
        </StatAndIcon>
        <StatAndIcon
          color="black"
          display="row"
          v-if="adminDashboardAnalyticsRef.totalMentees"
          :stat="adminDashboardAnalyticsRef.totalMentees"
          :text="t('admin.dashboard.common.nbMentees')"
        >
        </StatAndIcon>
        <StatAndIcon
          color="black"
          display="row"
          v-if="adminDashboardAnalyticsRef.totalMentees && adminDashboardAnalyticsRef.totalMentors"
          :stat="
            Math.floor((adminDashboardAnalyticsRef.totalMentees / adminDashboardAnalyticsRef.totalMentors) * 10) / 10
          "
          :text="t('admin.dashboard.common.nbMenteesPerMentor')"
        >
        </StatAndIcon>
      </div>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAdminDashboardAnalytics from '@/features/admin/analytics/hooks/use-dashboard-analytics.hook'
import StatAndIcon from '@/features/dashboards/components/stat-and-icon/stat-and-icon.vue'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import CheckIcon from '@/features/ui/components/icons/check-icon.vue'
import DonutProgressIcon from '@/features/ui/components/icons/donut-progress-icon.vue'
import GoalIcon from '@/features/ui/components/icons/goal-icon.vue'
import ProgramIcon from '@/features/ui/components/icons/program-icon.vue'
import { useI18n } from '@/lib/i18n'
import { getFullTimeRange } from '@/utils/dates'

const { t } = useI18n()

const { adminDashboardAnalyticsRef, isPendingRef: isAdminDashboardAnalyticsPendingRef } = useAdminDashboardAnalytics()

const percentageMenteesMatchedOnTotalGoalRef = computed(() => {
  if (!adminDashboardAnalyticsRef.value?.totalMatches) return 0
  return Math.floor(
    (adminDashboardAnalyticsRef.value.totalMatches / Math.max(1, adminDashboardAnalyticsRef.value.totalMentees || 1)) *
      100,
  )
})
</script>

<style lang="scss">
@import './dashboard-matching.scss';
</style>
