<template>
  <LoadingContent v-if="isPending" />
  <div v-else class="groups-table-wrapper">
    <div class="groups-table">
      <div class="groups-table__header">
        <span>{{ t('admin.groups.table.header.name') }}</span>
        <span>{{ t('admin.groups.table.header.mentor') }}</span>
        <span>{{ t('admin.groups.table.header.mentees') }}</span>
        <span>{{ t('admin.groups.table.header.finishedSessions') }}</span>
        <span>{{ t('admin.groups.table.header.actions') }}</span>
      </div>
      <div class="groups-table__content">
        <GroupRow v-for="group in groups" :key="group.id" :group="group" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import GroupRow from '@/features/admin/groups/components/groups-table/group-row/group-row.vue'
import type { V2GroupType } from '@/features/groups/types'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import { useI18n } from '@/lib/i18n'

const { t } = useI18n()

defineProps<{
  isPending: boolean
  groups: V2GroupType[]
}>()
</script>

<style lang="scss">
@import './groups-table.scss';
</style>
