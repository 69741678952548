<template>
  <ModalStep
    id="report-modal-content"
    :header="{
      title: t('session.reportModal.title'),
    }"
  >
    <template #bodyContent>
      <div class="c-info-wrapper">
        <p class="c-info-label" style="text-align: center">
          <strong v-html="t('session.reportModal.body')"></strong>
        </p>
      </div>
      <CustomTextarea
        :label="`${t('session.reportModal.input.report.label')}`"
        id="report-session"
        :placeholder="programRef?.settings.mentorReportTemplate"
        :value="reportRef"
        @emit:change="onChange"
      />
    </template>

    <template #footer>
      <div class="c-btns-container">
        <CustomButton
          usage="button"
          type="submit"
          color="white"
          :text="t('ui.button.fillLater')"
          @emit:click="onCloseAndFillLater"
        />
        <CustomButton
          :is-disabled="!reportRef"
          :is-loading="isAddSessionReportPendingRef"
          usage="button"
          type="submit"
          color="primary"
          :text="`${t('ui.button.finish')}`"
          icon-position="right"
          @emit:click="onSubmit"
        >
          <template #icon>
            <ArrowIcon color="white" />
          </template>
        </CustomButton>
      </div>
    </template>
  </ModalStep>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'

import { selectGetSelectedGroupId } from '@/features/groups/stores/groups/groups.selectors'
import groupsStore from '@/features/groups/stores/groups/groups.store'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import { V1AddSessionReportMutation } from '@/features/sessions/api'
import { useSessionInReview } from '@/features/sessions/hooks/use-in-review-session.hook'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomTextarea from '@/features/ui/components/custom-textarea/custom-textarea.vue'
import ArrowIcon from '@/features/ui/components/icons/arrow-icon.vue'
import ModalStep from '@/features/ui/components/modal/modal-step/modal-step.vue'
import { selectGetReviewSessionModal } from '@/features/ui/store/modal/modal.selectors'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { invalidateSession, invalidateSessions } from '@/utils/lib/vue-query'

const reportRef = ref<string | undefined>(undefined)
const toast = useToast()
const { t } = useI18n()

const selectedGroupRef = selectGetSelectedGroupId(groupsStore)
const reviewSessionModalRef = selectGetReviewSessionModal(modalsStore)
const selectedSessionInReviewIdRef = computed(() => reviewSessionModalRef.value?.attachedData.sessionId)

const programIdRef = selectGetSelectedProgramId(programsStore)
const { programRef } = useProgram(programIdRef)

const { onClickNextStep, onCloseAndFillLater } = useSessionInReview()

// set base value for the report textarea
watch(
  programRef,
  (program) => {
    reportRef.value = program?.settings.mentorReportTemplate
  },
  { immediate: true },
)

function onChange(text: string) {
  reportRef.value = text
}

const { mutate: addSessionReport, isPending: isAddSessionReportPendingRef } = useMutation({
  mutationFn: V1AddSessionReportMutation,
  onError: () => {
    toast?.error(t('error.common'))
  },
  onSuccess: async () => {
    // on success, we invalidate the modal to pass the session to the COMPLETE status
    await invalidateSessions({ groupId: selectedGroupRef.value! })
    await invalidateSession(selectedSessionInReviewIdRef.value!)

    toast?.success(t('session.reportModal.success'))
    onClickNextStep()
  },
})

function onSubmit() {
  try {
    if (!selectedSessionInReviewIdRef.value) {
      throw new Error('No session id found')
    }

    if (!reportRef.value) {
      throw new Error('No report found')
    }
    addSessionReport({
      sessionId: selectedSessionInReviewIdRef.value,
      comment: reportRef.value,
    })
  } catch (error) {
    console.error(error)
    toast?.error(t('error.common'))
  }
}
</script>
