<template>
  <div :class="`custom-select-wrapper ${id} ${isDisabled ? 'disabled' : ''}`">
    <!-- input label -->
    <label v-if="label" class="input-label" :for="id">
      <span>{{ label }}</span>
    </label>
    <div class="custom-select" :id="id">
      <slot name="icon"></slot>
      <span :style="`opacity: ${selectedValueRef ? 1 : 0.2};'`">{{
        options.find((option) => option.value === selectedValueRef)?.label || t('ui.select.defaultOption')
      }}</span>
      <DropdownIcon color="black" />
      <select class="select" v-model="selectedValueRef" @change="onChange">
        <option value="" disabled>{{ t('ui.select.defaultOption') }}</option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import DropdownIcon from '@/features/ui/components/icons/dropdown-icon.vue'
import { useI18n } from '@/lib/i18n'

const props = defineProps<{
  options: { label: string; value: string }[]
  id: string
  value?: string | null
  label?: string
  isDisabled?: boolean
}>()

const { t } = useI18n()

// set default value v-model
const selectedValueRef = computed(() => props.value || '')

const emit = defineEmits(['emit:change'])

const onChange = (event: Event) => {
  const value = (event.target as HTMLSelectElement).value
  // Emit the new value to the parent
  emit('emit:change', value, event)
}
</script>

<style lang="scss">
@import './custom-select.scss';
</style>
