<template>
  <!-- if the program is not matching enabled, we don't show the program criterias -->
  <GridBlockBase
    v-if="programRef?.settings.isMatchingEnabled"
    id="program-criterias"
    :title="t('dashboard.mentor.programCriterias.title')"
  >
    <template #icon>
      <ProgramIcon color="black" filled />
    </template>
    <template #body>
      <!-- we check for the mentorshipgroups ref as well because it is on the dashboard (we don't want to show the loading state if there is no group yet) -->
      <LoadingContent v-if="isProgramPendingRef" />
      <EmptyContent
        v-else-if="!isProgramPendingRef && !programRef"
        :text="t('dashboard.mentor.programCriterias.noProgram')"
      >
        <template #icon>
          <MentorIcon color="black" />
        </template>
      </EmptyContent>
      <template v-else>
        <LoadingContent v-if="isUserAccountPendingRef" />
        <template v-if="!userRef?.matchingCriteria">
          <EmptyContent :text="t('dashboard.mentor.programCriterias.empty.noCriterias')">
            <template #icon>
              <EditIcon color="black" />
            </template>
            <template #action>
              <CustomButton
                usage="button"
                type="button"
                color="primary"
                :text="t('dashboard.mentor.programCriterias.button.addCriterias')"
                @emit:click="
                  () => {
                    // if there is no group, we open the matching modal
                    if (groupsRef.length === 0) {
                      openMatchingModal()
                      // if there is a group, we open the program criterias modal
                      // this might be the case if the user has been matched to a mentee (manually for example)
                    } else {
                      openProgramCriteriasModal()
                    }
                  }
                "
              >
                <template #icon>
                  <EditIcon color="white" filled />
                </template>
              </CustomButton>
            </template>
          </EmptyContent>
        </template>
        <div v-else class="program-criterias-content">
          <p v-html="userRef?.matchingCriteria" style="white-space: pre-line; line-height: 1"></p>
          <div class="c-btns-container c-btn-center">
            <CustomButton
              usage="button"
              type="button"
              color="primary"
              :text="t('dashboard.mentor.programCriterias.button.modifyCriterias')"
              @emit:click="openProgramCriteriasModal"
            >
              <template #icon>
                <EditIcon color="white" filled />
              </template>
            </CustomButton>
          </div>
        </div>
      </template>
    </template>
  </GridBlockBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAccount from '@/features/auth/hooks/use-account.hook'
import useGroups from '@/features/groups/hooks/use-groups.hook'
import useProgram from '@/features/programs/hooks/use-program.hook'
import { selectGetSelectedProgramId } from '@/features/programs/stores/programs/programs.selectors'
import programsStore from '@/features/programs/stores/programs/programs.store'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import EmptyContent from '@/features/ui/components/empty-content/empty-content.vue'
import GridBlockBase from '@/features/ui/components/grid-block-base/grid-block-base.vue'
import EditIcon from '@/features/ui/components/icons/edit-icon.vue'
import MentorIcon from '@/features/ui/components/icons/mentor-icon.vue'
import ProgramIcon from '@/features/ui/components/icons/program-icon.vue'
import LoadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import modalsStore from '@/features/ui/store/modal/modal.store'
import useUser from '@/features/users/hooks/use-user.hook'
import { useI18n } from '@/lib/i18n'

const selectedProgramIdRef = selectGetSelectedProgramId(programsStore)
const { programRef, isPendingRef: isProgramPendingRef } = useProgram(selectedProgramIdRef)
const { groupsRef, isPendingRef: isGroupsPendingRef } = useGroups()

const { t } = useI18n()
const { accountRef } = useAccount()
const accountIdRef = computed(() => accountRef.value?.id)
const { userRef, isPendingRef: isUserAccountPendingRef } = useUser(accountIdRef)
console.log('userRef', userRef.value)

const openProgramCriteriasModal = () => {
  modalsStore.toggleModal('program-criterias', true)
}

const openMatchingModal = () => {
  modalsStore.toggleShouldHideMatchingModals(false)
}
</script>

<style lang="scss">
@import './dashboard-program-criterias.scss';
</style>
