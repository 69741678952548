import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import { togglePopoverAction } from '@/features/ui/store/popover/popover.actions'
import subscribeToStore from '@/lib/zustand'

// common popover data type
type CommonPopoverType<T> = {
  name: T
  isOpen: boolean
  event?: MouseEvent
}

// programs popover type
export type ProgramsPopoverType = CommonPopoverType<'programs'>

const ProgramsPopoverItem = {
  name: 'programs',
  isOpen: false,
} satisfies ProgramsPopoverType

// periods popover type
export type PeriodsPopoverType = CommonPopoverType<'periods'>

const PeriodsPopoverItem = {
  name: 'periods',
  isOpen: false,
} satisfies PeriodsPopoverType

// groups popover type
export type GroupsPopoverType = CommonPopoverType<'groups'>

const GroupsPopoverItem = {
  name: 'groups',
  isOpen: false,
} satisfies GroupsPopoverType

export type PopoverStoreType = ProgramsPopoverType | PeriodsPopoverType | GroupsPopoverType

export type PopoversStoreValuesType = {
  popovers: Record<PopoverStoreType['name'], PopoverStoreType>
}

export const INITIAL_POPOVERS_STORE_VALUES: PopoversStoreValuesType = {
  popovers: {
    programs: ProgramsPopoverItem,
    periods: PeriodsPopoverItem,
    groups: GroupsPopoverItem,
  },
}

const popoversStore = subscribeToStore(
  create(
    combine(INITIAL_POPOVERS_STORE_VALUES, (set, get) => ({
      togglePopover: (name: PopoverStoreType['name'], isOpen: PopoverStoreType['isOpen'], event?: MouseEvent) =>
        togglePopoverAction(get, set, name, isOpen, event),
    })),
  ),
)
export default popoversStore
