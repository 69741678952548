<template>
  <AuthWrapper>
    <template #header>
      <h1 v-html="t('auth.forgotPassword.title')"></h1>
      <p v-html="t('auth.forgotPassword.body')"></p>
    </template>
    <template #body>
      <form v-if="tokenRef" class="forgot-password-reset-form" @submit.prevent="handleResetPassword">
        <CustomInput
          id="password"
          type="password"
          :label="t('ui.input.password.label')"
          :placeholder="t('ui.input.password.placeholder')"
          :value="passwordRef"
          :errors="passwordErrorsRef"
          @emit:change="handlePasswordChange"
          :default-on-change-delay="500"
        />
        <CustomInput
          id="confirmPassword"
          type="password"
          :label="t('auth.password.modify.confirmNewPassword')"
          :placeholder="t('auth.password.modify.confirmNewPassword')"
          :value="confirmPasswordRef"
          :errors="arePasswordsMatchingRef ? '' : t('auth.forgotPassword.error.passwordsDoNotMatch')"
          @emit:change="handleConfirmPasswordChange"
          :default-on-change-delay="500"
        />
        <div class="c-btns-container">
          <CustomButton
            :isDisabled="!verifyPassword()"
            type="submit"
            usage="button"
            color="primary"
            :text="t('auth.forgotPassword.resetPassword')"
          ></CustomButton>
        </div>
      </form>
      <div class="forgot-password-email-sent" v-else-if="emailSentRef">
        <p v-html="t('auth.forgotPassword.emailSent')"></p>
      </div>
      <form v-else class="forgot-password-form" @submit.prevent="sendEmailForgotPassword(emailRef)">
        <CustomInput
          id="email"
          type="email"
          :label="t('ui.input.email.label')"
          :placeholder="t('ui.input.email.placeholder')"
          :value="emailRef"
          :errors="emailErrorsRef"
          @emit:change="handleEmailChange"
        />
        <div class="c-btns-container">
          <CustomButton
            :isDisabled="emailRef.length === 0 || emailErrorsRef.length > 0"
            type="submit"
            usage="button"
            color="primary"
            :text="t('auth.forgotPassword.sendEmail')"
          ></CustomButton>
          <CustomButton
            usage="button"
            color="white"
            :text="t('ui.button.back')"
            @emit:click.prevent="router?.back()"
          ></CustomButton>
        </div>
      </form>
    </template>
  </AuthWrapper>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { onMounted, ref, watch } from 'vue'

import { sendEmailForgotPasswordMutation } from '@/features/auth/api'
import { resetPasswordMutation } from '@/features/auth/api'
import AuthWrapper from '@/features/auth/components/auth-wrapper/auth-wrapper.vue'
import CustomButton from '@/features/ui/components/button/custom-button.vue'
import CustomInput from '@/features/ui/components/input/custom-input.vue'
import useRouter from '@/hooks/use-router.hook'
import useToast from '@/hooks/use-toasts.hook'
import { useI18n } from '@/lib/i18n'
import { EMAIL_REGEX, ROUTES } from '@/utils/config/constants'

const { t } = useI18n()
const router = useRouter()
const toast = useToast()

const emailRef = ref('')
const emailSentRef = ref(false)
const emailErrorsRef = ref('')
const tokenRef = ref<string | null>(null)
const passwordRef = ref('')
const confirmPasswordRef = ref('')
const passwordErrorsRef = ref('')
const arePasswordsMatchingRef = ref(true)

watch(confirmPasswordRef, (confirmPassword) => {
  arePasswordsMatchingRef.value = passwordRef.value === confirmPassword
})

function isValidPassword(password: string) {
  return password.length > 8
}

function handlePasswordChange(value: string) {
  if (!isValidPassword(value)) {
    passwordErrorsRef.value = t('auth.login.password.errors.invalid')
    return
  } else {
    passwordErrorsRef.value = ''
    passwordRef.value = value
  }
}

function handleConfirmPasswordChange(value: string) {
  if (!isValidPassword(value)) {
    passwordErrorsRef.value = t('auth.login.password.errors.invalid')
    return
  } else {
    passwordErrorsRef.value = ''
    confirmPasswordRef.value = value
  }
}

// get the reset password token from the url
onMounted(() => {
  const token = router.currentRoute?.params?.token as string | undefined
  if (token) {
    tokenRef.value = token
  }
})

function isValidEmail(email: string) {
  return EMAIL_REGEX.test(email)
}

function handleEmailChange(value: string) {
  if (!isValidEmail(value)) {
    emailErrorsRef.value = t('auth.login.email.errors.invalid')
    return
  } else {
    emailErrorsRef.value = ''
    emailRef.value = value
  }
}

const { mutate: sendEmailForgotPassword } = useMutation({
  mutationFn: sendEmailForgotPasswordMutation,
  onSuccess: () => {
    emailSentRef.value = true
    toast?.success(t('auth.forgotPassword.emailSentSuccess'))
  },
  onError: () => {
    emailErrorsRef.value = t('auth.forgotPassword.error.userNotFound')
    toast?.error(t('auth.forgotPassword.error.userNotFound'))
  },
})

function verifyPassword() {
  return (
    passwordRef.value.length > 0 &&
    confirmPasswordRef.value.length > 0 &&
    passwordRef.value === confirmPasswordRef.value
  )
}

async function handleResetPassword() {
  console.log('reset password')
  if (!tokenRef.value) {
    return
  }
  try {
    await resetPassword({
      token: tokenRef.value,
      password: passwordRef.value,
      confirmPassword: confirmPasswordRef.value,
    })
    toast?.success(t('auth.forgotPassword.success.passwordReset'))
  } catch (error) {
    console.log(error)
  }
}

const { mutate: resetPassword } = useMutation({
  mutationFn: resetPasswordMutation,
  onSuccess: () => {
    console.log('reset password success')
    router?.push(ROUTES.lOGIN_WITH_EMAIL)
  },
  onError: () => {
    console.log('reset password error')
  },
})
</script>

<style scoped lang="scss">
@import '@/features/ui/pages/auth/forgot-password/forgot-password.scss';
</style>
