<template>
  <InnerContent>
    <DashboardMentorContent v-if="accountRef?.role === RoleEnumType.MENTOR" />
    <DashboardMenteeContent v-else-if="accountRef?.role === RoleEnumType.MENTEE" />
    <loadingContent v-else :text="t('ui.message.loading')" />
  </InnerContent>
</template>

<script setup lang="ts">
import useAccount from '@/features/auth/hooks/use-account.hook'
import { RoleEnumType } from '@/features/auth/types'
import InnerContent from '@/features/ui/components/inner-content/inner-content.vue'
import loadingContent from '@/features/ui/components/loading-content/loading-content.vue'
import DashboardMenteeContent from '@/features/ui/pages/dashboard/dashboard-content/mentee/mentee-dashboard-content.vue'
import DashboardMentorContent from '@/features/ui/pages/dashboard/dashboard-content/mentor/mentor-dashboard-content.vue'
import { useI18n } from '@/lib/i18n'

const { accountRef } = useAccount()
const { t } = useI18n()
</script>

<style lang="scss">
@import './dashboard-content.scss';
</style>
